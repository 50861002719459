import React, { useContext, useState } from 'react'
import { Button, DialogTitle, IconButton, Stack } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { OpenModalProps } from 'src/@types/modalProps'
import { del, put } from 'src/utils/httpMethods'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router'
import { EndletContext } from './EndletDetails'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { CloseIcon } from 'src/theme/overrides/CustomIcons'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

interface Props {
  openProps: OpenModalProps
  service: any
  endletId: string
  getDetails: any
  setSelected?: any
}

function RemoveServiceModal({
  openProps: { open, setOpen },
  service,
  endletId,
  getDetails,
  setSelected,
}: Props) {
  console.log(service)
  const { endlet: endlet, endletId: id, getEndlets } = useContext(EndletContext)!
  const [loading, setLoading] = useState(false)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = () => {
    setLoading(true)

    const data = {
      endletIds:
        service.subType === 'Point To Point' &&
        service.associatedEndlets.length === 3
          ? [endletId, service.associatedEndlets[1].endletId]
          : [endletId],
    }
    put(
      `${process.env.REACT_APP_HOST_API_URL}/services/filter/${service.id}`,
      data as any
    )
      .then((response: any) => {
        setSnackbarProps({
          open: true,
          message: 'vWAN Removed Successfully!',
          severity: 'success',
        })
        if (endletId === id || service.subType === 'Point To Point') getEndlets()
        else {
          getDetails()
          setSelected([])
        }
        handleClose()
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)

        setSnackbarProps({
          open: true,
          message: 'Error Removing vWAN!',
          severity: 'error',
        })
      })
  }
  console.log(service)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack justifyContent="center" alignItems={'center'} sx={{ pt: 2 }}>
          <ErrorOutlineIcon
            sx={{ color: 'orange', width: '5rem', height: '5rem' }}
          />
        </Stack>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to remove the vWAN from the Endlet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={() => handleDelete()}
            variant="contained"
            color="error"
            loading={loading}
          >
            Yes
          </LoadingButton>
          <Button onClick={handleClose} color="primary" variant="contained">
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <Stack direction={'row'} justifyContent="flex-end">
          <HighlightOffIcon sx={{ mr: 2, mt: 3 }} onClick={handleClose}>
            <CloseIcon color="inherit" />
          </HighlightOffIcon>
        </Stack>
        <Stack justifyContent="center" alignItems={'center'} sx={{ pt: 2 }}>
          <CheckCircleOutlineIcon
            sx={{ color: 'green', width: '5rem', height: '5rem' }}
          />
        </Stack>
        <DialogContent sx={{ pl: 12 }}>
          <DialogContentText id="alert-dialog-description">
            Ticket has been created successfully
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
    </div>
  )
}

export default RemoveServiceModal
