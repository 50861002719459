import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Box,
  FormHelperText,
  Divider,
} from '@mui/material'
import { COUNTRIES } from '../../sections/@dashboard/Services(Endlets)/CreateEndletModal'
import useAuth from 'src/hooks/useAuth'
//custom
import { useForm } from 'react-hook-form'
import { get } from 'src/utils/httpMethods'
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form'
import { Stack } from '@mui/system'
import { ProvEndletContext } from './ProvisioningDetails'

type FormValuesProps = {
  endletName: string
  tags: Array<string>
  cloudProvider: string
  region: string
  systemGeneratedGeotag: boolean
  location_name: string
  country: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  endletIp: string
  // userName: string
  location: any
  sshKey: any
  afterSubmit?: string
}

function ProvDtConfiguration() {
  const { endlet } = useContext(ProvEndletContext)!
  const [regions, setRegions] = useState<any>()
  const { organization } = useAuth()
  const [tagsList, setTagsList] = useState<any>()
  const [initialSSH, setInitialSSH] = useState<any>({})

  const orgName = organization?.name
  const [countryLookup, setCountryLookup] = useState('')
  let keys = organization?.sshKeys

  const defaultValues = {
    endletName: '',
    tags: [],
    cloudProvider: '',
    region: '',
    systemGeneratedGeotag: false,
    location: {
      name: '',
      country: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
    endletIp: '',
    sshKey: {
      sshKeyName: '',
      sshKeyValue: '',
      username: '',
    },
  }
  const methods = useForm<FormValuesProps>({
    defaultValues,
  })
  const {
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods

  const {
    systemGeneratedGeotag: endletLoc,
    cloudProvider,
    location,
    tags,
    region,
    sshKey,
  } = watch()
  const [sshKeys, setSshKeys] = useState(
    keys
      ? keys.length
        ? keys.map((key: any) => ({
            name: key.sshKeyName,
            username: key.username,
            value: key.sshKeyValue,
            checked: false,
          }))
        : []
      : []
  )

  useEffect(() => {
    if (sshKey) {
      setSshKeys((prev: any) => [
        ...prev
          .filter((ssh: any) => ssh.name !== endlet.sshKey.sshKeyName)
          .map((innerKey: any) =>
            innerKey.name === sshKey.sshKeyName
              ? {
                  ...innerKey,
                  checked: true,
                }
              : { ...innerKey }
          ),
      ])
    }

    if (sshKey) console.log(sshKey)
  }, [sshKey])
  const hideAddress =
    typeof endletLoc === 'boolean' ? endletLoc : endletLoc === 'true' ? true : false
  useEffect(() => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/regions?cloudProvider=${cloudProvider}`
    )
      .then((res: any) => {
        setRegions(res.regions)
      })
      .catch((e) => console.log(e))
  }, [cloudProvider])
  const fetchTags = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/tags?tagType=Endlet`)
      .then((res: any) => {
        setTagsList(res)
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    fetchTags()
  }, [])
  useEffect(() => {
    reset(endlet)
    setInitialSSH({ ...endlet.sshKey })
  }, [endlet])

  useEffect(() => {
    reset(endlet)
  }, [endlet])

  return (
    <div>
      <FormProvider methods={methods}>
        <Typography variant="subtitle1" sx={{ pb: 2 }} mt={2}>
          GENERAL
        </Typography>
        <Typography variant="subtitle1" sx={{}}>
          Tags
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormHelperText sx={{ pb: 2 }}>
              Create a New Tag Below or Select From an Existing Tag
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <FormHelperText sx={{ pb: 2 }}>Tag Suggestions</FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disabled
              multiple
              filterSelectedOptions
              freeSolo
              options={
                tagsList
                  ? tagsList.length
                    ? tagsList.map((option: any) => option.name)
                    : ['']
                  : ['']
              }
              value={tags ? [...tags] : ['']}
              onChange={(e, newValue) =>
                setValue('tags', [
                  ...newValue.filter((tag) => tag.trim().length !== 0),
                ])
              }
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Type Tags and Hit 'Enter'"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} spacing={2}>
              <Chip label="Cloud" />
              <Chip label="DC" />
              <Chip label="PCI" />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} pt={1} mb={4}>
          {tags?.map((v, i) => (
            <Grid item key={i}>
              <Chip key={v} label={v} />
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ width: '100%', borderColor: 'darkgray' }} />
        <Typography variant="subtitle1" sx={{ pb: 2, pt: 2 }}>
          LOCATION
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Provider
            </Typography>
            <RHFSelect
              disabled
              name="cloudProvider"
              // label="Cloud Provider"
              placeholder="Cloud Provider"
            >
              <option value=""> </option>
              <optgroup label="Cloud Providers">
                <option value="aws"> Amazon Web Services </option>
                <option value="maz"> Microsoft Azure </option>
                <option value="gcp"> Google Cloud Platform </option>
              </optgroup>
              <optgroup label="Other">
                <option value="custom"> Custom Endlet</option>
              </optgroup>
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1 }}
              hidden={
                endlet.cloudProvider === '' || endlet.systemGeneratedGeotag === false
              }
            >
              Deployment Location
            </Typography>
            <RHFSelect
              disabled
              InputLabelProps={{ shrink: true }}
              name="region"
              value={region}
              // label="Deployment Location"
              placeholder="Select Region"
              hidden={
                endlet.cloudProvider === '' || endlet.cloudProvider === 'custom'
              }
            >
              <option value=""> </option>
              {regions && regions.length ? (
                <>
                  {regions.map((reg: any, i: any) => (
                    <option value={reg} key={i}>
                      {reg}
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>
                  Loading...
                </option>
              )}
            </RHFSelect>
            <RHFTextField
              disabled
              value={endlet.region}
              name="region"
              hidden={
                endlet.cloudProvider !== 'custom' ||
                endlet.systemGeneratedGeotag === false
              }
            ></RHFTextField>
          </Grid>
        </Grid>

        {endlet.cloudProvider === 'custom' && !endlet.systemGeneratedGeotag ? (
          <>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Location
                </Typography>
                <RHFTextField
                  disabled
                  name="location.name"
                  hidden={hideAddress}
                ></RHFTextField>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Country
                </Typography>
                <Autocomplete
                  hidden={hideAddress}
                  fullWidth
                  disabled
                  id="combo-box-demo"
                  options={Object.keys(COUNTRIES).map((obj: string) => obj)}
                  value={location && location.country ? location.country : ''}
                  onChange={(e, value) => {
                    setValue('country', value ? value : '')
                    setCountryLookup(value ? COUNTRIES[value].toString() : '')
                  }}
                  renderInput={(params) => (
                    <RHFTextField {...params} name="location.country" />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ display: hideAddress ? 'none' : '' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Address 1
                </Typography>
                <RHFTextField disabled name="location.address1"></RHFTextField>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Address 2
                </Typography>
                <RHFTextField disabled name="location.address2"></RHFTextField>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  City
                </Typography>
                <RHFTextField disabled name="location.city"></RHFTextField>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  State / Province
                </Typography>
                <RHFTextField disabled name="location.state"></RHFTextField>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  ZIP
                </Typography>
                <RHFTextField disabled name="location.zip"></RHFTextField>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ pb: 1 }} mt={2}>
              Endlet IP Address
            </Typography>
            <RHFTextField disabled name="endletIp"></RHFTextField>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 3, width: '100%', borderColor: 'darkgray' }} />
        <Typography variant="subtitle1" sx={{ py: 2 }}>
          AUTHENTICATION
        </Typography>
        <Stack direction={'row'} alignItems="center" sx={{ mb: 2 }} spacing={2}>
          <Typography variant="subtitle2">Current SSH Key</Typography>

          <Chip
            label={
              initialSSH &&
              initialSSH.sshKeyName &&
              initialSSH.sshKeyName
                .replace(/-/g, ' ')
                .replace(orgName?.toLowerCase(), '')
            }
            sx={{
              p: 2,
              fontWeight: 'bold',
              backgroundColor: '#00A54F',
              color: 'white',
            }}
          />

          <Button style={{ marginLeft: 'auto' }} variant="outlined" disabled>
            Change SSH Key
          </Button>
        </Stack>

        <Box sx={{ justifyContent: 'flex-end', display: 'flex', mt: 2 }}>
          <Button type="submit" variant="contained" disabled>
            save
          </Button>
        </Box>
      </FormProvider>
    </div>
  )
}

export default ProvDtConfiguration
