import React, { useContext, useState } from 'react'
import * as Yup from 'yup'

//@mui
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'

//custom
import { Reseller } from 'src/pages/super-user/ResellerManagement'
import useIsMountedRef from 'src/hooks/useIsMountedRef'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, RHFTextField } from 'src/components/hook-form'
import EditIcon from '@mui/icons-material/Edit'
import { get, put } from 'src/utils/httpMethods'
import { ResellersContext } from 'src/contexts/ResellersContext'
import { LoadingButton } from '@mui/lab'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

type FormValuesProps = {
  zip: string
  name: string
  phone: string
  email: string
  address: object
  website: string
  taxId: string
  subdomain: string
  logo: string
  afterSubmit?: string
}

export const ContactPersonSchema = Yup.object().shape({
  address: Yup.string().required('Address cannot be blank'),
  phone: Yup.string().required('Phone cannot be blank'),
  email: Yup.string().email().required('Email is required'),
  website: Yup.string().email().required('Website is required'),
  taxId: Yup.string().email().required('Website is required'),
})

interface Props {
  reseller: Reseller
}

function ResellerGeneral({ reseller }: Props) {
  // const { address, taxId, email, website, name, subdomain, logo } = reseller
  const [isUpdating, setIsUpdating] = useState(false)
  const value = useContext(ResellersContext)!

  const { changeCompany: setSelected, reloadResellers: setReloadResellers } = value
  // RHF
  const isMountedRef = useIsMountedRef()

  const addressFields = [
    {
      label: 'Address 1',
      name: 'line1',
      value: reseller?.address?.line1,
    },
    {
      label: 'Address 2',
      name: 'line2',
      value: reseller?.address?.line2,
    },
    {
      label: 'City',
      name: 'city',
      value: reseller?.address?.city,
    },

    {
      label: 'State',
      name: 'state',
      value: reseller?.address?.state,
    },
    {
      label: 'ZIP Code',
      name: 'zip',
      value: reseller?.address?.zip,
    },
  ]

  const otherFields = [
    {
      label: 'Website',
      name: 'website',

      value: reseller?.website,
    },
    {
      name: 'taxId',

      label: 'Tax ID',
      value: reseller?.taxId,
    },
    {
      label: 'Subdomain',
      name: 'subdomain',

      value: reseller?.subdomain,
    },
  ]

  const commonFields = [
    {
      label: 'Name',
      name: 'name',
      value: reseller?.name,
    },
    {
      label: 'E-mail',
      name: 'email',
      value: reseller?.email,
    },
  ]

  const defaultValues = {
    name: reseller?.name,
    phone: reseller?.phone,
    email: reseller?.email,
    address: {
      line1: reseller?.address?.line1,
      line2: reseller?.address?.line2,
      city: reseller?.address?.city,
      state: reseller?.address?.state,
      zip: reseller?.address?.zip,
    },
    website: reseller?.website,
    taxId: reseller?.taxId,
    subdomain: reseller?.subdomain
      ? reseller?.subdomain + '.stage.21packets.com'
      : '',
    logo: reseller?.logo,
  }

  const resellerSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    address: Yup.object().shape({
      line1: Yup.string().required('Line1 is required'),
      line2: Yup.string().required('Line2 is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('ZIP is required'),
    }),
    website: Yup.string().required('Website is required'),
    taxId: Yup.string().required('TaxId is required'),
    subdomain: Yup.string(),
    logo: Yup.string(),
  })

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(resellerSchema),
    defaultValues,
  })

  const [edit, setEdit] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const onSubmit = async (data: FormValuesProps) => {
    console.log(data)
    setIsUpdating(true)
    try {
      put(`/resellers/organization/${reseller?.id}`, data)
        .then((response) => {
          setIsUpdating(false)
          setSnackbarProps({
            open: true,
            message: 'Reseller details updated successfully!',
            severity: 'success',
          })
          get(`/resellers/organization/${reseller?.id}`).then((response) => {
            setSelected({ ...reseller, ...response })
            // setReloadResellers('')
          })
        })
        .catch((error) => {
          setIsUpdating(false)
          setSnackbarProps({
            open: true,
            message: error,
            severity: 'error',
          })
        })
    } catch (error) {
      console.error(error)

      reset()

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message })
      }
    }
  }

  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods
  console.log(errors)
  return (
    <>
      <Box className="mt-3">
        <Card sx={{ p: 3 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                  <Stack
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    General
                    <IconButton
                      size="small"
                      onClick={() => setEdit((prev) => !prev)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Stack>
                </Grid>
                {commonFields.map((field: any) => (
                  <Grid item xs={6} key={field.name}>
                    {edit ? (
                      <RHFTextField
                        size="small"
                        name={field.name}
                        label={field.label}
                      ></RHFTextField>
                    ) : !reseller?.name ? (
                      <>
                        <Skeleton animation="wave" height={30} width={200} />
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">
                          <Box fontWeight="fontWeightMedium" display="inline">
                            {field.label}
                          </Box>
                        </Typography>
                        <Typography variant="body2">{field.value}</Typography>
                      </>
                    )}
                  </Grid>
                ))}
                <Divider />
                <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                  Address
                </Grid>
                {addressFields.map((field: any) => (
                  <Grid item xs={6} key={field.name}>
                    {edit ? (
                      <RHFTextField
                        size="small"
                        name={`address.${field.name}`}
                        label={field.label}
                      ></RHFTextField>
                    ) : !reseller?.name ? (
                      <>
                        <Skeleton animation="wave" height={30} width={200} />
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">
                          <Box fontWeight="fontWeightMedium" display="inline">
                            {field.label}
                          </Box>
                        </Typography>
                        <Typography variant="body2">{field.value}</Typography>
                      </>
                    )}
                  </Grid>
                ))}
                <Divider />

                <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                  Other
                </Grid>
                {otherFields.map((field: any) => (
                  <Grid item xs={6} key={field.name}>
                    {edit ? (
                      <RHFTextField
                        size="small"
                        disabled={field.name === 'subdomain' ? true : false}
                        name={field.name}
                        // defaultValue={field.value}
                        label={field.label}
                      ></RHFTextField>
                    ) : !reseller?.name ? (
                      <>
                        <Skeleton animation="wave" height={30} width={200} />
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">
                          <Box fontWeight="fontWeightMedium" display="inline">
                            {field.label}
                          </Box>
                        </Typography>
                        <Typography variant="body2">
                          {field.name === 'subdomain'
                            ? field.value
                              ? field.value + '.stage.21packets.com'
                              : ''
                            : field.value}
                        </Typography>
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
              {edit && (
                <Box sx={{ alignSelf: 'flex-end', display: 'flex', mt: 2 }}>
                  <Button
                    variant="text"
                    onClick={() => setEdit(false)}
                    sx={{ color: 'text.secondary', float: 'right', mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ float: 'right' }}
                    loading={isUpdating}
                  >
                    <span>Update</span>
                  </LoadingButton>
                </Box>
              )}
            </Stack>
          </FormProvider>
        </Card>
      </Box>
    </>
  )
}

export default ResellerGeneral
