import React, { useContext, useState } from 'react'

// @mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { red } from '@mui/material/colors'
import { LoadingButton } from '@mui/lab'

// types
import { del } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

interface Props {
  openProps: {
    openDeleteInvite: boolean
    setOpenDeleteInvite: React.Dispatch<React.SetStateAction<boolean>>
  }
  user: string
  id: string
  status: string
  orgId: string
}

function DeleteResellerUserInvite({
  user: name,
  openProps,
  id,
  status,
  orgId,
}: Props) {
  const { openDeleteInvite: open, setOpenDeleteInvite: setOpen } = openProps
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteInvite = async () => {
    setLoading(true)
    try {
      await del(`${process.env.REACT_APP_HOST_API_URL}/resellers/users/${id}`)
      setLoading(false)
      setSnackbarProps({
        open: true,
        severity: 'success',
        message: `User ${name} is no longer invited`,
      })
      handleClose()
    } catch (e) {
      setLoading(false)
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Error while deleting invite for ${name}`,
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ width: 600 }}>
        <DialogTitle>Delete Invite?</DialogTitle>

        <DialogContentText sx={{ p: 3 }}>
          {`Invitiation for ${name} will be deleted!`}
        </DialogContentText>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleDeleteInvite}
            variant="contained"
            sx={{
              bgcolor: red[400],
              color: 'white',
              '&:hover': { bgcolor: red[900] },
            }}
          >
            Delete Invite
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeleteResellerUserInvite
