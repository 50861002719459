import React from 'react'

// @mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

//@form
import { useForm } from 'react-hook-form'
import { FormProvider, RHFTextField } from 'src/components/hook-form'

type FormValuesProps = {
  firstName: string
  lastName: string
  email: string
  role: string
  address: string
}

function AddSAML({
  openProps,
}: {
  openProps: {
    openAddNew: boolean
    setOpenAddNew: React.Dispatch<React.SetStateAction<boolean>>
  }
}) {
  const { openAddNew: open, setOpenAddNew: setOpen } = openProps

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    address: '',
  }

  const handleClose = () => {
    setOpen(false)
  }

  const methods = useForm<FormValuesProps>({
    defaultValues,
  })

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500))
      // enqueueSnackbar("Update success!");
    } catch (error) {
      console.error(error)
    }
  }

  const { handleSubmit } = methods
  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <DialogTitle>Add User</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {/* <DialogContentText>
        To subscribe to this website, please enter your email address here.
        We will send updates occasionally.
      </DialogContentText> */}
          <Box
            sx={{
              mt: '20px',
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            <RHFTextField name="entityID" label="SAML Provider entity ID" />
            <RHFTextField name="sso" label="SAML SSO URL" />
            <RHFTextField name="certificate" label="Public key certificate" />
            <RHFTextField name="appId" label="App entity ID" />

            {/* <RHFTextField name="userName" label="User Name" /> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Add</Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export default AddSAML
