import React, { useContext, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import {
  FormProvider,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form'
import { get, post } from 'src/utils/httpMethods'
import { Stack } from '@mui/system'
import { Autocomplete, CircularProgress, Divider, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { COUNTRIES } from './CreateEndletModal'
import { Chip } from '@mui/material'
import { Button } from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { TopLevelmodalContext } from 'src/contexts/ModalContext'
import { Grid } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import useAuth from 'src/hooks/useAuth'
import NewSSHModal from './NewSSHModal'
import { LoadingButton } from '@mui/lab'

type FormValuesProps = {
  endlet_name: string
  tags: string[]
  system_generated_geotag: boolean
  ip: string
  cloudProvider: string
  region: string
  location_name: string
  country: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  ssh: string
  afterSubmit?: string
}

const GeneralInfoSchema = Yup.object().shape({
  endlet_name: Yup.string(),
  tags: Yup.array().of(Yup.string()),
  system_generated_geotag: Yup.boolean(),
  cloudProvider: Yup.string().required('Cloud Provider is required!'),
  ip: Yup.string()
    .trim()
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'Invalid IP'
    ),
  region: Yup.string().when('cloudProvider', {
    is: 'aws' || 'maz' || 'aws',
    then: Yup.string().required('Region is required!'),
  }),
  location_name: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('Location is required!'),
  }),

  country: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('Country is required!'),
  }),
  address1: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('Address is required!'),
  }),
  address2: Yup.string(),
  city: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('City is required!'),
  }),
  state: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('State is required!'),
  }),
  zip: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('ZIP is required!'),
  }),
  ssh: Yup.string().required('SSH key is required'),
})

interface Props {
  generalFormProps: {
    handleClose: () => void
  }
  formDetails: {
    endletFields: any
    setEndletFields: React.Dispatch<React.SetStateAction<any>>
  }
  setEndletAdded: any
}

const staticTags = ['Cloud', 'DC', 'PCI']

function CreateEPsinglePage({
  generalFormProps: { handleClose },
  formDetails: { endletFields, setEndletFields },
  setEndletAdded,
}: Props) {
  const defaultValues = {
    endlet_name: endletFields.endlet_name ? endletFields.endlet_name : '',
    cloudProvider: endletFields.cloudProvider ? endletFields.cloudProvider : '',
    ip: endletFields.ip ? endletFields.ip : '',
    region: endletFields.region ? endletFields.region : '',
    tags: endletFields.tags ? endletFields.tags : [''],
    system_generated_geotag:
      endletFields.system_generated_geotag !== undefined
        ? endletFields.system_generated_geotag
        : true,
    location_name: endletFields.location_name ? endletFields.location_name : '',
    country: endletFields.country ? endletFields.country : '',
    address1: endletFields.address1 ? endletFields.address1 : '',
    address2: endletFields.address2 ? endletFields.address2 : '',
    city: endletFields.city ? endletFields.city : '',
    state: endletFields.state ? endletFields.state : '',
    zip: endletFields.zip ? endletFields.zip : '',
    ssh: endletFields.sshKey ? endletFields.sshKey : '',
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(GeneralInfoSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = methods

  const {
    system_generated_geotag: endletLoc,
    country,
    address1,
    city,
    state,
    zip,
    tags,
    cloudProvider,
    endlet_name,
  } = watch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verifyObj = {
    addr1: address1,
    locality: city,
    state: state,
    zip: zip,
  }

  const smartyAPIKey = process.env.REACT_APP_LOCATION_EMBEDDED_KEY

  const disableAddress =
    typeof endletLoc === 'boolean' ? endletLoc : endletLoc === 'true' ? true : false

  const [isAddressVerified, setIsAddressVerified] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<any>()
  const [countryLookup, setCountryLookup] = useState('')
  const [query, setQuery] = useState('')
  const [suggestions, setSuggestions] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const [regions, setRegions] = useState<any>()

  const { setModalProps } = useContext(TopLevelmodalContext)!

  const { organization, UseNewUserData } = useAuth()
  const { refetchUserData } = UseNewUserData()

  const orgName = organization?.name
  let keys = organization?.sshKeys

  const [openNewSSH, setOpenNewSSH] = useState(false)
  const [newKeyAdded, setNewKeyAdded] = useState(false)

  const [sshKeys, setSshKeys] = useState<any>([])

  // const [sshKeys, setSshKeys] = useState(
  //   keys
  //     ? keys.length
  //       ? keys.map((key: any) => ({
  //           name: key.sshKeyName,
  //           username: key.username,
  //           checked: false,
  //         }))
  //       : []
  //     : []
  // )

  const getSShKeys = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/auth/organization/ssh`)
      .then((res: any) => {
        setSshKeys(
          res.reverse().map((key: any, k: number) =>
            k === res.length - 1 && newKeyAdded
              ? {
                  id: key.id,
                  name: key.sshKeyName,
                  username: key.username,
                  checked: true,
                }
              : {
                  id: key.id,
                  name: key.sshKeyName,
                  username: key.username,
                  checked: false,
                }
          ) || []
        )
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    getSShKeys()
  }, [])

  const setNewKeys = async () => {
    await getSShKeys()
  }

  useEffect(() => {
    console.log('key added ')
    if (newKeyAdded === false) return
    setNewKeys()
  }, [newKeyAdded])

  useEffect(() => {
    setIsAddressVerified(
      selectedLocation
        ? verifyObj.addr1 === selectedLocation.addr1 &&
          verifyObj.locality === selectedLocation.locality &&
          verifyObj.state === selectedLocation.state &&
          verifyObj.zip === selectedLocation.zip
          ? true
          : false
        : false
    )
  }, [selectedLocation, verifyObj])

  const [tagsList, setTagsList] = useState<any>()
  const [tagsnameList, setTagsnameList] = useState<any>()

  useEffect(() => {
    setValue('tags', endletFields.tags)
  }, [endletFields, setValue])

  const [submitMethod, setSubmitMethod] = useState<'save' | 'create'>('create')

  const [suggestionsPlaceHolder, setSuggestionsPlaceHolder] = useState(
    'Start typing to get address suggestions'
  )

  useEffect(() => {
    if (!countryLookup.length || !query.length) return
    if (countryLookup === 'USA') {
      get(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=147123342709467494&search=${query}`
      )
        .then((res: any) => {
          setSuggestions(res.suggestions)
        })
        .catch((e) => console.log(e))
    } else {
      get(
        `https://international-autocomplete.api.smarty.com/v2/lookup?key=147123342709467494&country=${countryLookup}&search=${query}`
      )
        .then((res: any) => {
          setSuggestions(res.candidates)
        })
        .catch((e) => console.log(e))
    }
  }, [countryLookup, query])

  useEffect(() => {
    let selectedKey =
      sshKeys && sshKeys.filter((key: any) => key.checked === true)[0]
    console.log(selectedKey)

    setValue('ssh', selectedKey?.name)
  }, [sshKeys])

  const createEndlet = (data: FormValuesProps) => {
    let selectedKey =
      sshKeys && sshKeys.filter((key: any) => key.checked === true)[0]
    console.log(selectedKey)

    setLoading(true)
    post(`${process.env.REACT_APP_HOST_API_URL}/endlets`, {
      endlet: {
        endletName: data.endlet_name,
        tags: data.tags,
        cloudProvider: data.cloudProvider,
        region: data.region,
        location: {
          name: data.location_name,
          country: data.country,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
        },
        endletIp: data.ip,
        sshKey: {
          sshKeyId: selectedKey?.id,
          sshKeyName: selectedKey?.name,
          username: selectedKey?.username,
          sshPortNumber: selectedKey.sshPortNumber
            ? selectedKey.sshPortNumber
            : '22',
        },

        systemGeneratedGeotag: data.system_generated_geotag,
        status: 'Pending',
      },
    })
      .then(() => {
        handleClose()
        setModalProps({
          open: true,
          message: 'Endlet created successfully!',
          severity: 'success',
        })

        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setModalProps({
          open: true,
          message: e.message,
          severity: 'error',
        })
      })
  }

  const onSubmit = async (data: FormValuesProps) => {
    console.log(data)

    if (cloudProvider !== 'custom') {
      setValue('system_generated_geotag', false)
    }
    console.log(data)
    createEndlet(data)
    setEndletFields((prev: any) => ({ ...prev, ...data }))
  }

  const fetchTags = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/tags?tagType=Endlet`)
      .then((res: any) => {
        setTagsList(res)
        const arr: any = []
        res.map((item: any) => {
          arr.push(item.name)
        })
        setTagsnameList(arr)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    fetchTags()
  }, [])

  useEffect(() => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/regions?cloudProvider=${cloudProvider}`
    )
      .then((res: any) => {
        setRegions(res.regions)
      })
      .catch((e) => console.log(e))
  }, [cloudProvider])

  const [nameValidationLoader, setNameValidationLoader] = useState(false)
  const [nameValid, setNameValid] = useState(false)

  useEffect(() => {
    if (endlet_name !== '') {
      setNameValidationLoader(true)
      const nameValidation = () => {
        get(
          `${process.env.REACT_APP_HOST_API_URL}/endlets/endlet-exists?endletName=${endlet_name}`
        )
          .then((res) => {
            setNameValidationLoader(false)
            clearErrors('endlet_name')
            setNameValid(true)
          })
          .catch((e) => {
            setNameValidationLoader(false)
            console.log(e)
            setError('endlet_name', e)
            setNameValid(false)
          })
      }
      const timer = setTimeout(() => {
        nameValidation()
      }, 500)

      return () => {
        clearTimeout(timer)
      }
    } else {
      setNameValidationLoader(false)
      setNameValid(false)
    }
  }, [clearErrors, endlet_name, setError])

  console.log(sshKeys)
  console.log(newKeyAdded)

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <>
          <Typography variant="subtitle1" sx={{ py: 2 }}>
            General
          </Typography>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                Endlet Name
              </Typography>
              <Typography variant="caption" sx={{ mt: 0 }} color={'gray'}>
                Enter a Custom Endlet Name Below or Leave Blank for a System
                Generated Endlet Name
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems="center">
              <RHFTextField
                name="endlet_name"
                placeholder="Type Endlet Name"
                color={nameValid ? 'success' : 'primary'}
                autoComplete="off"
                sx={{ maxWidth: 850 }}
              ></RHFTextField>
              {nameValidationLoader && <CircularProgress />}
            </Stack>
            {nameValid && (
              <Stack direction={'row'} alignItems="center" spacing={1}>
                <VerifiedIcon color="success" />
                <Typography variant="body2" color="green">
                  {' '}
                  Endlet name is available!{' '}
                </Typography>
              </Stack>
            )}

            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={8} sx={{ pl: 0 }}>
                <Stack>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Tags
                  </Typography>
                  <Typography variant="caption" sx={{ mt: 0 }} color={'gray'}>
                    Create a New Tag Below or Select From an Existing Tag
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={4} sx={{ pl: 2 }}>
                <Typography variant="body2" color={'GrayText'} sx={{ mt: 4 }}>
                  Tag suggestions
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Autocomplete
                  sx={{ flexGrow: 1 }}
                  multiple
                  id="tags-filled"
                  noOptionsText={
                    'No tags found for your organization. Type and press enter to add a new tag'
                  }
                  options={
                    tagsList && tagsList.length
                      ? tagsList.map((option: any) => option.name)
                      : []
                  }
                  freeSolo
                  value={tags ? [...tags] : ['']}
                  // itemID=''
                  renderTags={(value: readonly string[], getTagProps) => {
                    return value
                      .filter((option: string) => option !== '')
                      .map((option: string, index: number) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                  }}
                  onChange={(e, value) => {
                    setValue('tags', [
                      ...value.filter((tag) => tag.trim().length !== 0),
                    ])
                    // let res=tagsList.map((item:any)=>{
                    //   let val= tagsList.filter(item.name.includes(value[1]))
                    //  console.log(val)

                    //   debugger
                    //   return val;
                    // })
                    // console.log(res)
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`Type Tags and Hit “Enter"`}
                      name="tags"
                      sx={{ maxWidth: 850 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{ pl: 2, pt: 1 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {/* {tagsList &&
                  tagsList.map((tag: any, i: number) =>
                    i < 3 ? (
                      <Chip
                        label={tag.name}
                        onClick={() => {
                          setValue(
                            'tags',
                            tags && tags.length ? [...tags, tag.name] : [tag.name]
                          )
                        }}
                      />
                    ) : (
                      <></>
                    )
                  )} */}

                  {staticTags &&
                    staticTags.map((tag: any, i: number) => (
                      <Chip
                        label={tag}
                        onClick={() => {
                          setValue(
                            'tags',
                            tags && tags.length ? [...tags, tag] : [tag]
                          )
                        }}
                      />
                    ))}

                  {/* {!tagsList && <CircularProgress size={28} />} */}
                </Stack>
                {/* </Stack> */}
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: 'black', px: 2 }} />
            <Typography variant="subtitle1" sx={{ pb: 1, pt: 1 }}>
              Location
            </Typography>
            <Stack
              direction={'row'}
              sx={{ width: '100%' }}
              spacing={2}
              alignItems="center"
            >
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Provider
                </Typography>
                <RHFSelect
                  sx={{ maxWidth: 850 }}
                  name="cloudProvider"
                  label="Select Provider"
                  placeholder="Endlet Deployment"
                >
                  <option value=""> </option>
                  <optgroup label="Cloud Providers">
                    <option value="aws"> Amazon Web Services </option>
                    <option value="maz"> Microsoft Azure </option>
                    <option value="gcp"> Google Cloud Platform </option>
                  </optgroup>
                  <optgroup label="Other">
                    <option value="custom"> Custom Endlet</option>
                  </optgroup>
                </RHFSelect>
              </Stack>

              <Stack sx={{ width: '100%' }} spacing={2}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 1 }}
                  hidden={cloudProvider === 'custom'}
                >
                  Deployment Location
                </Typography>
                <RHFSelect
                  sx={{ maxWidth: 850 }}
                  name="region"
                  placeholder="Select Deployment Location"
                  disabled={cloudProvider === '' ? true : false}
                  hidden={cloudProvider === 'custom'}
                >
                  {regions && regions.length ? (
                    <>
                      <option value="" disabled>
                        <em>Select Deployment Location</em>
                      </option>

                      {regions.map((reg: any) => (
                        <option value={reg}>{reg}</option>
                      ))}
                    </>
                  ) : cloudProvider === '' ? (
                    <option value="" disabled>
                      Select Deployment Location
                      {/* <CircularProgress /> */}
                    </option>
                  ) : (
                    <option value="" disabled>
                      Loading...
                      {/* <CircularProgress /> */}
                    </option>
                  )}
                </RHFSelect>
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2} hidden={cloudProvider !== 'custom'}>
            <RHFRadioGroup
              hidden={cloudProvider !== 'custom'}
              name="system_generated_geotag"
              // defaultValue={false}
              options={[
                { label: 'System Generated Location', value: true },
                { label: 'User Generated Location', value: false },
              ]}
              sx={{ '& .MuiFormControlLabel-root': { mr: 4, mt: 1 } }}
            ></RHFRadioGroup>

            <RHFTextField
              sx={{ maxWidth: 850 }}
              autoComplete="off"
              name="location_name"
              label="Location Name"
              disabled={disableAddress}
              hidden={disableAddress}
            ></RHFTextField>

            <Autocomplete
              disablePortal
              disabled={disableAddress}
              hidden={disableAddress}
              id="combo-box-demo"
              options={Object.keys(COUNTRIES).map((obj: string) => obj)}
              // sx={{ width: 300 }}
              value={country}
              onChange={(e, value) => {
                console.log(value)
                setQuery('')
                setValue('country', value ? value : '')
                setCountryLookup(value ? COUNTRIES[value].toString() : '')
              }}
              renderInput={(params) => (
                <RHFTextField
                  {...params}
                  label="Country"
                  name="country"
                  sx={{ maxWidth: 850 }}
                />
              )}
            />

            <Autocomplete
              disablePortal
              disabled={disableAddress}
              hidden={disableAddress}
              value={address1}
              id="combo-box-demo"
              options={
                countryLookup === 'USA'
                  ? suggestions.length
                    ? [
                        ...suggestions.map((addr: any) => ({
                          label:
                            addr.street_line +
                            ', ' +
                            addr.secondary +
                            ', ' +
                            addr.city,
                          value: addr.street_line,
                          addr1: addr.street_line,
                          locality: addr.city,
                          zip: addr.zipcode,
                          state: addr.state,
                        })),
                      ]
                    : [
                        {
                          label: 'Start typing to get suggestions',
                          value: '',
                        },
                      ]
                  : suggestions.length
                  ? [
                      ...suggestions.map((addr: any) => ({
                        label: addr.address_text,
                        entries: addr.entries,
                        address_id: addr.address_id,
                      })),
                    ]
                  : [
                      {
                        label: 'Start typing to get suggestions',
                        value: '',
                      },
                    ]
              }
              // sx={{ width: 300 }}
              onChange={(e, value: any) => {
                console.log('suggestions ===>', value)
                console.log('options', suggestions)
                if (countryLookup === 'USA') {
                  setQuery('')
                  console.log(suggestions)
                  console.log('=========>ADDRESS=========>', value)

                  if (value) {
                    setValue('address1', value.addr1)
                    setValue('city', value.locality)
                    setValue('zip', value.zip)
                    setValue('state', value.state)
                  }
                  setSuggestions([])
                } else if (value.entries > 1) {
                  console.log('more')
                  get(
                    `https://international-autocomplete.api.smarty.com/v2/lookup/${value.address_id}?country=${countryLookup}&key=${smartyAPIKey}`
                  )
                    .then((res: any) => {
                      setSuggestions(res.candidates)
                      setSuggestionsPlaceHolder(
                        `Click to select from ${res.candidates.length} Local Addresses`
                      )
                    })
                    .catch((err) => console.log(err))
                } else {
                  get(
                    `https://international-autocomplete.api.smarty.com/v2/lookup/${value.address_id}?country=${countryLookup}&key=${smartyAPIKey}`
                  )
                    .then((res: any) => {
                      const { locality, postal_code, street, administrative_area } =
                        res.candidates[0]
                      setValue('address1', street)
                      setValue('city', locality)
                      setValue('zip', postal_code)
                      setValue('state', administrative_area)
                    })
                    .catch((err) => console.log(err))
                }
              }}
              renderInput={(params) => (
                <RHFTextField
                  {...params}
                  sx={{ maxWidth: 850 }}
                  label="Address 1"
                  placeholder={suggestionsPlaceHolder}
                  name="address1"
                  onChange={(e) => {
                    console.log(e.target.value)
                    // setValue('address1', e.target.value)
                    setQuery(e.target.value)
                  }}
                />
              )}
            />
            <RHFTextField
              autoComplete="off"
              sx={{ maxWidth: 850 }}
              name="address2"
              disabled={disableAddress}
              hidden={disableAddress}
              label="Address 2"
            ></RHFTextField>
            <RHFTextField
              autoComplete="off"
              disabled={disableAddress}
              sx={{ maxWidth: 850 }}
              hidden={disableAddress}
              name="city"
              label="City"
            ></RHFTextField>
            <RHFTextField
              autoComplete="off"
              disabled={disableAddress}
              hidden={disableAddress}
              sx={{ maxWidth: 850 }}
              name="state"
              label="State / Province"
            ></RHFTextField>

            <RHFTextField
              autoComplete="off"
              disabled={disableAddress}
              sx={{ maxWidth: 850 }}
              hidden={disableAddress}
              name="zip"
              label="ZIP"
            ></RHFTextField>
          </Stack>
        </>

        {isAddressVerified && (
          <Stack direction={'row'} spacing={1} alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Address is verified</Typography>
            <CheckCircleIcon sx={{ color: 'green' }} />
          </Stack>
        )}

        <Stack spacing={2} sx={{ my: 4 }}>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            Endlet IP Address
          </Typography>
          <RHFTextField
            name="ip"
            placeholder="Type Endlet IP Address"
            autoComplete="off"
            sx={{ maxWidth: 850 }}
          ></RHFTextField>
        </Stack>

        <Typography variant="subtitle1" sx={{ py: 2 }}>
          Authentication
        </Typography>
        <Stack>
          <Typography variant="body2" sx={{ pb: 2 }}>
            Create a new SSH Key
          </Typography>
          <Button
            sx={{ width: 'fit-content', mb: 2 }}
            variant="outlined"
            onClick={() => setOpenNewSSH(true)}
          >
            + New SSH Key
          </Button>

          <Typography variant="body2" sx={{ pb: 2 }}>
            Or Use an Existing SSH Key
          </Typography>
          <Stack
            // spacing={1}
            direction="row"
            sx={{ pb: 2, maxWidth: '100%' }}
            flexWrap="wrap"
          >
            {sshKeys.length
              ? sshKeys.map((key: any, i: number) => (
                  <React.Fragment key={key.name}>
                    <Button
                      sx={{
                        m: 1,
                        textTransform: 'none',
                        wordWrap: 'break-word',
                        minWidth: 120,
                        color: key.checked ? 'primary' : 'darkgray',
                      }}
                      onClick={() => {
                        setValue('ssh', key.sshKeyValue)

                        setSshKeys((prev: any) => [
                          ...prev.map((innerKey: any, j: number) =>
                            i === j
                              ? {
                                  ...innerKey,
                                  checked: key.checked === true ? false : true,
                                }
                              : { ...innerKey, checked: false }
                          ),
                        ])
                      }}
                      variant="outlined"
                      startIcon={
                        key.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                      }
                    >
                      <Typography
                        variant="inherit"
                        sx={{
                          maxWidth: '100%',
                          textOverflow: 'ellipsis',
                          textTransform: 'capitalize',
                        }}
                      >
                        {key.name
                          ? key.name
                              .replace(/-/g, ' ')
                              .replace(orgName?.toLowerCase(), '')
                          : ''}
                      </Typography>
                    </Button>
                  </React.Fragment>
                ))
              : 'No SSH keys added, add a new one'}
          </Stack>
        </Stack>

        <Stack direction={'row'} spacing={1} sx={{ float: 'right', py: 3 }}>
          <Button
            onClick={handleClose}
            variant="text"
            type="button"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          {/* <Button
            onClick={() => {
              setSubmitMethod('save')
            }}
            variant="text"
            type="submit"
            sx={{ color: 'text.secondary' }}
          >
            Save Endlet
          </Button> */}
          <LoadingButton loading={loading} variant="contained" type="submit">
            Create Endlet
          </LoadingButton>
        </Stack>
      </FormProvider>

      <NewSSHModal
        openProps={{
          open: openNewSSH,
          setOpen: setOpenNewSSH,
        }}
        setNewKeys={setNewKeys}
        newKeyFlag={setNewKeyAdded}
      />
    </>
  )
}

export default CreateEPsinglePage
