export const _category = [
  { code: 'Downtime', label: 'Downtime', avatar: `<WarningIcon/>` },
  { code: 'New_Features', label: 'New feature', avatar: ` <CategoryIcon/>` },
  {
    code: 'Billing',
    label: 'Billing',
    avatar: `<MonetizationOnIcon/>';
  `,
  },
]
