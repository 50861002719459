// scroll bar
import 'simplebar/src/simplebar.css'
import './styles.scss'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
// redux
import { store } from './redux/store'
import { Provider as ReduxProvider } from 'react-redux'
// contexts
import { SettingsProvider } from './contexts/SettingsContext'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'
import { AuthProvider } from './contexts/JWTContext'
import SnackbarProvider from './contexts/SnackbarContext'
import ModalContext from './contexts/ModalContext'

//
import App from './App'
import TenantsProvider from './contexts/TenantsContext'
import MessageCountProvider from './contexts/MessageContext'
import CustomersProvider from './contexts/CustomersContext'
import ResellersProvider from './contexts/ResellersContext'
import LogoContext from './contexts/LogoContext'
import LayoutProvider from './contexts/LayoutContext'

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <SnackbarProvider>
      <ModalContext>
        <AuthProvider>
          <HelmetProvider>
            <ReduxProvider store={store}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <TenantsProvider>
                    <MessageCountProvider>
                      <CustomersProvider>
                        <ResellersProvider>
                          <LogoContext>
                            <LayoutProvider>
                              <App />
                            </LayoutProvider>
                          </LogoContext>
                        </ResellersProvider>
                      </CustomersProvider>
                    </MessageCountProvider>
                  </TenantsProvider>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </ReduxProvider>
          </HelmetProvider>
        </AuthProvider>
      </ModalContext>
    </SnackbarProvider>
  </BrowserRouter>
)
