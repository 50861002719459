import { Link as RouterLink } from 'react-router-dom'
// @mui
// import { useTheme } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'
import logo21 from '../assets/21-logo.png'
import { LogosContext } from 'src/contexts/LogoContext'
import { useContext } from 'react'
import useAuth from 'src/hooks/useAuth'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { logoUrl } = useContext(LogosContext)!
  const { organization } = useAuth()

  // const theme = useTheme()

  // const PRIMARY_LIGHT = theme.palette.primary.light

  // const PRIMARY_MAIN = theme.palette.primary.main

  // const PRIMARY_DARK = theme.palette.primary.dark

  const logo = (
    <Box
      component="img"
      src={
        logoUrl.lightLogo ? logoUrl.lightLogo : organization?.light_logo || logo21
      }
      sx={{ height: 40, cursor: 'pointer', ...sx }}
    />
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return <RouterLink to="/">{logo}</RouterLink>
}
