export const _receivers = [
  { code: 'All', label: 'All', avatar: `</>` },
  { code: 'All_Customers', label: 'All Customers', avatar: ` </>` },
  {
    code: 'Specific_Customer',
    label: 'Specific Customer',
    avatar: `</>';
  `,
  },
  { code: 'All_Resellers', label: 'All Resellers', avatar: ` </>` },

  {
    code: 'Specific_Reseller',
    label: 'Specific Reseller',
    avatar: ` </>`,
  },
]
