import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import AddPoint2EP from './AddPoint2EP'
import ConfigureEndletModal from './ConfigureEndletModal'
import IntermidiateEndlet from './IntermidiateEndlet'
import { useNavigate } from 'react-router-dom'
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

interface Props {
  isIpAddedProps: {
    isIPAdded: boolean
    setIsIPAdded: React.Dispatch<React.SetStateAction<boolean>>
  }
  setStateMethodProps: {
    setNewEpAdded: React.Dispatch<React.SetStateAction<boolean>>
  }
  indexProps: {
    each: any
    index: number
    endlet: any
  }
  isSuperUser: boolean
}
function PointToPointServices({
  isIpAddedProps,
  setStateMethodProps,
  indexProps,
  isSuperUser,
}: Props) {
  const navigate = useNavigate()
  const { setIsIPAdded } = isIpAddedProps
  const { setNewEpAdded } = setStateMethodProps
  const { each, index, endlet } = indexProps
  const [openNewEPModal, setOpenNewEPModal] = useState(false)
  const [openConfigureEndlet, setOpenConfigureEndlet] = useState(false)
  const [epStep, setEpStep] = useState(1)
  const [openInterEndlet, setOpenInterEndlet] = useState(false)
  const [assoEP, setAssoEP] = useState(each.associatedEndlets || [])
  const [intEndlet, setIntEndlet] = useState<any>({})
  const [point2Endlet, setPoint2Endlet] = useState<any>({})

  useEffect(() => {
    if (assoEP.length && assoEP.length === 3) {
      const res = assoEP.find((EP: any) => EP.endletType === 'NOD')
      setIntEndlet(res)
      setPoint2Endlet(
        assoEP.find(
          (EP: any) =>
            EP.endletId !== endlet.endletId && EP.endletId !== res.endletId
        )
      )
    } else {
      setPoint2Endlet(
        assoEP.find(
          (EP: any) => EP.endletId !== endlet.endletId && EP.endletType !== 'NOD'
        )
      )
    }
  }, [assoEP, endlet])

  return (
    <div>
      {' '}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-around"
        alignItems={'flex-start'}
        sx={{ mt: 5 }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ m: 0 }}>
          <Stack spacing={1}>
            <LightTooltip title={endlet.endletName} placement="top">
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '80px',
                  height: '32px',
                }}
                // sx={{ width: 'max-content', margin: 'auto' }}
              >
                {endlet.endletName}
              </Typography>
            </LightTooltip>

            <Box>
              <img
                alt={'title'}
                src="/assets/Globe.png"
                style={{ margin: 'auto', height: 50, width: 50 }}
              />
            </Box>
            <Button
              disabled={isSuperUser}
              sx={{
                margin: 'auto',
                width: 'fit-content',
                alignSelf: 'center',
              }}
              variant="outlined"
              onClick={() => (setOpenConfigureEndlet(true), setEpStep(1))}
            >
              Configure
            </Button>
          </Stack>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginLeft: '-20px',
              // marginBottom: '3rem',
              marginTop: assoEP?.length < 3 ? '4rem' : '0rem', //changed
              // marginRight: '-30px',
              marginRight:
                (assoEP?.length === 1 &&
                  assoEP?.filter((asEP: any) => asEP.endletId !== endlet.endletId)
                    .length) ||
                assoEP?.length === 2
                  ? '-20px'
                  : assoEP?.length === 3
                  ? '-17px'
                  : '-43px',
            }}
          >
            <img alt={'title'} src="/assets/Flow.gif" />
            <Button
              disabled={assoEP?.length === 1}
              hidden={assoEP?.length === 3}
              sx={{
                marginTop: '2rem',
                marginBottom: '0px',
                width: 'fit-content',
                alignSelf: 'center',
              }}
              variant="outlined"
              onClick={() => setOpenInterEndlet(true)}
            >
              Add Intermediate Endlet
            </Button>
          </Box>
          {/* ////////////////////////     Intermediate Endlet    //////////////////// */}

          <Stack spacing={1} hidden={assoEP.length !== 3}>
            <LightTooltip title={intEndlet?.endletId} placement="top">
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '80px',
                  height: '32px',
                }}
                // sx={{ width: 'max-content', margin: 'auto' }}
              >
                {intEndlet?.endletId}
              </Typography>
            </LightTooltip>

            <Box>
              <img
                alt={'title'}
                src="/assets/Globe.png"
                style={{ margin: 'auto', height: 50, width: 50 }}
              />
            </Box>
            <Button
              disabled={isSuperUser}
              sx={{
                margin: 'auto',
                width: 'fit-content',
                alignSelf: 'center',
              }}
              variant="outlined"
              onClick={() =>
                navigate(`/endlets/${intEndlet?.endletId}/intermediate`)
              }
            >
              Configure
            </Button>
          </Stack>

          <Box
            hidden={assoEP.length !== 3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // marginBottom: '3rem',
              justifyContent: 'center',
              flexDirection: 'column',
              marginLeft: '-20px',
              // marginTop: '4rem', //changed
              // marginRight: '-30px',
              marginRight: '-15px',
            }}
          >
            <img alt={'title'} src="/assets/Flow.gif" />
          </Box>
          {/* ////////////////////////     END   //////////////////// */}

          <Stack spacing={1}>
            <LightTooltip
              title={(assoEP.length > 1 && point2Endlet?.endletName) || ''}
              placement="top"
            >
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '80px',
                  height: '32px',
                }}
                // sx={{ width: 'max-content', margin: 'auto' }}
              >
                {
                  // each.associatedEndlets.find(
                  //   (ep: any) => ep.endletId !== endlet.endletId
                  // )?.endletName
                  assoEP.length > 1 && point2Endlet?.endletName
                }
              </Typography>
            </LightTooltip>
            <img
              alt={'title'}
              src="/assets/Globe.png"
              style={{ margin: 'auto auto 0px auto', height: 50, width: 50 }}
            />
            <div
              style={{
                whiteSpace: 'nowrap',
                margin: 'auto auto 0px auto',
                width: 'fit-content',
                alignSelf: 'center',
              }}
            >
              <>
                {assoEP.length === 1 && assoEP[0].endletId === endlet.endletId ? (
                  <Button
                    variant="outlined"
                    onClick={() => setOpenNewEPModal(true)}
                    disabled={isSuperUser}
                  >
                    {' '}
                    Add new Endlet
                  </Button>
                ) : (
                  <Button
                    disabled={isSuperUser}
                    sx={{
                      margin: 'auto',
                      width: 'fit-content',
                      alignSelf: 'center',
                    }}
                    variant="outlined"
                    onClick={() => (setOpenConfigureEndlet(true), setEpStep(2))}
                  >
                    Configure
                  </Button>
                )}
              </>
            </div>
          </Stack>
        </Stack>
      </Stack>
      <ConfigureEndletModal
        openProps={{
          open: openConfigureEndlet,
          setOpen: setOpenConfigureEndlet,
        }}
        endletName={epStep === 1 ? endlet.endletName : point2Endlet.endletName}
        id={epStep === 1 ? endlet.endletId! : point2Endlet.endletId}
        setIsIPAdded={setIsIPAdded}
        serviceId={endlet.services[index].id}
      />
      <AddPoint2EP
        openProps={{
          openEp: openNewEPModal,
          setOpenEp: setOpenNewEPModal,
        }}
        endlet={endlet}
        setNewEpAdded={setNewEpAdded}
        currentIndex={index}
      />
      <IntermidiateEndlet
        openProps={{
          open: openInterEndlet,
          setOpen: setOpenInterEndlet,
        }}
        service={endlet.services[index]}
        setNewEpAdded={setNewEpAdded}
      />
    </div>
  )
}

export default PointToPointServices
