import React, { useCallback } from 'react'
import { paramCase } from 'change-case'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import {
  Box,
  Table,
  Tooltip,
  TableBody,
  IconButton,
  TableContainer,
  Skeleton,
  Card,
  Snackbar,
  Alert,
} from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// custom
import useTable from 'src/hooks/useTable'
import { PATH_USER } from 'src/routes/paths'
import { TableNoData, TableSelectedActions } from 'src/components/table'

import Scrollbar from 'src/components/Scrollbar'
import Page from 'src/components/Page'
import Iconify from 'src/components/Iconify'
import { get } from 'src/utils/httpMethods'
import { Reseller } from 'src/pages/super-user/ResellerManagement'
import ResellerCustomerToolbar from './ResellerCustomerToolbar'
import ResellerCustomerRow from './ResellerCustomerRow'

type Props = {
  reseller: Reseller
}

function ResellerUserList({ reseller }: Props) {
  const { dense, selected, setSelected, onSelectRow, onSelectAllRows } = useTable()

  const [users, setUsers] = useState<any[]>([])
  const [userLoading, setuserLoading] = useState(false)

  const navigate = useNavigate()

  const [tableData, setTableData] = useState<any>([])

  const [filterName, setFilterName] = useState('')

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName)
  }
  const getUsers = useCallback(async () => {
    setuserLoading(true)
    try {
      const res: unknown = await get(
        `${process.env.REACT_APP_HOST_API_URL}/super-user/resellers/customers/${reseller.id}`
      )
      const userArr = res as any[]
      setUsers([...userArr])
      setuserLoading(false)
    } catch (e) {
      setuserLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row: any) => row.id !== id)
    setSelected([])
    setTableData(deleteRow)
  }

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row: any) => !selected.includes(row.id))
    // setSelected([])
    setTableData(deleteRows)
  }

  const handleEditRow = (id: string) => {
    navigate(PATH_USER.edit(paramCase(id)))
  }

  useEffect(() => {
    setTableData([...users])
  }, [users])

  // const [rows, setRows] = useState(5)

  // const handleRowChange = (event: SelectChangeEvent) => {
  //   setRows(parseInt(event.target.value))
  // }

  // snackbar
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleSnackBarClose = () => {
    setShowSnackbar(false)
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page title="Manage users" className="mt-3">
        <Card>
          <>
            <ResellerCustomerToolbar
              filterName={filterName}
              onFilterName={handleFilterName}
              id={reseller.id as string}
              refetchData={getUsers}
              snackBarProps={{
                setOpenToast: setShowSnackbar,
                setToastMessage: setSnackbarMessage,
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 600, position: 'relative' }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableBody>
                    {!users.length && !userLoading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TableNoData
                          isNotFound={!userLoading}
                          message={'No user found'}
                        />
                      </Box>
                    ) : users && users.length ? (
                      users.map((row: any) => (
                        <ResellerCustomerRow
                          key={row.id}
                          row={row}
                          orgId={reseller.id as string}
                          refetchUserData={getUsers}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.firstName)}
                        />
                      ))
                    ) : (
                      <Box sx={{ px: 2 }}>
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </>
        </Card>
      </Page>

      <Snackbar
        sx={{ width: '30vw', zIndex: 999999 }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackBarClose}
          icon={<CheckCircleIcon fontSize="inherit" />}
          severity="success"
          variant="outlined"
          sx={{
            width: '100%',
            color: 'text.primary',
            fontWeight: 'bold',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ResellerUserList

// import React, { useCallback } from 'react'
// import { paramCase } from 'change-case'
// import { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// // @mui
// import {
//   Box,
//   Table,
//   Tooltip,
//   TableBody,
//   IconButton,
//   TableContainer,
//   Skeleton,
//   Card,
//   Snackbar,
//   Alert,
// } from '@mui/material'

// import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// // custom
// import useTable from 'src/hooks/useTable'
// import { PATH_USER } from 'src/routes/paths'
// import { TableNoData, TableSelectedActions } from 'src/components/table'

// import Scrollbar from 'src/components/Scrollbar'
// import Page from 'src/components/Page'
// import Iconify from 'src/components/Iconify'
// import { get } from 'src/utils/httpMethods'
// import ResellerCustomerToolbar from './ResellerCustomerToolbar'

// function ResellerCustomer() {
//   const { dense, selected, setSelected, onSelectRow, onSelectAllRows } = useTable()

//   return (
//     <div>
//       <Page title="Manage users" className="mt-3">
//         <Card>
//           <>
//             <ResellerCustomerToolbar
//             //   filterName={''}
//             //   onFilterName={handleFilterName}
//             //   id={'reseller.id' as string}
//             //   refetchData={getUsers}
//             //   snackBarProps={{
//             //     setOpenToast: setShowSnackbar,
//             //     setToastMessage: setSnackbarMessage,
//             //   }}
//             />

//             <Scrollbar>
//               <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
//                 <Table size={dense ? 'small' : 'medium'}>
//                   <TableBody>
//                     <TableNoData isNotFound={true} />
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Scrollbar>
//           </>
//         </Card>
//       </Page>
//     </div>
//   )
// }

// export default ResellerCustomer
