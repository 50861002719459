import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { OpenModalProps } from 'src/@types/modalProps'

import useAuth from 'src/hooks/useAuth'
import { get } from 'src/utils/httpMethods'
import { LoadingButton } from '@mui/lab'
import { useLocation, useNavigate, useParams } from 'react-router'

function AlternateMfaModal({ openProps }: { openProps: OpenModalProps }) {
  const { failedAttempts, verifyBackupCode, verifySmsOTP } = useAuth()
  const { open, setOpen } = openProps
  const [loading, setLoading] = useState(false)

  const { pathname } = useLocation()
  const { num } = useParams()
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
  }
  const [isSelected, setIsSelected] = useState(false)
  const [focusOtp, setFocusOtp] = useState(false)
  const [backupCode, setBackupCode] = useState('')
  const [errors, setErrors] = useState('')
  const [mobileOTP, setMobileOTP] = useState('')

  const [firstOtpSent, setFirstOtpSent] = useState(num === '2')

  const handleClick = () => {
    setIsSelected(!isSelected)
  }
  const handleChange = (e: any) => {
    setBackupCode(e.target.value)
  }

  const onSubmit = async () => {
    setLoading(true)
    if (!focusOtp) {
      try {
        await verifyBackupCode(backupCode)
        setLoading(false)
        setBackupCode('')
      } catch (e) {
        setLoading(false)
        setBackupCode('')
      }
    } else {
      try {
        await verifySmsOTP(mobileOTP)
        setLoading(false)
        setMobileOTP('')
      } catch (e) {
        setLoading(false)
        setMobileOTP('')
      }
    }
  }

  const handleSendOTP = async () => {
    setFocusOtp(true)
    try {
      await get(`/auth/sms-otp`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken') ?? ''}`,
        },
      })
      setFirstOtpSent(true)

      navigate(`/auth/recover/${parseInt(num ?? '0') + 1}`)
    } catch (e) {}
  }

  const [count, setCount] = useState(10)

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => (firstOtpSent ? prevCount - 1 : prevCount))
    }, 1000)

    return () => clearInterval(timer)
  }, [firstOtpSent])

  useEffect(() => {
    setFirstOtpSent((prev) => (count === 0 ? false : prev))
  }, [count])

  console.log(count)
  console.log(num)

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>{failedAttempts.message}</DialogTitle>
      <DialogContent dividers={true} sx={{ width: 550 }}>
        <Stack>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Backup Code
          </Typography>
          <TextField
            name="backup_code"
            value={backupCode}
            placeholder="Enter Backup Code"
            onChange={handleChange}
            disabled={focusOtp}
            onFocus={() => setFocusOtp(false)}
          ></TextField>

          {errors && (
            <Typography sx={{ mt: 2 }} variant="body2" color={'error'}>
              {errors}
            </Typography>
          )}
        </Stack>
        <Divider sx={{ mt: 3, mb: 3, width: '100%', borderColor: 'darkgray' }} />

        <Stack
          alignItems="center"
          spacing={2}
          onClick={handleClick}
          sx={{
            fontWeight: isSelected ? 'bold' : 'normal',
            // backgroundColor: isSelected ? 'lightblue' : 'transparent',
            padding: '15px',
            borderRadius: '5px',
            width: '100%',
          }}
        >
          <Typography variant="body1"> OR </Typography>
          <Typography variant="body1">
            {`send OTP to registered mobile ${
              localStorage.getItem('tempPhone')?.toString() ?? ''
            }`}
          </Typography>

          <Button
            variant="outlined"
            onClick={handleSendOTP}
            // disabled={count !== 0 || num === '2'}
          >
            {num && num > '0' ? 'Resend OTP' : 'Send OTP'}
          </Button>
          {firstOtpSent && num !== '2' && (
            <Typography>{`You can resend OTP after ${count} seconds`}</Typography>
          )}

          <TextField
            fullWidth
            disabled={!focusOtp}
            onFocus={() => setFocusOtp(true)}
            placeholder="Enter OTP"
            onChange={(e) => setMobileOTP(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          onClick={onSubmit}
        >
          Next
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AlternateMfaModal
