import React, { useRef, useState } from 'react'

import Cytoscape from 'react-cytoscapejs'

import { graphStyle } from './graphStyles'
import { graphData } from './mockGraph'
import { Popper, useTheme } from '@mui/material'
import { Typography } from '@mui/material'

export default function CytoscapeTry() {
  const { palette } = useTheme()

  console.log(graphStyle)

  const layoutOptions = {
    name: 'circle',

    // idealEdgeLength: 5,
    // nodeRepulsion: 6000,
    // // gravity: 50,
    // animate: true,
    // animationDuration: 5000,
    // // Add more options as needed
  }

  const [cy, setCy] = useState<cytoscape.Core | null>(null)
  const containerRef = useRef<any>(null)

  const handleNodeMouseEnter = (event: any) => {
    setTooltipVisible(true)
    const node = event.target
    const containerRect = containerRef.current.getBoundingClientRect()
    const nodePos = node.renderedPosition()
    const containerPos = {
      x: containerRect.left,
      y: containerRect.top,
    }
    const position = {
      x: containerPos.x + nodePos.x,
      y: containerPos.y + nodePos.y,
    }
    console.log(position)
    // setAnchorEl(position)
    // Additional logic for mouse enter
  }

  const handleNodeMouseLeave = () => {
    console.log('BYEBYE')
    setAnchorEl(null)

    // Additional logic for mouse leave
  }

  const handleCyInit = (cy: cytoscape.Core) => {
    setCy(cy)

    if (cy) {
      cy.on('mouseover', 'node', handleNodeMouseEnter)
      cy.on('mouseout', 'node', handleNodeMouseLeave)
    }
  }

  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)

  return (
    <div ref={containerRef}>
      <Cytoscape
        cy={handleCyInit}
        elements={graphData}
        stylesheet={[
          ...graphStyle,
          { selector: 'edge', style: { 'line-color': palette.primary.main } },
        ]}
        layout={layoutOptions}
        style={{
          margin: 'auto',
          width: '90%',
          height: '600px',
          border: '1px solid',
          backgroundColor: 'white',
        }}
      />
      <Popper open={tooltipVisible} anchorEl={anchorEl} placement="top">
        <Typography>Tooltip or Popover Content</Typography>
      </Popper>
    </div>
  )
}
