import React, { useContext, useEffect, useState } from 'react'

// @mui
import { Button, Container, TextField, Typography, Divider } from '@mui/material'
import Switch from '@mui/material/Switch'
import { Stack } from '@mui/system'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'

// @rhf
import { FormProvider } from 'src/components/hook-form'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'

import Page from 'src/components/Page'
import useSettings from 'src/hooks/useSettings'

// _mock_
// import { _samlData } from "../../_mock";
import { yupResolver } from '@hookform/resolvers/yup'
import useAuth from 'src/hooks/useAuth'
import { get, post } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'
import { createSecretKey } from 'crypto'

type FormValuesProps = {
  integrationKey: string
  secretKey: string
  apiHostName: string
  corporateDuoEnabled: boolean
}

// ----------------------------------------------------------------------

function DuoCredentials() {
  const { themeStretch } = useSettings()
  const { organization, user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState(organization?.corporateDuoEnabled || false)
  const [originalSecret, setOriginalSecret] = useState('')
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [duoData, setDuoData] = useState<any>({})
  useEffect(() => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/secret?secretName=duo-credentials-${organization?.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    )
      .then((data: any) => {
        setOriginalSecret(
          JSON.parse(data.getSecret.secret.data).secretKey.replace(
            /.(?=.{4,}$)/g,
            '*'
          )
        )
        setDuoData(JSON.parse(data.getSecret.secret.data))
      })
      .catch((error) => console.log(error))
  }, [])

  const defaultValues = {
    integrationKey: '',
    secretKey: '',
    apiHostName: organization?.duoAPIHostname || '',
    corporateDuoEnabled: organization?.corporateDuoEnabled || false,
  }

  const duoScheme = Yup.object().shape({
    integrationKey: Yup.string().required(),
    secretKey: Yup.string().required(),
    apiHostName: Yup.string().required(),
    corporateDuoEnabled: Yup.boolean().required(),
  })
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(duoScheme),
    defaultValues,
  })

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods
  const { integrationKey, apiHostName } = watch()

  const onSubmit = async (data: FormValuesProps) => {
    setLoading(true)
    const finalData = { ...data, corporateDuoEnabled: toggle }
    post(`${process.env.REACT_APP_HOST_API_URL}/auth/duo/credentials`, finalData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((data: any) => {
        console.log('duo creddd=====>', data)
        setLoading(false)
        setSnackbarProps({
          open: true,
          message: `${data.message}`,
          severity: 'success',
        })
      })
      .catch((error) =>
        setSnackbarProps({
          open: true,
          message: `${error.message}`,
          severity: 'success',
        })
      )
  }

  useEffect(() => {
    setValue('integrationKey', duoData?.integrationKey)
    setValue('secretKey', duoData?.secretKey)
  }, [duoData])

  const handleChange = () => {
    setToggle(!toggle)
  }
  console.log(toggle)
  return (
    <>
      <Page title="SSO settings">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Switch
            checked={toggle}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <label htmlFor="toggleSwitch">Corporate Duo</label>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ my: 3 }} hidden={!toggle}>
              <Stack spacing={1}>
                <Typography variant="subtitle1">Integration key</Typography>
                <TextField
                  fullWidth
                  value={integrationKey}
                  name="integrationKey"
                  placeholder="Enter Integration Key"
                  onChange={(e) => setValue('integrationKey', e.target.value)}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="subtitle1">Secret key</Typography>
                <TextField
                  fullWidth
                  value={originalSecret}
                  name="secretKey"
                  placeholder="Enter Secret Key"
                  helperText="Dont write down your secret key or share it with anyone"
                  onChange={(e) => {
                    setOriginalSecret(e.target.value.replace(/.(?=.{4,}$)/g, '*'))
                    setValue('secretKey', e.target.value)
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="subtitle1">API hostname</Typography>
                <TextField
                  fullWidth
                  value={apiHostName}
                  name="apiHostName"
                  placeholder="Enter Secret Key"
                  onChange={(e) => setValue('apiHostName', e.target.value)}
                />
              </Stack>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: 'flex-end', mb: 3 }}
              hidden={!toggle}
            >
              {/* <Button sx={{ color: 'text.disabled' }} type="button">
                Cancel
              </Button> */}
              <LoadingButton loading={loading} variant="contained" type="submit">
                Save changes
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Container>
      </Page>
    </>
  )
}

export default DuoCredentials
