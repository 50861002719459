import React, { useEffect, useState } from 'react'

import { Box, Dialog, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// types
import { OpenModalProps } from 'src/@types/modalProps'

import { Stack } from '@mui/system'
import NewSSHModal from '../Services(Endlets)/NewSSHModal'
import SavedEpGeneralForm from './SavedEpGeneralForm'
import SavedEpTechnicalForm from './SavedEpTechnicalForm'

interface Props {
  openProps: OpenModalProps
  currentRow: any
}

function SavedEndlet({ openProps, currentRow }: Props) {
  console.log(currentRow)
  const { open, setOpen } = openProps

  const [loading, setLoading] = useState(false)

  const [formStep, setFormStep] = useState(1)

  const [endletFields, setEndletFields] = useState<any>()

  const handleClose = () => {
    setOpen(false)
    setFormStep(1)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'lg'}
        scroll="body"
      >
        <Box sx={{ height: '100%', px: 1 }}>
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" sx={{ px: 1, pt: 3 }}>
              Create Endlet ({`Step ${formStep} of 2`})
            </Typography>
            <IconButton sx={{ mr: 2, mt: 3 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{ px: 1 }}>
            {formStep === 1 ? (
              <>
                <SavedEpGeneralForm
                  generalFormProps={{ formStep, setFormStep, handleClose }}
                  formDetails={{ endletFields, setEndletFields, currentRow }}
                />
              </>
            ) : (
              <SavedEpTechnicalForm
                technicalFormProps={{
                  handleClose,
                  setFormStep,
                }}
                formDetails={{ setEndletFields, currentRow }}
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default SavedEndlet

export const COUNTRIES: {
  [key: string]: string | number
} = {
  Albania: 'ALB',
  Algeria: 'DZA',
  Andorra: 'AND',
  Angola: 'AGO',
  Argentina: 'ARG',
  Aruba: 'ABW',
  Australia: 'AUS',
  Austria: 'AUT',
  Azerbaijan: 'AZE',
  Bahamas: 'BHS',
  Bahrain: 'BHR',
  Barbados: 'BRB',
  Belarus: 'BLR',
  Belgium: 'BEL',
  Belize: 'BLZ',
  Benin: 'BEN',
  Bermuda: 'BMU',
  Bolivia: 'BOL',
  'Bosnia and Herzegovina': 'BIH',
  Botswana: 'BWA',
  Brazil: 'BRA',
  'Brunei Darussalam': 'BRN',
  Bulgaria: 'BGR',
  'Burkina Faso': 'BFA',
  Burundi: 'BDI',
  Cameroon: 'CMR',
  Canada: 'CAN',
  Chile: 'CHL',
  China: 'CHN',
  Colombia: 'COL',
  'Congo-Brazzaville': 'COG',
  'Congo-Kinshasa': 'COD',
  'Costa-Rica': 'CRI',
  Croatia: 'HRV',
  Cuba: 'CUB',
  Cyprus: 'CYP',
  'Czech Republic': 'CZE',
  Denmark: 'DNK',
  'Dominican Republic': 'DOM',
  Ecuador: 'ECU',
  Egypt: 'EGY',
  'El Salvador': 'SLV',
  Estonia: 'EST',
  Finland: 'FIN',
  France: 'FRA',
  Gabon: 'GAB',
  Georgia: 'GEO',
  Germany: 'DEU',
  Ghana: 'GHA',
  Greece: 'GRC',
  Guatemala: 'GTM',
  Guyana: 'GUY',
  Honduras: 'HND',
  'Hong Kong': 'HKG',
  Hungary: 'HUN',
  Iceland: 'ISL',
  India: 'IND',
  Indonesia: 'IDN',
  Iraq: 'IRQ',
  Ireland: 'IRL',
  Israel: 'ISR',
  Italy: 'ITA',
  Jamaica: 'JAM',
  Japan: 'JPN',
  Jordan: 'JOR',
  Kazakhstan: 'KAZ',
  Kenya: 'KEN',
  'Korea (South)': 'KOR',
  Kosovo: 'XKX',
  Kuwait: 'KWT',
  Latvia: 'LVA',
  Lebanon: 'LBN',
  Lesotho: 'LSO',
  'Liechtenstein (Territory CHE)': 'LIE',
  Lithuania: 'LTU',
  Luxembourg: 'LUX',
  Macau: 'MAC',
  Macedonia: 'MKD',
  Malawi: 'MWI',
  Malaysia: 'MYS',
  Mali: 'MLI',
  Malta: 'MLT',
  Mauritania: 'MRT',
  Mauritius: 'MUS',
  Mexico: 'MEX',
  Montenegro: 'MNE',
  Morocco: 'MAR',
  Mozambique: 'MOZ',
  Namibia: 'NAM',
  Netherlands: 'NLD',
  'New Zealand': 'NZL',
  Nicaragua: 'NIC',
  Niger: 'NER',
  Nigeria: 'NGA',
  Norway: 'NOR',
  Oman: 'OMN',
  Panama: 'PAN',
  Paraguay: 'PRY',
  Peru: 'PER',
  Philippines: 'PHL',
  Poland: 'POL',
  Portugal: 'PRT',
  Qatar: 'QAT',
  Romania: 'ROU',
  Russia: 'RUS',
  Rwanda: 'RWA',
  'Saint Kitts and Nevis': 'KNA',
  'Saudi Arabia': 'SAU',
  Senegal: 'SEN',
  Serbia: 'SRB',
  Singapore: 'SGP',
  Slovakia: 'SVK',
  Slovenia: 'SVN',
  'South Africa': 'ZAF',
  Spain: 'ESP',
  Suriname: 'SUR',
  Swaziland: 'SWZ',
  Sweden: 'SWE',
  Switzerland: 'CHE',
  Liechtenstein: 'CHE',
  Taiwan: 'TWN',
  Tajikistan: 'TJK',
  Tanzania: 'TZA',
  Thailand: 'THA',
  Togo: 'TGO',
  'Trinidad and Tobago': 'TTO',
  Tunisia: 'TUN',
  Turkey: 'TUR',
  Uganda: 'UGA',
  Ukraine: 'UKR',
  'United Arab Emirates': 'ARE',
  'United Kingdom': 'GBR',
  Uruguay: 'URY',
  Venezuela: 'VEN',
  'Viet Nam': 'VNM',
  Yemen: 'YEM',
  Zambia: 'ZMB',
  Zimbabwe: 'ZWE',
}
