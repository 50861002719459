import React, { useState } from 'react'

import Label from '../../components/Label'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

// @mui----------------------------------
import { Box, Typography, useTheme, Popover } from '@mui/material'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))
const useStyles = makeStyles((theme: any) => ({
  popoverContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  tags: any
  index: number
  data: any
}
function Tags({ tags, index, data }: Props) {
  const theme = useTheme()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [indexOfClickedTag, setIndexOfClickedTag] = useState(0)

  const handleClick = (event: any) => {
    setIndexOfClickedTag(index)
    if (tagLengthCounter() > 20 || tags.length > 3) setIsOpen(!isOpen)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }
  //method to count total tags length
  const tagLengthCounter = () => {
    return tags.reduce((count: number, tag: string) => count + tag.length, 0)
  }

  return (
    <div>
      {tags[0]?.length > 22 ? (
        <>
          <BootstrapTooltip title={'Click to view all tags'}>
            <Typography onClick={(e) => handleClick(e)}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                sx={{ m: 1 }}
                style={{
                  color: 'grey',
                  backgroundColor: 'grey',
                  whiteSpace: 'nowrap',
                }}
              >
                {tags[0].substring(0, 22) + '...'}
              </Label>
            </Typography>
          </BootstrapTooltip>
        </>
      ) : tags.length > 3 || tagLengthCounter() > 20 ? (
        <>
          <BootstrapTooltip title={'Click to view all tags'}>
            <Typography
              style={{
                maxWidth: 160,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              onClick={(e) => handleClick(e)}
            >
              {tags.map((tag: any) => {
                console.log(tag.length, index)
                return (
                  <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    sx={{ m: 1 }}
                    style={{
                      color: 'grey',
                      backgroundColor: 'grey',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {tag}
                  </Label>
                )
              })}
            </Typography>
          </BootstrapTooltip>
        </>
      ) : (
        <>
          {tags.map((tag: any) => {
            console.log(tag.length, index)
            return (
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                sx={{ m: 1 }}
                style={{
                  color: 'grey',
                  backgroundColor: 'grey',
                  whiteSpace: 'nowrap',
                }}
              >
                {tag}
              </Label>
            )
          })}
        </>
      )}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            maxWidth: 300,
            minWidth: 200,
          },
        }}
      >
        <Box className={classes.popoverContent}>
          {data &&
            data[indexOfClickedTag]?.tags?.map((tag: any) => {
              return (
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  style={{
                    color: 'grey',
                    backgroundColor: 'grey',
                  }}
                  sx={{
                    textTransform: 'none',
                    m: 1,
                  }}
                >
                  {tag}
                </Label>
              )
            })}
        </Box>
      </Popover>
    </div>
  )
}

export default Tags
