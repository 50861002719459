import React from 'react'

// components
import { FirewallTabView, IpBlocksTabView } from './Configure Endlet Tabs'

import { OpenModalProps } from 'src/@types/modalProps'

// @mui
import { Dialog, IconButton, SvgIcon } from '@mui/material'
import { ReactComponent as FirewallIcon } from '../../../assets/Firewall.svg'
import { ReactComponent as IPaddress } from 'src/assets/ipAddress.svg'
import CloseIcon from '@mui/icons-material/Close'

import useTabs from 'src/hooks/useTabs'
import { Tabs } from '@mui/material'
import { Tab } from '@mui/material'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { Stack } from '@mui/material'

interface Props {
  openProps: OpenModalProps
  endletName: string
  isHnS?: boolean // to determine whether to show hub and spoke selector
  id: string
  // associatedEndlet?: any
  serviceId?: string
  setIsIPAdded: React.Dispatch<React.SetStateAction<boolean>>
}

export interface IPAddress {
  // id?: number
  subnet: string
  IP: string
  prefix: string
  description: string
  IPVersion: string
  IPBlock: string
}
export interface EndletInterfaces {
  addresses: IPAddress[]
  createdAt: { _seconds: string; _nanoseconds: string }
  endletId: string
  id: string
  name: string
  updatedAt: { _seconds: string; _nanoseconds: string }
  wanIP?: boolean
}

function ConfigureEndletModal({
  openProps: { open, setOpen },
  endletName,
  id,
  isHnS,
  serviceId,
  setIsIPAdded,
}: // associatedEndlet,
Props) {
  const { currentTab, onChangeTab, setCurrentTab } = useTabs('ip-blocks')

  // TABS array
  const ConfigTabs = [
    {
      value: 'ip-blocks',
      icon: (
        <SvgIcon>
          <IPaddress height={25} width={25} />
        </SvgIcon>
      ),
      // icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: (
        <IpBlocksTabView
          openProps={{ open, setOpen }}
          isHnS={isHnS ? isHnS : false}
        />
      ),
      label: 'IP blocks',
    },
    {
      value: 'firewall',
      icon: (
        <SvgIcon>
          <FirewallIcon height={25} width={25} />
        </SvgIcon>
      ),
      component: (
        <FirewallTabView
          openProps={{ open, setOpen }}
          isHnS={isHnS ? isHnS : false}
        />
      ),
      label: 'Firewall',
    },
  ]

  const handleClose = () => {
    console.log('^^^^^^^ closing modal ^^^^^^^')
    setOpen(false)
  }

  console.log(currentTab)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullScreen
      maxWidth={'lg'}
      sx={{ m: 5 }}
    >
      <Box sx={{ px: 2, pt: 3 }}>
        <Stack
          direction="row"
          justifyContent={'space-between'}
          width="100%"
          alignItems={'center'}
        >
          <Typography variant="h6">{endletName}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ConfigTabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
              onClick={() => {
                // navigate(`${PATH_USER.root}/${tab.value}`)
                setCurrentTab(tab.value)
              }}
            />
          ))}
        </Tabs>
        <Box>
          {ConfigTabs.map((tab) => {
            const isMatched = tab.value === currentTab
            return isMatched && <Box key={tab.value}>{tab.component}</Box>
          })}
        </Box>
      </Box>
    </Dialog>
  )
}

export default ConfigureEndletModal
