import React, { useState, useEffect, useContext } from 'react'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Dialog, Grid } from '@mui/material'
import { OpenModalProps } from 'src/@types/modalProps'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Button } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import { get, put } from 'src/utils/httpMethods'
import AddNewVwan from './AddNewVwan'
import { LoadingButton } from '@mui/lab'
import { EndletContext } from './EndletDetails'

interface Props {
  openProps: OpenModalProps
  endlet: any

  id: string
  currentIndex: any
}

function ChangevWANs({
  openProps: { open, setOpen },
  endlet,

  id,
  currentIndex,
}: Props) {
  const { organization } = useAuth()
  const [existingVWan, setExistingVWan] = useState<any>([])
  const [openVWAN, setOpenvWAN] = useState(false)
  const [loading, setLoading] = useState(false)
  const { endlet: elt, getEndlets } = useContext(EndletContext)!

  //Get all existing VWANs(services) for the organization
  const getExistingVWan = async () => {
    console.log('in ashdggdd')
    try {
      await get(
        `${process.env.REACT_APP_HOST_API_URL}/services/organization/${organization?.id}`
      ).then((response: any) => {
        console.log(response)

        const IdleServices = response?.map((res: any, index: number) => ({
          ...res,
          checked: res.id === endlet.services[currentIndex]?.id ? true : false,
        })) //all Idle services containing currently selected service

        const currentConsumedService = {
          ...endlet.services[currentIndex],
          checked: true,
        } //currently selected service for endlet

        //to show current consumed service selected as it is not in response
        const finalExistingVWANs =
          !response?.some(
            (res: any) => res.id === endlet.services[currentIndex]?.id
          ) && endlet.services.length
            ? [...IdleServices, currentConsumedService]
            : [...IdleServices]

        setExistingVWan(finalExistingVWANs.reverse())
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (open) getExistingVWan()
  }, [open])

  const handleSubmit = () => {
    const x = existingVWan.find((vWAN: any) => vWAN.checked)
    setLoading(true)

    //To remove current endlet from previous service
    if (endlet.services.length) {
      const currentActiveService = endlet.services[currentIndex]
      const data = {
        service: {
          associatedEndlets: currentActiveService.associatedEndlets.filter(
            (curr: any) => curr.endletId !== endlet.endletId
          ),
          // status: 'Idle',
          // deploymentStatus: 'Configured',
        },
      }
      put(
        `${process.env.REACT_APP_HOST_API_URL}/services/${currentActiveService.id}`,
        data
      )
        .then((response: any) => {
          updateEndlet()
        })
        .catch((e) => {
          console.log(e)
          setLoading(false)
          handleClose()
        })
    } else {
      updateEndlet()
    }
  }
  const updateEndlet = () => {
    const x = existingVWan.find((vWAN: any) => vWAN.checked)

    const finalServiceArr = [...endlet.services]
    finalServiceArr[currentIndex] = { id: x.id, name: x.name }
    const newService = {
      endlet: {
        services: [...finalServiceArr],
      },
    }

    put(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/${endlet?.endletId}`,
      newService
    )
      .then((response: any) => {
        setLoading(false)
        updateService(x)
        handleClose()
        getEndlets()
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        handleClose()
      })
  }
  const handleClose = () => {
    setOpen(false)
  }

  const updateService = (service: any) => {
    const mesh_data = {
      service: {
        associatedEndlets: !service.associatedEndlets.some(
          (ep: any) => ep.endletId === endlet.endletId
        )
          ? [
              ...service.associatedEndlets,
              {
                endletId: endlet.endletId,
                endletName: endlet.endletName,
                designation: elt.designation ? elt.designation : '',
              },
            ]
          : [...service.associatedEndlets],
        // status: 'Idle',
      },
    }

    const p2p_assoEp_arr =
      service.associatedEndlets.length === 1 &&
      !service.associatedEndlets.some((ep: any) => ep.endletId === endlet.endletId)
        ? [
            ...service.associatedEndlets,
            { endletId: endlet.endletId, endletName: endlet.endletName },
          ] //if selected service is not associated with the current endlet
        : service.associatedEndlets.length === 2 &&
          service.associatedEndlets.some(
            (ep: any) => ep.endletId === endlet.endletId
          )
        ? [...service.associatedEndlets] // if selected service is already associated with the current endlet
        : [{ endletId: endlet.endletId, endletName: endlet.endletName }] //if service is not associated with any endlet

    const P2P_data = {
      service: {
        associatedEndlets: p2p_assoEp_arr,
        // status: p2p_assoEp_arr.length === 2 ? 'Consumed' : 'Idle',
      },
    }
    const finalData =
      service.subType === 'Point To Point'
        ? P2P_data
        : service.subType === 'Full Mesh' || service.subType === 'Hub And Spoke'
        ? mesh_data
        : ''
    put(`${process.env.REACT_APP_HOST_API_URL}/services/${service.id}`, finalData)
      .then((response: any) => {
        getEndlets()
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        handleClose()
      })
  }
  console.log(existingVWan)
  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ p: 2, width: 500 }}>
        <Typography variant="h6" sx={{ my: 1 }}>
          Change vWAN
        </Typography>
        <Stack
          direction={'row'}
          alignItems="center"
          sx={{ mb: 4 }}
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">Existing vWAN's</Typography>
          <Button
            sx={{ width: 'fit-content', textTransform: 'none' }}
            variant="outlined"
            onClick={() => setOpenvWAN(true)}
          >
            + Add new vWAN
          </Button>
        </Stack>
        <Grid container spacing={1} sx={{ pb: 2 }}>
          {existingVWan.length ? (
            existingVWan.map((key: any, i: number) => (
              <Grid item key={i}>
                <React.Fragment key={key.name}>
                  <Button
                    sx={{
                      textTransform: 'none',
                      wordWrap: 'break-word',
                      minWidth: 120,
                      color: key?.checked ? 'primary' : 'darkgray',
                    }}
                    onClick={() => {
                      // setValue('currentService', key.name)
                      setExistingVWan((prev: any) => [
                        ...prev.map((innerKey: any, j: number) =>
                          i === j
                            ? {
                                ...innerKey,
                                checked: true,
                              }
                            : { ...innerKey, checked: false }
                        ),
                      ])
                    }}
                    variant="outlined"
                    startIcon={
                      key.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                    }
                  >
                    <Typography
                      variant="inherit"
                      sx={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {key.name}
                    </Typography>
                  </Button>
                </React.Fragment>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ p: 1 }}>
              No vWAN added, add a new one
            </Typography>
          )}
        </Grid>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            float: 'right',
            px: 0,
            my: 2,
          }}
          spacing={1}
        >
          <Button
            onClick={handleClose}
            variant="text"
            type="button"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>

      <AddNewVwan
        openProps={{
          open: openVWAN,
          setOpen: setOpenvWAN,
        }}
        getExistingVWan={getExistingVWan!}
        id={id}
        serviceTp={elt.serviceType[currentIndex]}
      />
    </Dialog>
  )
}

export default ChangevWANs
