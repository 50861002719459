import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { Card } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import moment from 'moment'
import Page from 'src/components/Page'
import useAuth from 'src/hooks/useAuth'
import useSettings from 'src/hooks/useSettings'
import { Button } from '@mui/material'
import { get, del } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

import NewSSHModal from 'src/sections/@dashboard/Services(Endlets)/NewSSHModal'
import { LoadingButton } from '@mui/lab'
import { TableNoData } from 'src/components/table'

function SSHsettings() {
  const { themeStretch } = useSettings()
  const { organization, UseNewUserData } = useAuth()
  const [keys, setKeys] = useState<any>([])!
  const orgName = organization?.name
  const [openSSH, setOpenSSH] = useState(false)
  const [newKeyAdded, setNewKeyAdded] = useState(false)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  // to disable button
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>(undefined)

  // api call
  const deleteSSH = async (
    ssh: {
      sshKeyName: string
      sshKeyId: string
    },
    index: number
  ) => {
    setDeleteIndex(index)
    del(
      `${process.env.REACT_APP_HOST_API_URL}/auth/organization/ssh/${ssh.sshKeyId}`
    )
      .then((res) => {
        console.log(res)
        getSshKeys()
        setSnackbarProps({
          open: true,
          message: `SSH key ${ssh.sshKeyName} deleted`,
          severity: 'success',
        })
        setDeleteIndex(undefined)
        setNewKeyAdded(false)
      })
      .catch((e) => console.log(e))
  }

  const getSshKeys = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/auth/organization/ssh`)
      .then((res) => {
        setKeys(Array.isArray(res) ? res : [])
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getSshKeys()
  }, [newKeyAdded])

  console.log(newKeyAdded)
  return (
    <Page title="SSH Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card sx={{ p: 3 }}>
          <Stack
            direction={'row'}
            sx={{ mb: 2 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">SSH Keys</Typography>
            <Button variant="contained" onClick={() => setOpenSSH(true)}>
              {' '}
              + Add New SSH
            </Button>
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Below is a list of SSH Keys that are attached to your company. All keys
            are utilized only once when the endlet is initially configured. If the
            key is not configured on another endlet and utilized for the initial
            deployment of another endlet within 30 days of the SSH Key configuration
            within the 21Packets Portal, it will automatically be purged from our
            system.
          </Typography>

          {keys?.length ? (
            keys?.map((sshKey: any, i: number) => (
              <Box
                sx={{
                  borderRadius: '5px',
                  border: '1px solid lightgray',
                  my: 2,
                  p: 2,
                }}
                key={i}
              >
                <Stack>
                  <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    sx={{ mb: 2 }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {sshKey.sshKeyName
                        .replace(/-/g, ' ')
                        .replace(orgName?.toLowerCase(), '')}
                    </Typography>
                    <LoadingButton
                      loading={deleteIndex === i ? true : false}
                      size="small"
                      color="error"
                      onClick={() =>
                        deleteSSH(
                          {
                            sshKeyName: sshKey.sshKeyName,
                            sshKeyId: sshKey.id,
                          },
                          i
                        )
                      }
                    >
                      Delete
                    </LoadingButton>
                  </Stack>
                  <Stack direction={'row'} justifyContent="space-between">
                    <Typography variant="body2">
                      {`Created on
                    ${
                      sshKey.createdAt
                        ? moment
                            .unix(sshKey.createdAt._seconds)
                            .format('MMMM D, YYYY')
                        : ''
                    } and last used ${
                        sshKey.lastUsedAt
                          ? moment
                              .unix(sshKey.lastUsedAt._seconds)
                              .format('MMMM D, YYYY')
                          : ''
                      }`}
                    </Typography>

                    <Typography variant="body2">
                      {`Added by
                    ${sshKey.createdBy ? sshKey.createdBy : ''}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                // borderRadius: '5px',
                // border: '1px solid lightgray',
                my: 2,
                p: 2,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 700, color: 'gray' }}>
                No SSH keys added in your org!
              </Typography>
            </Box>
          )}
        </Card>
      </Container>
      <NewSSHModal
        openProps={{ open: openSSH, setOpen: setOpenSSH }}
        newKeyFlag={setNewKeyAdded}
      />
    </Page>
  )
}

export default SSHsettings
