import { Card, Typography } from '@mui/material'
import React from 'react'
import Page from 'src/components/Page'

function IntermidiateEdletDetails() {
  return (
    <Page title="Intermediate Endlet">
      <Card sx={{ height: 200, p: 3, m: 3, display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ color: 'GrayText', m: 2, fontWeight: 'bold' }}>
          Intermediate Node Additional Services Currently in Development. We will
          notify you when these Services are available.
        </Typography>
      </Card>
    </Page>
  )
}

export default IntermidiateEdletDetails
