// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import MotionLazyContainer from './components/animate/MotionLazyContainer'

import { useEffect } from 'react'
import { initFirebaseApp } from './utils/firebase'
import useAuth from './hooks/useAuth'
import LoadingScreen from './components/LoadingScreen'
import { useLocation } from 'react-router'

// ----------------------------------------------------------------------

export default function App() {
  const { processSSORedirectCallback, isLoadingUser } = useAuth()
  const { pathname } = useLocation()
  // Initialize Firebase & Auth service
  useEffect(() => {
    // Initialize Firebase app
    initFirebaseApp()
    // SSO: Process the data received from SAML Identity provider after redirected from their site to here
    processSSORedirectCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ProgressBarStyle />
          <ScrollToTop />
          {isLoadingUser && !pathname.includes('auth') ? (
            <LoadingScreen />
          ) : (
            <Router />
          )}
          {/* <Router /> */}
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  )
}
