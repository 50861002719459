import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import useTabs from '../../hooks/useTabs'
import PasswordIcon from '@mui/icons-material/Password'
import SSHsettings from './SSHsettings'
import KeyIcon from '@mui/icons-material/Key'
import DuoIcon from '@mui/icons-material/Duo'
import SAMLsettings from './SAMLsettings'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import AccountSecurityCreds from 'src/sections/@dashboard/user/account/AccountSecurityCreds'
import { useNavigate, useLocation } from 'react-router'
import { PATH_USER } from '../../routes/paths'
import DuoCredentials from 'src/sections/@dashboard/user/account/DuoCredentials'
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock'

function SecuritySettings() {
  const { pathname } = useLocation()
  const activeTab = pathname.split('/').slice(-1)
  const { currentTab, onChangeTab, setCurrentTab } = useTabs(activeTab[0])
  const navigate = useNavigate()
  const SECURITY_TABS = [
    {
      value: 'security',
      icon: <PasswordIcon width={20} height={20} />,
      component: <SSHsettings />,
      label: 'SSH Keys',
    },

    {
      value: 'sso',
      icon: <KeyIcon width={20} height={20} />,
      component: <SAMLsettings />,
      label: 'SSO (Single Sign On)',
    },

    {
      value: 'security-credentials',
      icon: <VpnKeyIcon width={20} height={20} />,
      component: <AccountSecurityCreds />,
      label: 'API Tokens',
    },
    {
      value: 'duo-credentials',
      icon: <PhonelinkLockIcon width={20} height={20} />,
      component: <DuoCredentials />,
      label: 'Duo Credentials',
    },
  ]
  useEffect(() => {
    setCurrentTab(activeTab[0])
  }, [activeTab, setCurrentTab])
  console.log(currentTab)
  return (
    <div>
      <Tabs
        sx={{ ml: 3 }}
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {SECURITY_TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
            onClick={() =>
              navigate(
                tab.value === 'security'
                  ? `${PATH_USER.root}/admin/security`
                  : `${PATH_USER.root}/admin/security/${tab.value}`
              )
            }
          />
        ))}
      </Tabs>
      <Box sx={{ mb: 5, overflow: 'auto' }} />

      {SECURITY_TABS.map((tab) => {
        const isMatched = tab.value === currentTab
        return isMatched && <Box key={tab.value}>{tab.component}</Box>
      })}
    </div>
  )
}

export default SecuritySettings
