import { Box, BoxProps } from '@mui/material'
import React from 'react'
import condensed from '../assets/ic_logo_condensed.svg'

function LogoCondensed({ sx }: BoxProps) {
  const logo = (
    <Box
      component="img"
      src={condensed}
      sx={{ height: 40, cursor: 'pointer', ...sx }}
    />
  )

  return <>{logo}</>
}

export default LogoCondensed
