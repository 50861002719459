import React, { useContext, useState } from 'react'
import * as Yup from 'yup'

import { Card, CardHeader, List } from '@mui/material'

import { yupResolver } from '@hookform/resolvers/yup'

import { Reseller } from 'src/pages/super-user/ResellerManagement'

import { Box } from '@mui/system'
import useIsMountedRef from 'src/hooks/useIsMountedRef'
import { useForm } from 'react-hook-form'

import { FormProvider, RHFTextField } from 'src/components/hook-form'
import { post } from 'src/utils/httpMethods'
import { LoadingButton } from '@mui/lab'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { ResellersContext } from 'src/contexts/ResellersContext'
import ResellerNoteRow from './ResellerNoteRow'

type FormValuesProps = {
  text: string
  afterSubmit?: string
}

const NoteSchema = Yup.object().shape({
  text: Yup.string().required('Note cannot be blank'),
})

interface Props {
  reseller: Reseller
}

function ResellerNotes({ reseller }: Props) {
  const { notes } = reseller

  const [showLoader, setShowLoader] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const value = useContext(ResellersContext)!

  const { changeCompany: setSelected } = value

  // RHF
  const isMountedRef = useIsMountedRef()

  const defaultValues = {
    text: '',
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NoteSchema),
    defaultValues,
  })

  const { reset, setError, handleSubmit } = methods

  const onSubmit = async (data: FormValuesProps) => {
    console.log("in submit")
    try {
      setShowLoader(true)
      await post(`/resellers/${reseller.id}/notes`, data)

      setSelected(reseller)
      setSnackbarProps({
        open: true,
        severity: 'success',
        message: 'Note Added',
      })

      setShowLoader(false)

      reset()
    } catch (error) {
      console.error(error)
      setShowLoader(false)
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Error while adding note`,
      })

      reset()

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message })
      }
    }
  }

  return (
    <>
      <Card className="mt-3">
        <CardHeader title="Notes" />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ px: 2, my: 3 }}>
            <RHFTextField multiline minRows={3} fullWidth label="Note" name="text" />
            <LoadingButton
              variant="contained"
              sx={{ float: 'right', my: 2 }}
              type="submit"
              loading={showLoader}
            >
              + Add
            </LoadingButton>
          </Box>
        </FormProvider>
        <List sx={{ mt: 8 }}>
          {notes?.map((note, i) => (
            <ResellerNoteRow note={note} index={i} />
          ))}
        </List>
      </Card>
    </>
  )
}

export default ResellerNotes
