import React, { createContext, ReactNode, useEffect, useState } from 'react'

interface MessageContextType {
  unread: string
  setUnread: React.Dispatch<React.SetStateAction<string>>
}

export const MessageContext = createContext<MessageContextType | null>(null)

function MessageCountProvider({ children }: { children: ReactNode }) {
  const [unread, setUnread] = useState(localStorage.getItem('unread') || '')
 
  useEffect(() => {
    localStorage.setItem('unread', unread)
  }, [unread])

  return (
    <MessageContext.Provider value={{ unread, setUnread }}>
      {children}
    </MessageContext.Provider>
  )
}

export default MessageCountProvider
