import _mock from './_mock'
// const incidents = ['Security incident', 'incident']

// const status = ['New', 'Waiting for customer']

// export const TICKETS_DATA = [...Array(10)].map((_, i) => ({
//   type: incidents[i % 2],
//   caseId: _mock.id(i),
//   subject: `Site ${i + 3} Firewall rebooted`,
//   severity: `P${((i + 4) % 4) + 1}`,
//   created: _mock.time(i),
//   changed: _mock.time(i + 3),
//   primary_contact: `${_mock.name.firstName(i)} ${_mock.name.lastName(i)}`,
//   status: status[i % 2],
// }))

export const columns = [
  {
    id: 'service_name',
    label: 'Service Name',
  },

  {
    id: 'service_type',
    label: 'Service Type',
  },
  {
    id: 'tags',
    label: 'Tags',
  },

  {
    id: 'status',
    label: 'Status',
  },
]
