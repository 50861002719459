import { useDropzone } from 'react-dropzone'
// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// type
import { UploadProps } from './type'
//
import Image from '../Image'
import Iconify from '../Iconify'
import RejectionFiles from './RejectionFiles'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
      margin: 0,
    },
  },
}))

const DropZoneStyle = styled('div')({
  zIndex: 0,
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  borderRadius: '50%',
  justifyContent: 'center',
  // '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
})

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  borderRadius: '50%',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}))

// ----------------------------------------------------------------------

export default function UploadAvatar({
  error,
  file,
  helperText,
  sx,
  logoInfo,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } =
    useDropzone({
      multiple: false,
      ...other,
    })

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...(!logoInfo?.isOrgLogo && {
              '& > *': { width: '100%', height: '100%' },
            }),
            ...(logoInfo?.showDarkBack && { backgroundColor: '#030637' }),
          }}
        >
          <input {...getInputProps()} />

          {file && (
            <Image
              alt="avatar"
              src={typeof file === 'string' ? file : file.preview}
              sx={{ zIndex: 8 }}
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              cursor: 'pointer',
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': {
                  opacity: 0.72,
                  cursor: 'pointer',
                },
                cursor: 'pointer',
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Iconify
              icon={'ic:round-add-a-photo'}
              sx={{ width: 24, height: 24, mb: 1 }}
            />
            <Typography variant="caption">
              {file ? 'Update Photo' : 'Upload Photo'}
            </Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {helperText && helperText}

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}
    </>
  )
}
