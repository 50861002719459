// some actions are nested in table rows so the snackbar is hidden behind the nav, for such cases use this component and its context to call snackbars / toasters
import React, { useContext } from 'react'

// @mui
import { Alert, Snackbar } from '@mui/material'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

function SnackbarTopLevel() {
  const { snackbarProps, setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const { open, severity, message } = snackbarProps!

  const handleCloseSnackbar = () => {
    setSnackbarProps((prev) => ({ ...prev, open: false }))
  }
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={20000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseSnackbar}
        style={{ zIndex: 99999 }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="outlined"
          sx={{
            width: '100%',
            color: 'text.primary',
            fontWeight: 'bold',
            zIndex: 99999,
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SnackbarTopLevel
