import React, { useContext, useEffect, useState } from 'react'
import { Delete, Save } from '@mui/icons-material'
import {
  CircularProgress,
  Fab,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  useTheme,
} from '@mui/material'
import EditableTableCell from './EditableTableCell'
import { IPAddress } from '../ConfigureEndletModal'
import { ConfigureInterfaceContext } from './UpdateInterfaceForm'

interface Props {
  address: IPAddress
}
function InterfaceIPv6Row({ address }: Props) {
  const { palette } = useTheme()
  const [rowEdit, setRowEdit] = useState(false)
  const [rowIPobject, setRowIPobject] = useState({ ...address })
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const { IP, description, prefix } = rowIPobject

  useEffect(() => {
    setRowIPobject({ ...address })
  }, [address])

  // import API methods from toplevel context
  const {
    addInterfaceIPv6,
    updateInterfaceIPv6,
    deleteInterfaceIPv6,
    userMethod,
    softDeleteV6,
    tableDisabled,
    deleteDisabled,
    setDeleteDisabled,
  } = useContext(ConfigureInterfaceContext)!

  console.log(userMethod)

  const [errors, setErrors] = useState<{
    IP: string | null
    prefix: string | null
  }>({ IP: null, prefix: null })

  const handleSubmit = async () => {
    console.log(rowIPobject)
    setLoading(true)
    const ipRegex =
      /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/

    const prefixRegex = /^[0-9]*$/
    const errorsLocal = {
      IP: ipRegex.test(rowIPobject.IP) ? null : 'Inavlid IP address',
      prefix: prefixRegex.test(rowIPobject.prefix) ? null : 'Inavlid prefix',
    }
    setErrors({ ...errorsLocal })

    if (!errorsLocal.IP && !errorsLocal.prefix) {
      userMethod === 'added'
        ? await addInterfaceIPv6(rowIPobject)
        : await updateInterfaceIPv6(rowIPobject, address)
      setRowEdit(false)
    }
    setLoading(false)
  }
  console.log(errors)
  return (
    <TableRow>
      <EditableTableCell
        rowProps={{ rowEdit, setRowEdit, rowIPobject, setRowIPobject }}
        text={IP}
        cellId="IP"
        error={errors.IP}
      />

      <EditableTableCell
        rowProps={{ rowEdit, setRowEdit, rowIPobject, setRowIPobject }}
        text={prefix}
        cellId="prefix"
        error={errors.prefix}
      />

      <EditableTableCell
        rowProps={{ rowEdit, setRowEdit, rowIPobject, setRowIPobject }}
        text={description}
        cellId="description"
        error={null}
      />

      <TableCell>
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          {loading ? (
            <CircularProgress size={25} sx={{ mx: 0.5 }} />
          ) : (
            <IconButton
              onClick={handleSubmit}
              size="small"
              disabled={!rowEdit}
              hidden={tableDisabled}
              sx={{
                '&:hover': {
                  '& .MuiSvgIcon-root': {
                    color: palette.primary.main,
                  },
                },
              }}
            >
              <Save />
            </IconButton>
          )}
          {deleteLoading ? (
            <CircularProgress size={25} sx={{ mx: 0.5 }} />
          ) : (
            <IconButton
              size="small"
              color="default"
              sx={{
                '&:hover': {
                  '& .MuiSvgIcon-root': {
                    color: palette.error.main,
                  },
                },
              }}
              hidden={tableDisabled}
              disabled={deleteDisabled}
              onClick={async () => {
                if (!address.IP.length || !address.prefix.length) {
                  softDeleteV6(address)
                } else {
                  setDeleteLoading(true)
                  setDeleteDisabled(true)

                  await deleteInterfaceIPv6(address)

                  setDeleteLoading(false)
                  setDeleteDisabled(false)
                }
              }}
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default InterfaceIPv6Row
