import React, { useContext, useState } from 'react'
import { CardHeader, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Stack } from '@mui/material'
import { Card } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import { Button } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import AddNewToken from './AddNewToken'

function AccountSecurityCreds() {
  const { organization } = useAuth()

  const tokens = organization?.apiTokens

  const [open, setOpen] = useState(false)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  return (
    <>
      <Card sx={{ p: 3, ml: 3, mr: 3 }}>
        <Stack
          direction={'row'}
          sx={{ mb: 2 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">API Tokens</Typography>
          <Button
            variant="contained"
            sx={{ mb: 1, ml: 3, mr: 3 }}
            onClick={() => setOpen(true)}
          >
            + Add New Token
          </Button>
        </Stack>
        <AddNewToken openProps={{ open: open, setOpen: setOpen }} />
        <Box sx={{ mb: 1.5 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Add and manage your API Tokens to provide access to your data found in
            the 21Packets Portal via API.
          </Typography>

          {tokens && tokens.length ? (
            tokens.map((token: any) => (
              <Box
                sx={{
                  borderRadius: '5px',
                  border: '1px solid lightgray',
                  my: 2,
                  p: 2,
                }}
                key={token.name}
              >
                <Typography variant="subtitle1">{token.name}</Typography>
                <Stack
                  direction={'row'}
                  sx={{ mt: 2 }}
                  alignItems="center"
                  spacing={2}
                  width="30%"
                  justifyContent={'space-between'}
                >
                  <Typography variant="body1">{token.token}</Typography>
                  <Button
                    startIcon={<ContentCopyIcon />}
                    onClick={() => {
                      setSnackbarProps({
                        open: true,
                        message: `copied token ${token.name}`,
                        severity: 'success',
                      })
                      navigator.clipboard.writeText(token.token)
                    }}
                  >
                    Copy
                  </Button>
                </Stack>
              </Box>
            ))
          ) : (
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 4 }}>
              There are no API Tokens currently added for your organization. If you
              would like to add a token, click the button in the right hand corner of
              this section called “+ Add New Token”.
            </Typography>
          )}
        </Box>
      </Card>
    </>
  )
}

export default AccountSecurityCreds
