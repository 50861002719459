// ////////////////////////////////////// START OF COMPONENT //////////////////////////////////////////////////////////////////////////////////
import React, { useContext, useState } from 'react'
import { EndletContext, InterfacesContext } from '../EndletDetails'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UpdateInterfaceForm from './UpdateInterfaceForm'
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { get } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { useTheme } from '@mui/material/styles'

function InterfacesTabView() {
  const interfaces = useContext(InterfacesContext)!
  const theme = useTheme()
  const [expanded, setExpanded] = useState<Array<boolean>>([false, false, false])
  const { endletId, refetchInterfaces } = useContext(EndletContext)!

  const [syncLoader, setSyncLoader] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const handleSyncInterfaces = (id: string) => {
    setSyncLoader(true)

    get(`/interfaces/synchronize/${id}`)
      .then((res: any) => {
        setSyncLoader(false)
        setSnackbarProps({ open: true, message: res.message, severity: 'success' })
      })
      .catch((e) => {
        console.log(e)

        setSnackbarProps({ open: true, message: e.message, severity: 'error' })
      })
  }
  const handleChange = (index: any) => {
    const newArr = [...expanded]
    newArr[index] = !expanded[index]
    setExpanded(newArr)
  }
console.log(interfaces)
  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ my: 2 }}
      >
        <Typography variant="subtitle1" sx={{ py: 1 }}>
          INTERFACES
        </Typography>
        <LoadingButton
          loading={syncLoader}
          variant="outlined"
          onClick={() => handleSyncInterfaces(endletId)}
        >
          Sync Interfaces
        </LoadingButton>
      </Stack>
      {interfaces ? (
        interfaces.map((int, index) => (
          <Accordion
            disableGutters={true}
            expanded={expanded[index]}
            onChange={() => handleChange(index)}
            sx={{
              borderTop: expanded[index]
                ? `3px solid ${theme.palette.primary.main}`
                : '',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {int.name}
            </AccordionSummary>
            <AccordionDetails>
              <UpdateInterfaceForm interfaceDetails={int} />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography sx={{ color: 'GrayText' }}>
          Interfaces will be displayed after Endlet is fully configured!
        </Typography>
      )}
    </Box>
  )
}

export default InterfacesTabView
