import { Divider, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { RHFTextField } from 'src/components/hook-form'

interface props {
  endlet: any
}

function DetailEpTab({ endlet }: props) {
  const IPDetailsPart1 = [
    {
      label: 'City',

      value: endlet.ipRegionLookup?.city,
    },
    {
      label: 'Region',

      value: endlet.ipRegionLookup?.region_name,
    },
    {
      label: 'ZIP',

      value: endlet.ipRegionLookup?.zip,
    },
  ]
  const IPDetailsPart2 = [
    {
      label: 'Country',

      description: '',
      value: endlet.ipRegionLookup?.country_name,
    },
    {
      label: 'Continent',

      description: '',
      value: endlet.ipRegionLookup?.continent_name,
    },
    {
      label: 'Latitude',

      description: '',
      value: endlet.ipRegionLookup?.latitude,
    },
    {
      label: 'Longitude',

      description: '',
      value: endlet.ipRegionLookup?.longitude,
    },
    {
      label: 'Internet Service Provider',

      description: 'Name of the Internet Service Provider for the Endlet',
      value: endlet.ipRegionLookup?.connection?.isp,
    },
    {
      label: ' ISP AS Number',

      description: 'AS Number of the Internet Service Provider',
      value: endlet.ipRegionLookup?.connection?.asn,
    },
  ]
  return (
    <div>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ mb: 1 }} mt={2}>
            Endlet ID
          </Typography>
          <TextField
            disabled
            value={endlet.endletUniqueId}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider sx={{ width: '100%', borderColor: 'darkgray' }} />
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        ENDLET IP INFORMATION
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {IPDetailsPart1.map((field) => (
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {field.label}
            </Typography>
            <TextField disabled value={field.value} variant="outlined" fullWidth />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {IPDetailsPart2.map((field) => (
          <Grid item xs={6}>
            <Typography variant="subtitle1">{field.label}</Typography>
            <FormHelperText sx={{ pb: 1 }}>{field.description}</FormHelperText>
            <TextField disabled value={field.value} variant="outlined" fullWidth />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default DetailEpTab
