// @mui
import { List, Box } from '@mui/material'
//
import { NavSectionProps } from '../type'
import { ListSubheaderStyle } from './style'
import NavList from './NavList'
import useAuth from 'src/hooks/useAuth'
import React, { useEffect, useState } from 'react'

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse,
  ...other
}: NavSectionProps) {
  const { user } = useAuth()

  const [navItemsConditional, setNavItemsConditional] = useState(navConfig)

  const Packets_Employee = ['Packets_Engineer', 'Packets_Finance', 'Packets_Support']
  const Reseller_Employee = [
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance',
  ]
  const Customer_Employee = [
    'Customer_Engineer',
    'Customer_Support',
    'Customer_Finance',
  ]

  useEffect(() => {
    setNavItemsConditional(() =>
      user?.role === 'Packets_Super_User' ||
      Packets_Employee.includes(user?.role) ||
      user?.role === 'Reseller_Admin' ||
      Reseller_Employee.includes(user?.role) ||
      Customer_Employee.includes(user?.role)
        ? Packets_Employee.includes(user?.role) ||
          Reseller_Employee.includes(user?.role) ||
          Customer_Employee.includes(user?.role)
          ? [
              ...navConfig
                .map((conf) =>
                  conf.subheader === 'General'
                    ? {
                        ...conf,
                        items: [
                          ...conf.items.filter((a) => a.title !== 'Message center'),
                        ],
                      }
                    : { ...conf }
                )
                .filter(
                  (config) =>
                    config.subheader !== 'Super user' &&
                    config.subheader !== 'Company Administration'
                ),
            ]
          : // For Reseller_Admin
          user?.role === 'Reseller_Admin'
          ? [
              ...navConfig
                .map((conf) =>
                  conf.subheader === 'General'
                    ? {
                        ...conf,
                        items: [
                          ...conf.items.filter((a) => a.title !== 'Message center'),
                        ],
                      }
                    : conf.subheader === 'Super user'
                    ? {
                        subheader: 'Administration',
                        items: [
                          ...conf.items.filter(
                            (a) => a.title !== 'Reseller Management'
                          ),
                        ],
                      }
                    : { ...conf }
                )
                .filter((config) => config.subheader !== 'Company Administration'),
            ]
          : //For Super_User
            [
              ...navConfig
                .map((conf) =>
                  conf.subheader === 'General'
                    ? {
                        ...conf,
                        items: [
                          ...conf.items.filter((a) => a.title !== 'Message center'),
                        ],
                      }
                    : conf.subheader === 'Super user'
                    ? {
                        subheader: 'Super User Administration',
                        items: [
                          ...conf.items.filter(
                            (a) => a.title !== 'Company Settings'
                          ),
                        ],
                      }
                    : { ...conf }
                )
                .filter((config) => config.subheader !== 'Company Administration'),
            ]
        : //For Customer_Admin
          [...navConfig.filter((conf) => conf.subheader !== 'Super user')]
    )

    // setNavItemsConditional(() =>
    //   user?.role === 'Packets_Super_User' || user?.role === 'Reseller_Admin'
    //     ? [
    //         ...navConfig.map((config) =>
    //           config.subheader === 'General'
    //             ? {
    //                 ...config,
    //                 items: [
    //                   ...config.items.filter(
    //                     (cfg) => cfg.title !== 'Message center'
    //                   ),
    //                 ],
    //               }
    //             : { ...config }
    //         ),
    //       ]
    //     : []
    // )
  }, [navConfig, user])

  return (
    <Box {...other}>
      {navItemsConditional.map((group) => (
        <React.Fragment key={group.subheader}>
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
                bgcolor: 'transparent',
                color: 'text.disabled',
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>

            {group.items.map((list) => (
              <NavList
                key={list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        </React.Fragment>
      ))}
    </Box>
  )
}
