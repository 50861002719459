import React, { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'

import KeyIcon from '@mui/icons-material/Key'
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing'
import { ButtonGroup } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import { LoadingButton } from '@mui/lab'
import VerifyDuoAuthForm from './VerifyDuoAuthForm'

function DuoAuthSelector() {
  const [loading, setLoading] = useState(false)

  const {
    verifyDuoUser,
    duoVerified,
    mfaStatus: { id, enabled },
  } = useAuth()
  const handleClick = async (event: any, key: any) => {
    console.log(key)
    setLoading(true)
    const verifyRes = await verifyDuoUser(key, id)
    setLoading(false)
    console.log(verifyRes)
  }

  const options = [
    <LoadingButton
      loading={!duoVerified && loading}
      key={'push'}
      startIcon={<PhonelinkRingIcon />}
      sx={{ color: 'gray', py: 2 }}
      onClick={(e) => handleClick(e, 'push')}
      variant="outlined"
      disabled={duoVerified}
    >
      {duoVerified ? 'Verified, Logging you in' : 'Duo Push'}
    </LoadingButton>,
    <Button
      key={'passcode'}
      startIcon={<KeyIcon />}
      sx={{ color: 'gray', py: 2 }}
      onClick={() => setPasscodeSelected(true)}
      disabled={duoVerified || loading}
    >
      {' '}
      Duo Mobile Passcode
    </Button>,
  ]

  const [passcodeSelected, setPasscodeSelected] = useState(false)

  return (
    <>
      {passcodeSelected ? (
        <Stack alignItems={'center'} width={'100%'}>
          <VerifyDuoAuthForm showAuthSelector={setPasscodeSelected} />
        </Stack>
      ) : (
        <Box sx={{ width: '70%', textAlign: 'center' }}>
          <Typography variant="h6"> Confirm your identity </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {' '}
            Select option for authentication{' '}
          </Typography>

          <ButtonGroup
            orientation="vertical"
            sx={{ width: '60%' }}
            aria-label="vertical outlined button group"
          >
            {options}
          </ButtonGroup>
        </Box>
      )}
    </>
  )
}

export default DuoAuthSelector
