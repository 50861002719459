import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  IconButton,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { get } from 'src/utils/httpMethods'
import { useNavigate, useParams } from 'react-router'
import ServiceCytoscape from './ServiceCytoscape/ServiceCytoscape'
import Page from 'src/components/Page'

const STATUS_OPTIONS = [
  'Service Configured',
  'Endlets Added',
  'Service Deployed',
  'Up',
]

export const ServiceContext = createContext<any>(null)

function ServiceDetails() {
  const navigate = useNavigate()
  const [contentLoading, setContentLoading] = useState(false)
  const [service, setService] = useState<any>({})
  const { id } = useParams()

  const getService = useCallback(async () => {
    setContentLoading(true)
    console.log('in side')
    get(`${process.env.REACT_APP_HOST_API_URL}/services/${id}`, {})
      .then((response: any) => {
        console.warn('in then')
        setService(response)
        setContentLoading(false)
      })
      .catch((e: any) => {
        setContentLoading(false)
      })
  }, [])

  useEffect(() => {
    getService()
  }, [])

  const useStyles: any = makeStyles(() => ({
    root: {
      '& .MuiStepIcon-root.Mui-active': { color: 'orange' },
      '& .MuiStepIcon-root.Mui-completed': { color: 'green' },
      '& .MuiStepIcon-root.Mui-error': { color: 'red' },
    },
  }))

  const c = useStyles()
  console.log(
    'service Id====>',
    service?.currentProgress && service.currentProgress[0].issue.message
  )
  return (
    <ServiceContext.Provider value={{ service, contentLoading }}>
      <Page title="Service View">
        <Stack>
          <Stack direction="row" sx={{ m: 2, justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'inline-isErrorInOsStep',
                alignItems: 'center',
                marginTop: 'inherit',
              }}
            >
              <Typography
                variant="h6"
                component={'span'}
                sx={{
                  m: 2,

                  color: 'text.secondary',
                }}
                // hidden={isEdit}
              >
                {service.name}
              </Typography>
            </Box>
            <Typography
              component={'span'}
              sx={{
                m: 2,
                cursor: 'pointer',
                color: 'text.secondary',
              }}
              onClick={() => {
                navigate('/endlets/services')
              }}
            >
              <IconButton>
                <ArrowBackIcon />
              </IconButton>
              View all Services
            </Typography>
          </Stack>
          <Card sx={{ m: 3, p: 2 }}>
            <div
            //  className={endlet.status === 'Up' ? 'd-flex align-items-center' : ''}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  mb: 2,
                }}
              >
                <Typography variant="h6" mt={1} sx={{ m: 0 }}>
                  Status
                </Typography>
              </Stack>

              {contentLoading ? (
                <Box sx={{ ml: 2, mr: 2 }}>
                  <Skeleton animation="wave" height={150} />
                </Box>
              ) : (
                <>
                  <Stepper
                    activeStep={STATUS_OPTIONS.indexOf(service.deploymentStatus) + 1}
                    alternativeLabel
                    sx={{ pb: 3 }}
                    className={c.root}
                  >
                    {STATUS_OPTIONS.map((label, index) => {
                      const labelProps: any = {}

                      return (
                        <Step key={label}>
                          <Tooltip
                            title={
                              service?.currentProgress &&
                              service.currentProgress[index]
                                ? service.currentProgress[index]?.issue?.message
                                : ''
                            }
                          >
                            <StepLabel
                              {...labelProps}
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              {label}
                            </StepLabel>
                          </Tooltip>
                        </Step>
                      )
                    })}
                  </Stepper>
                </>
              )}
            </div>
          </Card>
          <Card sx={{ m: 3 }}>
            <Stack spacing={2}>
              <Typography sx={{ m: 3 }} variant="h6">
                {' '}
                Endlet-Service Graph{' '}
              </Typography>
              <ServiceCytoscape />
            </Stack>
          </Card>
        </Stack>
      </Page>
    </ServiceContext.Provider>
  )
}

export default ServiceDetails
