import React, { useContext, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFTextField } from 'src/components/hook-form'
import { Box, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Button } from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import PublicIcon from '@mui/icons-material/Public'
import useAuth from 'src/hooks/useAuth'
import { post, put } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import Scrollbar from 'src/components/Scrollbar'

import { LoadingButton } from '@mui/lab'
import NewSSHModal from '../Services(Endlets)/NewSSHModal'

type FormValuesProps = {
  ssh: string
  serviceType: string

  afterSubmit?: string
}

const TechnicalInfoSchema = Yup.object().shape({
  ssh: Yup.string().required('SSH key is required!'),
  serviceType: Yup.string().required('Select atleast one service!'),
})

interface Props {
  technicalFormProps: {
    handleClose: () => void
    setFormStep: React.Dispatch<React.SetStateAction<number>>
  }
  formDetails: {
    setEndletFields: React.Dispatch<React.SetStateAction<any>>
    currentRow: any
  }
}

function SavedEpTechnicalForm({
  technicalFormProps: { handleClose, setFormStep },
  formDetails: { setEndletFields, currentRow },
}: Props) {
  console.log('fields in saved', currentRow)

  const defaultValues = {
    ssh: currentRow.sshKey ? currentRow.sshKey.sshKeyName : '',
    serviceType: currentRow.serviceType ? currentRow.serviceType[0] : '',
  }
  const { organization, UseNewUserData } = useAuth()
  const { refetchUserData } = UseNewUserData()

  const [openNewSSH, setOpenNewSSH] = useState(false)
  const [newKeyAdded, setNewKeyAdded] = useState(false)
  const [loading, setLoading] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const [submitMethod, setSubmitMethod] = useState<'save' | 'create'>('create')

  let keys = organization?.sshKeys

  const [sshKeys, setSshKeys] = useState(
    keys
      ? keys.length
        ? keys.map((key: any) => ({
            name: key.sshKeyName,
            username: key.username,
            checked:
              currentRow?.sshKey?.sshKeyName === key.sshKeyName ? true : false,
          }))
        : []
      : []
  )

  const setNewKeys = async () => {
    await refetchUserData()

    let newKeys = organization?.sshKeys
    console.log('UPDATED', newKeys)

    setSshKeys(
      newKeys
        ? newKeys.length
          ? newKeys.map((key: any, k: number) =>
              k === newKeys.length - 1
                ? {
                    name: key.sshKeyName,
                    username: key.username,
                    checked: true,
                  }
                : {
                    name: key.sshKeyName,
                    username: key.username,
                    checked: false,
                  }
            )
          : []
        : []
    )

    setNewKeyAdded(false)
  }

  useEffect(() => {
    if (newKeyAdded === false) return
    setNewKeys()
  }, [newKeyAdded])

  console.log(organization?.sshKeys)

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(TechnicalInfoSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods

  useEffect(() => {
    let selectedKey =
      sshKeys && sshKeys.filter((key: any) => key.checked === true)[0]
    console.log(selectedKey)

    setValue('ssh', selectedKey?.name)
  }, [sshKeys])

  const { ssh, serviceType } = watch()

  console.log(errors)

  const createEndlet = (data: FormValuesProps, postData: any) => {
    // selected key
    let selectedKey =
      sshKeys && sshKeys.filter((key: any) => key.checked === true)[0]
    console.log(selectedKey)

    setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${currentRow.id}`, {
      endlet: {
        name: postData.endlet_name,
        tags: postData.tags,
        location: {
          name: postData.location_name,
          country: postData.country,
          address1: postData.address1,
          address2: postData.address2,
          city: postData.city,
          state: postData.state,
          zip: postData.zip,
        },
        cloudProvider: postData.cloudProvider,
        region: postData.region,
        ipAddress: postData.ip,
        sshKey: {
          sshKeyName: selectedKey.name,
          username: selectedKey.username,
        },
        serviceType: [data.serviceType],
        systemGeneratedGeotag: postData.system_generated_geotag,
        status: 'Pending',
      },
    })
      .then(() => {
        handleClose()
        setSnackbarProps({
          open: true,
          message: 'Endlet created successfully!',
          severity: 'success',
        })
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          message: 'Error creating Endlet!',
          severity: 'error',
        })
      })
  }

  const saveEndlet = (data: FormValuesProps, postData: any) => {
    let selectedKey =
      sshKeys && sshKeys.filter((key: any) => key.checked === true)[0]
    console.log(selectedKey)

    setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${currentRow.id}`, {
      endlet: {
        name: postData.endlet_name,
        tags: postData.tags,
        location: {
          name: postData.location_name,
          country: postData.country,
          address1: postData.address1,
          address2: postData.address2,
          city: postData.city,
          state: postData.state,
          zip: postData.zip,
        },
        ipAddress: postData.ip,
        sshKey: {
          sshKeyName: selectedKey.name,
          username: selectedKey.username,
        },
        serviceType: [data.serviceType],
        systemGeneratedGeotag: postData.system_generated_geotag,
        status: 'Saved',
      },
    })
      .then(() => {
        handleClose()
        setSnackbarProps({
          open: true,
          message: 'Endlet created successfully!',
          severity: 'success',
        })
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          message: 'Error creating Endlet!',
          severity: 'error',
        })
      })
  }

  const onSubmit = async (data: FormValuesProps) => {
    console.log(data)
    let postData: any
    setEndletFields((prev: any) => {
      postData = { ...prev, ...data }
      return { ...prev, ...data }
    })
    console.log(postData)
    if (submitMethod === 'save') {
      saveEndlet(data, postData)
    } else {
      createEndlet(data, postData)
    }
  }
  const [activeServices, setActiveServices] = useState([''])

  useEffect(() => {
    setActiveServices([serviceType])
  }, [serviceType])

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <>
          {/* <Typography variant="subtitle1" sx={{ py: 2 }}>
            Endlet Technical Configuration{' '}
          </Typography> */}

          <Typography variant="subtitle1" sx={{ py: 2 }}>
            Authentication
          </Typography>
          <Stack>
            <Typography variant="body2" sx={{ pb: 2 }}>
              Create a new SSH Key
            </Typography>
            <Button
              sx={{ width: 'fit-content', mb: 2 }}
              variant="outlined"
              onClick={() => setOpenNewSSH(true)}
            >
              + New SSH Key
            </Button>

            <Typography variant="body2" sx={{ pb: 2 }}>
              Or Use an Existing SSH Key
            </Typography>
            <Scrollbar direction="ltr">
              <Stack spacing={1} direction="row" sx={{ pb: 2, maxWidth: '100%' }}>
                {sshKeys.length
                  ? sshKeys.map((key: any, i: number) => (
                      <React.Fragment key={key.name}>
                        <Button
                          sx={{
                            textTransform: 'none',
                            wordWrap: 'break-word',
                            minWidth: 120,

                            color: key.checked ? 'primary' : 'darkgray',
                          }}
                          onClick={() => {
                            setValue('ssh', key.sshKeyName)

                            setSshKeys((prev: any) => [
                              ...prev.map((innerKey: any, j: number) =>
                                i === j
                                  ? {
                                      ...innerKey,
                                      checked: key.checked === true ? false : true,
                                    }
                                  : { ...innerKey, checked: false }
                              ),
                            ])
                          }}
                          variant="outlined"
                          startIcon={
                            key.checked ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )
                          }
                        >
                          <Typography
                            variant="inherit"
                            sx={{
                              maxWidth: '100%',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {key.name}
                          </Typography>
                        </Button>
                      </React.Fragment>
                    ))
                  : 'No SSH keys added, add a new one'}
              </Stack>
            </Scrollbar>

            {errors.ssh && (
              <Typography variant="caption" sx={{ mt: 1 }} color={'error'}>
                {errors.ssh?.message}
              </Typography>
            )}
          </Stack>
          <Divider sx={{ borderColor: 'black', px: 2 }} />
          <Typography variant="subtitle1" sx={{ pb: 1, pt: 3 }}>
            Services
          </Typography>
          <Stack spacing={1}>
            <Button
              variant="outlined"
              onClick={() => {
                setValue('serviceType', 'vWAN')
                setActiveServices((prev) =>
                  prev.includes('vWAN')
                    ? [...prev.filter((elem) => elem !== 'vWAN')]
                    : [...prev, 'vWAN']
                )
              }}
              sx={{
                minWidth: 120,
                maxWidth: 150,
                textTransform: 'none',
                color: activeServices.includes('vWAN') ? 'gray' : 'darkgray',
                bgcolor: activeServices.includes('vWAN')
                  ? 'primary.lighter'
                  : 'white',
                '&:hover': {
                  bgcolor: 'primary.lighter',
                },
              }}
            >
              <Stack alignItems={'center'}>
                <PublicIcon fontSize="large" />
                vWan
              </Stack>
            </Button>

            {errors.serviceType && (
              <Typography variant="caption" color={'error'}>
                {errors.serviceType?.message}
              </Typography>
            )}
          </Stack>
        </>
        <Stack direction={'row'} spacing={2} sx={{ float: 'right', my: 2 }}>
          <Button
            onClick={() => {
              setFormStep(1)
            }}
            variant="text"
            type="button"
            sx={{ color: 'text.secondary' }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              setSubmitMethod('save')
            }}
            variant="text"
            type="submit"
            sx={{ color: 'text.secondary' }}
          >
            Save Endlet
          </Button>
          <LoadingButton loading={loading} variant="contained" type="submit">
            Create Endlet
          </LoadingButton>
        </Stack>
      </FormProvider>
      <NewSSHModal
        openProps={{
          open: openNewSSH,
          setOpen: setOpenNewSSH,
        }}
        setNewKeys={setNewKeys}
        newKeyFlag={setNewKeyAdded}
      />
    </>
  )
}

export default SavedEpTechnicalForm
