import React, { useContext } from 'react'
import { Box } from '@mui/material'
import logo21dark from '../assets/21-logo-dark.png'
import { LogosContext } from 'src/contexts/LogoContext'
function LogoBlackText() {
  const { logoUrl } = useContext(LogosContext)!
  console.log(logoUrl)
  return (
    <Box
      component="img"
      src={logoUrl.darkLogo ? logoUrl.darkLogo : logo21dark}
      sx={{ height: 40, cursor: 'pointer' }}
    />
  )
}

export default LogoBlackText
