import React, { useContext, useEffect, useState } from 'react'
import { EndletContext } from '../EndletDetails'

// contexts
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

// interceptors
import { get, post, put } from 'src/utils/httpMethods'

// RHF
import * as Yup from 'yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ConfigureContext } from '../EpDtServices'
import { FormProvider } from 'src/components/hook-form'

// @mui

import {
  Alert,
  AlertColor,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { MenuItem } from '@mui/material'
import { SelectChangeEvent } from '@mui/material'
import IPBlocksTable from './IPBlocksTable'
import FireWallTable from './FireWallTable'

// form

interface FormValue {
  firewallType: string
  protocol: string
  port: string
  source: string
  destination: string
}

const formSchema = Yup.object().shape({
  firewallType: Yup.string().required('Type is required!'),
  protocol: Yup.string().required('Protocol is required!'),
  port: Yup.string().when('firewallType', {
    is: (firewallType: any) => firewallType !== 'ICMP',
    then: Yup.string().required('Port is required!'),
  }),
  source: Yup.string()
    .required('Source is required!')
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'Invalid IP'
    ),
  destination: Yup.string()
    .required('Destination is required!')
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'Invalid IP'
    ),
})

interface Props {
  openProps: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
  isHnS: boolean
}

const MENU_OPTIONS = ['Custom TCP', 'Custom UDP', 'All TCP', 'All UDP', 'ICMP']

function FirewallTabView({ openProps: { open, setOpen }, isHnS }: Props) {
  // const {
  //   isIpAddedProps: { isIPAdded, setIsIPAdded },
  //   ipBlockProps: ipBlocksLeft,
  //   setStateMethodProps: { setDataLoading, setNewEpAdded },
  //   isSuperUser,
  // } = useContext(ConfigureContext)!
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const [loading, setLoading] = useState(false)
  const [syncLoader, setSyncLoader] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)

  /*TABLE DATA handlers and state */
  const { endlet: endpoint } = useContext(EndletContext)!
  const [associatedEndpoint, setassociatedEndpoint] = useState<any>(undefined)
  const id = endpoint.endletId
  const serviceId = endpoint.services[0].id
  const endpointName = endpoint.endletName
  const [tableData, setTableData] = useState<any>([])

  const getFirewalls = (load: boolean) => {
    setTableLoading((prev) => (load ? true : prev))
    get(`${process.env.REACT_APP_HOST_API_URL}/firewalls/endlet/${id}`)
      .then((res) => {
        setTableData(
          res.data.sort((a: any, b: any) => parseInt(a.index) - parseInt(b.index))
        )
        setTableLoading(false)
      })
      .catch((e) => {
        setTableLoading(false)
      })
  }

  useEffect(() => {
    getFirewalls(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const epDesignation =
    associatedEndpoint && associatedEndpoint.find((ep: any) => ep.endletId === id)

  const [designation, setDesignation] = useState(
    epDesignation ? epDesignation.designation : ''
  )

  const handleChange = (e: SelectChangeEvent) => {
    setDesignation(e.target.value as string)
  }

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      firewallType: '',
      protocol: '',
      port: '',
      source: '',
      destination: '',
    },
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods

  const { destination, firewallType, port, protocol, source } = watch()

  const handleClose = () => {
    console.log('^^^^^^^ closing modal ^^^^^^^')
    setOpen(false)
    setLoading(false)
    // setIsIPAdded(true)
    reset()
  }

  const onSubmit = async (data: any) => {
    setLoading(true)

    if (designation !== '') {
      put(`${process.env.REACT_APP_HOST_API_URL}/services/${serviceId}`, {
        associatedEndlets: [
          ...associatedEndpoint.filter((ep: any) => ep.endletId !== id),
          { endletId: id, endletName: endpointName, designation: designation },
        ],
      })
        .then((res) => console.log(res))
        .catch((e) => console.log(e))
    }
    const finalData = {
      type: firewallType,
      protocol: protocol,
      port: port,
      source: source,
      destination: destination,
      endletId: id,
      serviceId: serviceId,
    }
    post(`${process.env.REACT_APP_HOST_API_URL}/firewalls`, finalData)
      .then((response: any) => {
        setLoading(false)
        getFirewalls(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Firewall configured successfully`,
        })
        setLoading(false)
        reset()
      })
      .catch((e) => {
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: e.message,
        })
        setLoading(false)
        console.log(e.message)
      })
  }
  useEffect(() => {
    const splitVal = firewallType.split(' ')
    setValue('protocol', splitVal.length > 1 ? splitVal[1] : splitVal[0])
    if (firewallType === 'ICMP') setValue('port', '')
  }, [firewallType, setValue])

  // For synchronizing firewalls
  const handleSyncFirewall = (id: string) => {
    setSyncLoader(true)
    get(`${process.env.REACT_APP_HOST_API_URL}/firewalls/synchronize/${id}`)
      .then((res: any) => {
        setSyncLoader(false)
        setSnackbarProps({ open: true, message: res.message, severity: 'success' })
      })
      .catch((e) => {
        console.log(e)
        setSyncLoader(false)
        setSnackbarProps({ open: true, message: e.message, severity: 'error' })
      })
  }
  useEffect(() => {}, [])
  return (
    <Stack direction={'row'} alignItems="flex-start" width="100%">
      <Grid container columnGap={2}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={4} md={4} lg={4}>
            <Box sx={{ p: 2, width: '25vw' }}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack spacing={1}>
                  <Typography variant="subtitle2">Firewall Type</Typography>
                  <FormControl>
                    <InputLabel>Select the Firewall Type</InputLabel>
                    <Controller
                      name={`firewallType`}
                      defaultValue={firewallType}
                      render={({ field }) => (
                        <Select label={'Select the Firewall Type'} {...field}>
                          <MenuItem value={''} defaultChecked disabled>
                            <em>Select Type</em>
                          </MenuItem>
                          {MENU_OPTIONS.map((optn) => (
                            <MenuItem value={optn}>{optn}</MenuItem>
                          ))}
                        </Select>
                      )}
                    ></Controller>
                  </FormControl>
                  {errors?.firewallType ? (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small> {errors?.firewallType?.message}</small>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="subtitle2">Protocol</Typography>
                  <Controller
                    defaultValue={protocol}
                    render={({ field }) => (
                      <TextField
                        disabled
                        label="Enter Protocol"
                        {...field}
                        autoComplete="off"
                        fullWidth
                      />
                    )}
                    {...register(`protocol`)}
                  ></Controller>

                  {errors?.protocol ? (
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: '#FF4842',
                        fontWeight: 'normal',
                        float: 'left',
                      }}
                    >
                      <small> {errors?.protocol?.message}</small>
                    </Typography>
                  ) : (
                    ''
                  )}
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="subtitle2">Port</Typography>
                  <Controller
                    defaultValue={port}
                    render={({ field }) => (
                      <TextField
                        disabled={firewallType === 'ICMP'}
                        label="Enter Port number"
                        {...field}
                        autoComplete="off"
                        type="number"
                        fullWidth
                      />
                    )}
                    {...register(`port`)}
                  ></Controller>
                  {errors?.port ? (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small> {errors?.port?.message}</small>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="subtitle2">Source</Typography>
                  <FormControl>
                    <Controller
                      name={`source`}
                      defaultValue={source}
                      render={({ field }) => (
                        <TextField
                          label="Enter the Source IP address"
                          {...field}
                          autoComplete="off"
                          fullWidth
                        />
                      )}
                    ></Controller>
                  </FormControl>
                  {errors?.source ? (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small> {errors?.source?.message}</small>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="subtitle2">Destination</Typography>

                  <Controller
                    defaultValue={destination}
                    render={({ field }) => (
                      <TextField
                        label="Enter the IP address of the destination"
                        {...field}
                        autoComplete="off"
                        fullWidth
                      />
                    )}
                    {...register(`destination`)}
                  ></Controller>

                  {errors?.destination ? (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small> {errors?.destination?.message}</small>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Stack>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  float: 'right',
                  px: 0,
                  my: 2,
                }}
                spacing={1}
              >
                <Button
                  onClick={handleClose}
                  variant="text"
                  type="button"
                  sx={{ color: 'text.secondary' }}
                >
                  Cancel
                </Button>

                <LoadingButton variant="contained" type="submit" loading={loading}>
                  +Add Firewall Rule
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
        </FormProvider>

        <Grid item xs={8} md={8} lg={8}>
          <Stack>
            <Stack
              direction="column"
              // justifyContent={'space-between'}
              alignItems={'flex-end'}
            >
              <LoadingButton
                disabled={!(tableData && tableData.length)}
                sx={{ mt: 1, mb: 1 }}
                loading={syncLoader}
                variant="outlined"
                onClick={() => handleSyncFirewall(id)}
              >
                Sync Firewall
              </LoadingButton>
            </Stack>
            <FireWallTable
              tableDataProps={{ tableData, setTableData }}
              refetch={getFirewalls}
              // loading={tableLoading}
              loadingProps={{ loading: tableLoading, setLoading: setTableLoading }}
              endletId={id}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default FirewallTabView
