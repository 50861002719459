import React, { useEffect, useContext, useState } from 'react'
import {
  Box,
  Button,
  Step,
  Stepper,
  StepLabel,
  Dialog,
  DialogTitle,
  StepContent,
  IconButton,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { put } from 'src/utils/httpMethods'
import { OpenModalProps } from 'src/@types/modalProps'
import { LoadingButton } from '@mui/lab'
import StatusIssue from './StatusIssue'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { useNavigate } from 'react-router'

interface Props {
  openProps: OpenModalProps
  fetchEndletProps: {
    fetchEndlet: boolean
    setFetchEndlet: React.Dispatch<React.SetStateAction<any>>
  }
  id: string
  currentState: any
  currentIndex: number
}
const STATUS_OPTIONS = [
  'Endlet Configured',
  'SSH Key Validated',
  'O/S Validated',
  'Endlet Deployed',
]
function StatusStepper({
  openProps,
  fetchEndletProps,
  id,
  currentState,
  currentIndex,
}: Props) {
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const { open, setOpen } = openProps
  const { fetchEndlet, setFetchEndlet } = fetchEndletProps
  const [openIssue, setOpenIssue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [issueStep, setIssueStep] = useState('')
  const [modalActiveStep, setModalActiveStep] = React.useState(
    currentState?.issue?.isResolved ? currentIndex + 1 : currentIndex
  )
  const navigate = useNavigate()
  const handleNext = (step: string) => {
    setLoading(true)
    const data = {
      endlet: {
        status: step === 'Endlet Deployed' ? 'Up' : 'Pending',
      },
      deploymentStatusProgress: {
        deploymentStatus: step,
        successMessage:
          step === 'O/S Validated'
            ? 'Success: Endlet Operating System Supported {Endlet OS}'
            : '',
        issue: {
          message: '',
          isResolved: true,
        },
      },
    }
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, data)
      .then((response: any) => {
        console.log('update', response)
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Status has been updated!`,
        })
        setFetchEndlet(!fetchEndlet)

        if (step === 'Endlet Deployed') navigate(-1)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
        setOpen(false)
      })
  }
  useEffect(() => {
    setModalActiveStep(
      currentState?.issue?.isResolved ? currentIndex + 1 : currentIndex
    )
  }, [currentIndex, currentState])

  const handleClose = () => {
    setOpen(false)
  }

  const handleIssue = (step: string) => {
    setIssueStep(step)
    setOpenIssue(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Update Status</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: 3,
          }}
        >
          <Stepper
            activeStep={modalActiveStep}
            orientation="vertical"
            sx={{
              width: 'fit-content',
              marginX: '100px',
            }}
          >
            {STATUS_OPTIONS.map((step, index) => (
              <Step key={step}>
                <StepLabel>{step}</StepLabel>
                <StepContent>
                  <Box sx={{ mb: 5 }}>
                    <div>
                      <LoadingButton
                        variant="contained"
                        onClick={() => handleNext(step)}
                        sx={{ mt: 1, mr: 1 }}
                        loading={loading}
                      >
                        Complete
                      </LoadingButton>

                      <Button
                        disabled={index === 0}
                        onClick={() => handleIssue(step)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Issue
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <StatusIssue
            openProps={{ openIssue, setOpenIssue, setOpen }}
            fetchEndletProps={fetchEndletProps}
            issueStep={issueStep}
          />
        </Box>
        {/* <DialogActions>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={handleEdit} loading={loading}>
            Update
          </LoadingButton>
        </DialogActions> */}
      </Dialog>
    </div>
  )
}

export default StatusStepper
