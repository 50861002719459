import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import IpSubnetCalculator from 'ip-subnet-calculator'

// RHF

import { Box, Button, Typography, useTheme } from '@mui/material'

// components
import { EndletInterfaces } from '../ConfigureEndletModal'

import { put } from 'src/utils/httpMethods'
import { EndletContext, InterfacesContext } from '../EndletDetails'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import InterfaceIPTable from './InterfaceIPTable'
import Scrollbar from 'src/components/Scrollbar'
import InterfaceIPv6Table from './InterfaceIPv6Table'

interface Props {
  // details: FormSchema
  // isLast: boolean
  interfaceDetails: EndletInterfaces
}

export const ConfigureInterfaceContext = createContext<any>(null)

function UpdateInterfaceForm({ interfaceDetails }: Props) {
  // global snackbar context
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  //////////////// endlet details for API ////////////////////////
  const {
    endlet: { ipAddress, sshKey },
    endletId,
    refetchInterfaces,
  } = useContext(EndletContext)!

  const interfaces = useContext(InterfacesContext)!

  // set initial v4 and v6 objects
  const [v4rows, setV4rows] = useState(
    interfaceDetails.addresses
      .filter((addr) => addr.IPVersion === 'IPv4')
      .map((addr, i) => ({
        id: i,
        ...addr,
      }))
  )

  const [v6rows, setV6rows] = useState(
    interfaceDetails.addresses
      .filter((addr) => addr.IPVersion === 'IPv6')
      .map((addr, i) => ({
        id: i,
        ...addr,
      }))
  )

  console.log(v4rows)

  // handler for delete method

  useEffect(() => {
    setV4rows(
      interfaceDetails.addresses
        .filter((addr) => addr.IPVersion === 'IPv4')
        .map((addr, i) => ({
          id: i,
          ...addr,
        }))
    )

    setV6rows(
      interfaceDetails.addresses
        .filter((addr) => addr.IPVersion === 'IPv6')
        .map((addr, i) => ({
          id: i,
          ...addr,
        }))
    )
  }, [interfaceDetails.addresses, interfaces])

  const { palette } = useTheme()
  // set edit mode for API
  const [userMethod, setUserMethod] = useState<'added' | 'updated' | 'deleted'>(
    'updated'
  )

  const interfaceId = interfaceDetails.id

  // IP subnet calculator
  const calculatePrefix = (ipConfig: any) => {
    const { IP: ip, subnet } = ipConfig
    const prefix = IpSubnetCalculator.calculateCIDRPrefix(ip, subnet)
    console.log(prefix)

    return {
      ...ipConfig,
      IPBlock: `${prefix.ipLowStr}/${prefix.prefixSize}`,
      prefix: prefix.prefixSize.toString(),
    }
  }

  // API methods
  ////////////////////////////////////
  //   Methods for v4 addresses    //
  ///////////////////////////////////

  const addInterfaceIP = useCallback(
    async (newR: any) => {
      const { id, ...newIP } = newR

      const withPrefix = calculatePrefix(newIP)

      const newArray = [
        ...v4rows
          .filter((addr: any) => addr.IP !== '')
          .map((addr: any) => {
            const { id, ...rest } = addr
            return { ...rest }
          }),
        ...v6rows.map((addr: any) => {
          const { id, ...rest } = addr
          return { ...rest }
        }),
        { ...withPrefix },
      ]

      const addObject = {
        method: userMethod,
        data: {
          ...withPrefix,
        },
        Interface: { addresses: [...newArray] },
        endletIp: ipAddress,
        sshKey: sshKey,
      }
      console.log('API add object', {
        ...addObject,
      })
      try {
        const res: any = await put(
          `${process.env.REACT_APP_HOST_API_URL}/interfaces/${interfaceId}`,
          {
            ...addObject,
          }
        )
        console.log(res)
        setSnackbarProps({ severity: 'success', message: res.message, open: true })
        refetchInterfaces(endletId)
        setUserMethod('updated')
      } catch (e: any) {
        setSnackbarProps({ severity: 'error', message: e.message, open: true })
        softDeleteV4(newR)
      }
    },
    [
      endletId,
      interfaceId,
      ipAddress,
      refetchInterfaces,
      setSnackbarProps,
      sshKey,
      userMethod,
      v4rows,
      v6rows,
    ]
  )

  const updateInterfaceIP = useCallback(
    async (newR: any, oldRow: any) => {
      const { id, ...newIP } = newR
      const { id: oldID, ...oldIP } = oldRow
      const withPrefix = calculatePrefix(newIP)
      console.log(withPrefix)
      const newArray = [
        ...v4rows.map((addr: any) => {
          const { id, ...rest } = addr
          return addr.IP === oldRow?.IP ? { ...withPrefix } : { ...rest }
        }),
        ...v6rows.map((addr: any) => {
          const { id, ...rest } = addr
          return addr.IP === newR?.IP ? { ...newIP } : { ...rest }
        }),
      ]

      const updateObject = {
        method: userMethod,
        data: {
          oldAddress: {
            ...oldIP,
          },
          newAddress: {
            ...withPrefix,
          },
        },
        Interface: { addresses: [...newArray] },
        endletIp: ipAddress,
        sshKey: sshKey,
      }
      console.log('API object', {
        ...updateObject,
      })
      try {
        const res: any = await put(
          `${process.env.REACT_APP_HOST_API_URL}/interfaces/${interfaceId}`,
          {
            ...updateObject,
          }
        )
        console.log(res)

        refetchInterfaces(endletId)

        setSnackbarProps({
          severity: 'success',
          message: res.message,
          open: true,
        })
      } catch (e) {
        setSnackbarProps({ severity: 'error', message: e.message, open: true })
      }
    },
    [
      v4rows,
      v6rows,
      userMethod,
      ipAddress,
      sshKey,
      interfaceId,
      refetchInterfaces,
      endletId,
      setSnackbarProps,
    ]
  )

  const deleteInterfaceIP = useCallback(
    async (newR: any) => {
      const { id, ...newIP } = newR
      const withPrefix = calculatePrefix(newIP)

      const newArray = [
        ...v4rows.map((addr: any) => {
          const { id, ...rest } = addr
          return { ...rest }
        }),
        ...v6rows.map((addr: any) => {
          const { id, ...rest } = addr
          return { ...rest }
        }),
      ].filter((addr: any) => addr.IP !== newIP.IP)

      const delObject = {
        method: 'deleted',
        data: {
          ...withPrefix,
        },
        Interface: { addresses: [...newArray] },
        endletIp: ipAddress,
        sshKey: sshKey,
      }
      console.log('API delete object', {
        ...delObject,
      })
      try {
        const res: any = await put(
          `${process.env.REACT_APP_HOST_API_URL}/interfaces/${interfaceId}`,
          {
            ...delObject,
          }
        )
        refetchInterfaces(endletId)
        // rowDeleted(
        //   res.data.addresses.filter((addr: any) => addr.IPVersion === 'IPv4'),
        //   'v4'
        // )
        setSnackbarProps({ severity: 'success', message: res.message, open: true })
      } catch (e) {
        setSnackbarProps({ severity: 'error', message: e.message, open: true })
      }
    },
    [
      endletId,
      interfaceId,
      ipAddress,
      refetchInterfaces,
      setSnackbarProps,
      sshKey,
      v4rows,
      v6rows,
    ]
  )

  ////////////////////////////////////
  //   Methods for v6 addresses    //
  ///////////////////////////////////

  const addInterfaceIPv6 = useCallback(
    async (newR: any) => {
      const { id, ...newIP } = newR

      const newArray = [
        ...v4rows
          .filter((addr: any) => addr.IP !== '')
          .map((addr: any) => {
            const { id, ...rest } = addr
            return { ...rest }
          }),
        ...v6rows
          .filter((addr: any) => addr.IP !== '')
          .map((addr: any) => {
            const { id, ...rest } = addr
            return { ...rest }
          }),
        { ...newIP, IPBlock: `${newIP.IP}/${newIP.prefix}`, subnet: null },
      ]

      const addObject = {
        method: userMethod,
        data: {
          ...newIP,
          IPBlock: `${newIP.IP}/${newIP.prefix}`,
          subnet: null,
        },
        Interface: { addresses: [...newArray] },
        endletIp: ipAddress,
        sshKey: sshKey,
      }
      console.log('API add object', {
        ...addObject,
      })
      try {
        const res: any = await put(
          `${process.env.REACT_APP_HOST_API_URL}/interfaces/${interfaceId}`,
          {
            ...addObject,
          }
        )

        refetchInterfaces(endletId)

        setUserMethod('updated')
        console.log(res)
        setSnackbarProps({ severity: 'success', message: res.message, open: true })
      } catch (e: any) {
        setSnackbarProps({ severity: 'error', message: e.message, open: true })
      }
    },
    [
      endletId,
      interfaceId,
      ipAddress,
      refetchInterfaces,
      setSnackbarProps,
      sshKey,
      userMethod,
      v4rows,
      v6rows,
    ]
  )

  const updateInterfaceIPv6 = useCallback(
    async (newR: any, oldRow: any) => {
      const { id, ...newIP } = newR
      const { id: oldID, ...oldIP } = oldRow

      console.log(newIP)
      const newArray = [
        ...v4rows.map((addr: any) => {
          const { id, ...rest } = addr
          return addr.IP === newR?.IP ? { ...newIP } : { ...rest }
        }),
        ...v6rows.map((addr: any) => {
          const { id, ...rest } = addr
          return addr.IP === oldRow?.IP ? { ...newIP } : { ...rest }
        }),
      ]

      const updateObject = {
        method: userMethod,
        data: {
          oldAddress: {
            ...oldIP,
          },
          newAddress: {
            ...newIP,
          },
        },
        Interface: { addresses: [...newArray] },
        endletIp: ipAddress,
        sshKey: sshKey,
      }
      console.log('API object', {
        ...updateObject,
      })
      try {
        const res: any = await put(
          `${process.env.REACT_APP_HOST_API_URL}/interfaces/${interfaceId}`,
          {
            ...updateObject,
          }
        )
        refetchInterfaces(endletId)

        console.log(res)
        setSnackbarProps({ severity: 'success', message: res.message, open: true })
      } catch (e) {
        setSnackbarProps({ severity: 'error', message: e.message, open: true })
      }
    },
    [
      v4rows,
      v6rows,
      userMethod,
      ipAddress,
      sshKey,
      interfaceId,
      refetchInterfaces,
      endletId,
      setSnackbarProps,
    ]
  )

  const deleteInterfaceIPv6 = useCallback(
    async (newR: any) => {
      const { id, ...newIP } = newR

      const newArray = [
        ...v4rows.map((addr: any) => {
          const { id, ...rest } = addr
          return { ...rest }
        }),
        ...v6rows.map((addr: any) => {
          const { id, ...rest } = addr
          return { ...rest }
        }),
      ].filter((addr: any) => addr.IP !== newIP.IP)

      const delObject = {
        method: 'deleted',
        data: {
          ...newIP,
        },
        Interface: { addresses: [...newArray] },
        endletIp: ipAddress,
        sshKey: sshKey,
      }
      console.log('API delete object', {
        ...delObject,
      })
      try {
        const res: any = await put(
          `${process.env.REACT_APP_HOST_API_URL}/interfaces/${interfaceId}`,
          {
            ...delObject,
          }
        )
        refetchInterfaces(endletId)

        setSnackbarProps({ severity: 'success', message: res.message, open: true })
      } catch (e) {
        setSnackbarProps({ severity: 'error', message: e.message, open: true })
      }
    },
    [
      endletId,
      interfaceId,
      ipAddress,
      refetchInterfaces,
      setSnackbarProps,
      sshKey,
      v4rows,
      v6rows,
    ]
  )

  // handlers to add a new row
  const addv4Row = () => {
    setUserMethod('added')
    setV4rows((prev) => [
      ...prev,
      {
        id: prev.length,
        IP: '',
        description: '',
        subnet: '',
        prefix: '',
        IPBlock: '',
        IPVersion: 'IPv4',
      },
    ])
  }

  const addv6Row = () => {
    setUserMethod('added')
    setV6rows((prev) => [
      ...prev,
      {
        id: prev.length,
        IP: '',
        description: '',
        prefix: '',
        IPVersion: 'IPv6',
        IPBlock: '',
        subnet: '',
      },
    ])
  }

  /*
   * soft delete if user deletes before adding fields
   */
  const softDeleteV4 = (addr: any) => {
    console.log('**soft delete**', addr)
    setV4rows((prev) => prev.filter((address) => address.id !== addr.id))
  }

  const softDeleteV6 = (addr: any) => {
    console.log('**soft delete**', addr)
    setV6rows((prev) => prev.filter((address) => address.id !== addr.id))
  }

  const tableDisabled = interfaceDetails.wanIP === true

  const [deleteDisabled, setDeleteDisabled] = useState(false)
  console.log(interfaceDetails.wanIP && interfaceDetails.wanIP)
  return (
    <ConfigureInterfaceContext.Provider
      value={{
        addInterfaceIP,
        updateInterfaceIP,
        deleteInterfaceIP,
        userMethod,
        addInterfaceIPv6,
        updateInterfaceIPv6,
        deleteInterfaceIPv6,
        tableDisabled,
        softDeleteV4,
        softDeleteV6,
        deleteDisabled,
        setDeleteDisabled,
      }}
    >
      {interfaceDetails.wanIP && interfaceDetails.wanIP === true && (
        <Typography variant="body2" color="error" mb={2}>
          {' '}
          IP ADDRESS MODIFICATIONS FOR THIS INTERFACE ARE NOT ALLOWED{' '}
        </Typography>
      )}
      <Box
        sx={{
          maxHeight: 600,
          width: '100%',
          '& .MuiDataGrid-columnHeaderDraggableContainer': {
            color: palette.common.white,
          },
          '& .MuiDataGrid-columnHeadersInner': {
            width: '100%',
            backgroundColor: '#57667E !important',
          },
        }}
      >
        <Scrollbar sx={{ maxHeight: 600 }}>
          <InterfaceIPTable addresses={v4rows} />
        </Scrollbar>
      </Box>
      <Button
        onClick={addv4Row}
        variant="outlined"
        sx={{ my: 1 }}
        hidden={tableDisabled}
        disabled={tableDisabled}
      >
        Add IPv4 Address
      </Button>

      <Box
        sx={{
          maxHeight: 600,
          width: '100%',

          '& .MuiDataGrid-columnHeaderDraggableContainer': {
            color: palette.common.white,
          },
          '& .MuiDataGrid-columnHeadersInner': {
            width: '100%',
            backgroundColor: '#57667E !important',
          },
        }}
      >
        <Scrollbar sx={{ maxHeight: 600 }}>
          <InterfaceIPv6Table addresses={v6rows} />
        </Scrollbar>
      </Box>
      <Button
        onClick={addv6Row}
        variant="outlined"
        hidden={tableDisabled}
        sx={{ my: 1 }}
        disabled={tableDisabled}
      >
        Add IPv6 Address
      </Button>
    </ConfigureInterfaceContext.Provider>
  )
}

export default UpdateInterfaceForm
