import { useContext, useEffect, useState } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import { Checkbox, TableRow, TableCell } from '@mui/material'
// @types
// components
import Label from '../../../components/Label'

import { get } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import RemoveServiceModal from './RemoveServiceModal'

type Props = {
  row: any
  selected: boolean
  service: any
  onSelectRow: VoidFunction
  setSelected: any
  // onDeleteRow: VoidFunction
  setTableData: any
}

function DeleteServiceTableRow({
  row,
  selected,
  service,
  onSelectRow,
  setSelected,
  // onDeleteRow,
  setTableData,
}: Props) {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [openRemoveService, setOpenRemoveService] = useState(false)

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget)
  }

  useEffect(() => {
    getService()
  }, [])
  const getService = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/services/${service.id}`)
      .then((response: any) => {
        console.log(response)
        setTableData(response.associatedEndlets)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align="left">{row.endletName}</TableCell>
      <TableCell
        align="right"
        //  onClick={() => setOpenRemoveService(true)}
      >
        {' '}
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={'error'}
          sx={{
            textTransform: 'none',
            // cursor: 'pointer',
          }}
        >
          Remove
        </Label>
      </TableCell>
      <RemoveServiceModal
        openProps={{
          open: openRemoveService,
          setOpen: setOpenRemoveService,
        }}
        service={service}
        endletId={row.endletId}
        getDetails={getService}
        setSelected={setSelected}
      />
    </TableRow>
  )
}

export default DeleteServiceTableRow
