import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { OpenModalProps } from 'src/@types/modalProps'
import PublicIcon from '@mui/icons-material/Public'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { post, put, get } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { EndletContext } from './EndletDetails'
interface Props {
  openProps: OpenModalProps
}
type FormValuesProps = {
  type: string
  name: string
  subType: string
  tags: string[]
  afterSubmit?: string
}
const vWANSchema = Yup.object().shape({
  type: Yup.string().required('Service Type is required'),
  name: Yup.string().required('vWan name  is required'),
  subType: Yup.string().required('vWan type is required'),
  tags: Yup.array().of(Yup.string()),
})
function NewServiceModal({ openProps: { open, setOpen } }: Props) {
  const { endlet, endletId: id, getEndlets } = useContext(EndletContext)! || {}

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const VWAN_TYPES = ['Point To Point', 'Full Mesh', 'Hub And Spoke']
  const [loading, setLoading] = useState(false)
  const [tagsList, setTagsList] = useState<any>()

  const SERVICE_TYPE = ['vWAN']

  const defaultValues = {
    type: '',
    name: '',
    subType: '',
    tags: [],
  }
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(vWANSchema),
    defaultValues,
  })
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = methods
  const { name, type, tags } = watch()

  const fetchTags = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/tags?tagType=Service`)
      .then((res: any) => {
        setTagsList(res)
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    fetchTags()
  }, [])

  const onSubmit = async (data: any) => {
    setLoading(true)
    console.warn(data.type)
    if (endlet.serviceType.includes('vWAN') && data.type === 'vWAN') {
      setSnackbarProps({
        open: true,
        message: 'vWAN type of service already exist',
        severity: 'error',
      })

      setLoading(false)
    } else {
      const serviceData = {
        service: {
          tags: data.tags,
          type: data.type,
          name: data.name,
          subType: data.subType,
          associatedEndlets: [
            { endletId: endlet.endletId, endletName: endlet.endletName },
          ],
          endletId: endlet.endletId,
        },
      }

      post(`${process.env.REACT_APP_HOST_API_URL}/services`, serviceData)
        .then((response: any) => {
          setSnackbarProps({
            open: true,
            message: 'Service Added Successfully!',
            severity: 'success',
          })
          setLoading(false)
          updateEndlet(data.type, response.data)
          handleClose()
        })
        .catch((e) => {
          setLoading(false)
          setSnackbarProps({
            open: true,
            message: e.message,
            severity: 'error',
          })
          handleClose()
        })
    }
  }
  const updateEndlet = (service_type: any, service_id: any) => {
    const newService = {
      endlet: {
        services: [...endlet.services, { id: service_id, name: name }],
        serviceType: [...endlet.serviceType, service_type],
      },
    }
    put(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/${endlet?.endletId}`,
      newService
    )
      .then((response: any) => {
        console.log(response)
        getEndlets()
      })
      .catch((e: any) => {
        console.log(e)
      })
  }
  const handleClose = () => {
    setOpen(false)
    reset()
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll="body">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ p: 2, width: 500 }}>
            <Typography variant="h6" sx={{ my: 1 }}>
              Add New Service
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 2 }}>
              Select Type
            </Typography>
            <Stack spacing={1} sx={{ mt: 1 }} direction={'row'}>
              {SERVICE_TYPE.map((tp: string, index: number) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => {
                    // setActiveServices((prev) =>
                    //   prev.includes('vWan')
                    //     ? [...prev.filter((elem) => elem !== 'vWan')]
                    //     : [...prev, 'vWan']
                    // )
                    setValue('type', tp)
                  }}
                  sx={{
                    minWidth: 120,
                    maxWidth: 150,
                    textTransform: 'none',

                    // color: activeServices.includes('vWan') ? 'primary' : 'darkgray',
                    color: type === tp ? 'primary' : 'darkgray',
                  }}
                >
                  <Stack alignItems={'center'}>
                    <PublicIcon fontSize="large" />
                    {tp}
                  </Stack>
                </Button>
              ))}
            </Stack>
            {errors.type && (
              <Typography variant="caption" color={'error'}>
                {errors.type?.message}
              </Typography>
            )}
            <Stack spacing={2} sx={{ mt: 1 }}>
              <RHFTextField
                autoComplete="off"
                name="name"
                label="Name"
                sx={{ mt: 2 }}
              ></RHFTextField>
            </Stack>
            <RHFSelect sx={{ mt: 2 }} name="subType" label="Type" placeholder="Type">
              <option value=""></option>
              {VWAN_TYPES.map((tp, i) => {
                return (
                  <option value={tp} key={i}>
                    {' '}
                    {tp}
                  </option>
                )
              })}
            </RHFSelect>

            <Stack mt={2}>
              <Typography variant="caption" sx={{ mt: 0 }} color={'gray'}>
                Create a New Tag Below or Select From an Existing Tag
              </Typography>
            </Stack>
            <Stack>
              <Autocomplete
                sx={{ flexGrow: 1 }}
                multiple
                id="tags-filled"
                noOptionsText={
                  'No tags found for your organization. Type and press enter to add a new tag'
                }
                filterSelectedOptions
                options={
                  tagsList && tagsList.length
                    ? tagsList.map((option: any) => option.name)
                    : []
                }
                freeSolo
                value={tags ? [...tags] : ['']}
                renderTags={(value: readonly string[], getTagProps) => {
                  return value
                    .filter((option: string) => option !== '')
                    .map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                }}
                onChange={(e, value) => {
                  setValue('tags', [
                    ...value.filter((tag) => tag.trim().length !== 0),
                  ])
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`Type Tags and Hit “Enter"`}
                    name="tags"
                    sx={{ maxWidth: 850 }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
                float: 'right',
                px: 0,
                my: 2,
              }}
              spacing={1}
            >
              <Button
                onClick={handleClose}
                variant="text"
                type="button"
                sx={{ color: 'text.secondary' }}
              >
                Cancel
              </Button>

              <LoadingButton variant="contained" type="submit" loading={loading}>
                Save
              </LoadingButton>
            </Stack>
          </Box>
        </FormProvider>
      </Dialog>
    </div>
  )
}

export default NewServiceModal
