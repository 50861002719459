import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Container,
  DialogTitle,
  IconButton,
  ListItemButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { OpenModalProps } from 'src/@types/modalProps'
import { del, put } from 'src/utils/httpMethods'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import Label from '../../../components/Label'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTheme } from '@mui/material'
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable'

import Scrollbar from 'src/components/Scrollbar'
import useSettings from 'src/hooks/useSettings'
import { useNavigate } from 'react-router'
import Iconify from 'src/components/Iconify'
import DeleteServiceTableRow from './DeleteServiceTableRow'
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/components/table'
import Page from 'src/components/Page'
import { UserTableRow } from '../user/list'
import DeleteMultiple from './DeleteMultiple'
const TABLE_HEAD = [{ id: '', label: 'Select All' }, { id: '' }]

interface Props {
  openProps: OpenModalProps
  serviceId: string
  endletId: string
  currentService: any
}

function DeleteServiceModal({
  openProps: { open, setOpen },
  serviceId,
  endletId,
  currentService,
}: Props) {
  const {
    dense,
    order,
    orderBy,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
  } = useTable()
  const [openMultiDelete, setOpenMultiDelete] = useState(false)
  const { themeStretch } = useSettings()

  const navigate = useNavigate()

  const [tableData, setTableData] = useState<any>([])

  // const handleDeleteRow = (id: string) => {
  //   const deleteRow = tableData.filter((row: any) => row.id !== id)
  //   setSelected([])
  //   setTableData(deleteRow)
  // }

  // const handleDeleteRows = (selected: string[]) => {
  //   const deleteRows = tableData.filter((row: any) => !selected.includes(row.id))
  //   setSelected([])
  //   setTableData(deleteRows)
  // }
  const handleClose = () => {
    setOpen(false)
    setSelected([])
  }
  useEffect(() => {
    if (open) setTableData(currentService?.associatedEndlets)
  }, [open])
  return (
    <Page title="">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle>Delete vWAN</DialogTitle>

        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="alert-dialog-description">
            Current Endlets associated with this vWAN
            <Card sx={{ mt: 1 }}>
              <TableContainer>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) => onSelectAllRows(checked, [])}
                    actions={
                      <></>
                      // <Tooltip title="Delete">
                      //   <IconButton
                      //     color="primary"
                      //     onClick={() => handleDeleteRows(selected)}
                      //   >
                      //     <Iconify icon={'eva:trash-2-outline'} />
                      //   </IconButton>
                      // </Tooltip>
                    }
                  />
                )}

                <Table>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row: any) => row.endletId)
                      )
                    }
                  />

                  <TableBody>
                    {tableData.map((row: any, index: number) => (
                      <DeleteServiceTableRow
                        key={index}
                        row={row}
                        selected={selected.includes(row.endletId)}
                        service={currentService}
                        onSelectRow={() => onSelectRow(row.endletId)}
                        // onDeleteRow={() => handleDeleteRow(row.endletId)}
                        setTableData={setTableData}
                        setSelected={setSelected}

                        // onEditRow={() => handleEditRow(row.name)}
                      />
                    ))}
                    <TableNoData
                      isNotFound={!tableData.length}
                      message={'No endlet found'}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={() => setOpenMultiDelete(true)}
            variant="contained"
            color="error"
            // loading={loading}
            disabled={selected.length < 1}
          >
            {selected.length === tableData.length && selected.length > 1
              ? 'Delete All'
              : 'Delete'}
          </LoadingButton>
          <Button
            onClick={handleClose}
            variant="text"
            type="button"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
        </DialogActions>
        <DeleteMultiple
          openProps={{
            open: openMultiDelete,
            setOpen: setOpenMultiDelete,
          }}
          currentService={currentService}
          selectedEp={selected}
          totalEp={tableData.length}
          setTableData={setTableData}
          setSelected={setSelected}
        />
      </Dialog>
    </Page>
  )
}

export default DeleteServiceModal
