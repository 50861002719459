import { useContext, useEffect, useState } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import { Avatar, TableRow, TableCell, Typography, MenuItem } from '@mui/material'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import DeleteIcon from '@mui/icons-material/Delete'
import LockResetIcon from '@mui/icons-material/LockReset'

// custom

import Label from 'src/components/Label'
import Iconify from 'src/components/Iconify'
import { TableMoreMenu } from 'src/components/table'
import { UserManager } from 'src/@types/user'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import DeleteResellerUserInvite from './DeleteResellerUserInvite'
import EditResellerUser from './EditResellerUser'
import ResetMFAModal from 'src/sections/@dashboard/user/list/ResetMFAModal'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { post } from 'src/utils/httpMethods'
import DeactivateResellerUser from './DeactivateResellerUser'

//data and types
const roles = [
  { code: 'Reseller_Admin', label: 'Administrator' },
  { code: 'Reseller_Engineer', label: 'Engineer' },
  { code: 'Reseller_Finance', label: 'Finance' },
]

type Props = {
  row: UserManager
  selected: boolean
  onEditRow: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
  orgId: string
  refetchUserData?: () => Promise<void>
  orgStatus: boolean
  orgName: string
}

function ResellerUserRow({
  row,
  selected,
  onSelectRow,
  refetchUserData,
  orgId,
  orgStatus,
  orgName,
}: Props) {
  const theme = useTheme()

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpenMenuActions(null)
  }

  const [openDeactivate, setOpenDeactivate] = useState(false)
  const [openDeleteInvite, setOpenDeleteInvite] = useState(false)
  const [openEditRole, setOpenEditRole] = useState(false)
  const [openResetMFA, setOpenResetMFA] = useState(false)

  const { firstName, lastName, avatarUrl, email, role, status, id } = row

  const { snackbarProps, setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const { open } = snackbarProps
  useEffect(() => {
    refetchUserData && refetchUserData()
  }, [open, refetchUserData])

  const handleResendInvite = async () => {
    try {
      const resp: any = await post(`/users/invite/${id}`)
      setSnackbarProps({
        open: true,
        severity: 'success',
        message: resp.message || 'Invitation has been sent',
      })
    } catch (error: any) {
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
      <Checkbox checked={selected} onClick={onSelectRow} />
    </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={firstName + '' + lastName} src={avatarUrl} sx={{ mr: 2 }} />
          <Typography variant="subtitle2" noWrap>
            {firstName + ' ' + lastName}
          </Typography>
        </TableCell>

        <TableCell align="left">{email}</TableCell>

        <TableCell align="left">
          {roles.map((r) => (r.code === role ? r.label : ''))}
        </TableCell>
        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
              status === 'Archived'
                ? 'error'
                : status === 'Invited'
                ? 'warning'
                : 'success'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status === 'Archived' ? 'Deactivated' : status}
          </Label>
        </TableCell>

        {orgStatus && (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                status === 'Invited' ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleResendInvite()
                        handleCloseMenu()
                      }}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ForwardToInboxIcon />
                      {`Resend Invite`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenDeleteInvite(true)
                        handleCloseMenu()
                      }}
                      sx={{
                        color: 'error.main',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <DeleteIcon />
                      {`Delete Invite`}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        setOpenDeactivate(true)
                        handleCloseMenu()
                      }}
                      sx={{
                        color: status === 'Active' ? 'error.main' : 'text.primary',
                      }}
                    >
                      {status === 'Active' ? (
                        <>
                          <PersonOffIcon />
                          {`Deactivate user`}
                        </>
                      ) : (
                        <>
                          <PersonAddAlt1Icon />
                          {`Activate user`}
                        </>
                      )}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleCloseMenu()
                        setOpenEditRole(true)
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Change Role
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu()
                        setOpenResetMFA(true)
                      }}
                    >
                      <LockResetIcon />
                      Reset MFA
                    </MenuItem>
                  </>
                )
              }
            />
          </TableCell>
        )}
      </TableRow>
      <DeleteResellerUserInvite
        openProps={{ openDeleteInvite, setOpenDeleteInvite }}
        user={firstName + ' ' + lastName}
        id={id}
        orgId={orgId}
        status={status}
      />
      <EditResellerUser
        user={firstName + ' ' + lastName}
        id={id}
        orgId={orgId}
        role={row.role}
        openProps={{ open: openEditRole, setOpen: setOpenEditRole }}
      />
      <ResetMFAModal
        openProps={{ openResetMFA, setOpenResetMFA }}
        user={firstName + ' ' + lastName}
        id={id}
      />
      <DeactivateResellerUser
        openProps={{ openDeactivate, setOpenDeactivate }}
        user={firstName + ' ' + lastName}
        id={id}
        orgId={orgId}
        status={status}
        orgName={orgName}
      />
    </>
  )
}

export default ResellerUserRow
