import React, { useCallback } from 'react'
import { paramCase } from 'change-case'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import {
  Box,
  Table,
  Tooltip,
  TableBody,
  IconButton,
  TableContainer,
  Skeleton,
  Card,
  Snackbar,
  Alert,
} from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// custom
import useTable from 'src/hooks/useTable'
import { PATH_USER } from 'src/routes/paths'
import { TableNoData, TableSelectedActions } from 'src/components/table'

import Scrollbar from 'src/components/Scrollbar'
import Page from 'src/components/Page'
import Iconify from 'src/components/Iconify'
import { get, post } from 'src/utils/httpMethods'
import { Reseller } from 'src/pages/super-user/ResellerManagement'
import ResellerUserRow from './ResellerUserRow'
import ResellerUserToolbar from './ResellerUserToolbar'

type Props = {
  reseller: Reseller
}

function ResellerUserList({ reseller }: Props) {
  const { dense, selected, setSelected, onSelectRow, onSelectAllRows } = useTable()

  const [users, setUsers] = useState<any[]>([])
  const [userLoading, setuserLoading] = useState(false)

  const navigate = useNavigate()

  const [tableData, setTableData] = useState<any>([])

  const [filterName, setFilterName] = useState('')

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName)
  }

  const getUsers = useCallback(async () => {
    setuserLoading(true)
    try {
      const resellerFilters = localStorage.getItem('reseller_filters')
      const res: unknown = await post(
        `${process.env.REACT_APP_HOST_API_URL}/resellers/${reseller?.id}/users/filters`,
        { filters: resellerFilters ? JSON.parse(resellerFilters) : null }
      )
      const userArr = res as any[]
      setUsers([...userArr])
      setuserLoading(false)
    } catch (e) {
      setuserLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row: any) => row.id !== id)
    setSelected([])
    setTableData(deleteRow)
  }

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row: any) => !selected.includes(row.id))
    // setSelected([])
    setTableData(deleteRows)
  }

  const handleEditRow = (id: string) => {
    navigate(PATH_USER.edit(paramCase(id)))
  }

  useEffect(() => {
    setTableData([...users])
  }, [users])

  // const [rows, setRows] = useState(5)

  // const handleRowChange = (event: SelectChangeEvent) => {
  //   setRows(parseInt(event.target.value))
  // }

  // snackbar
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleSnackBarClose = () => {
    setShowSnackbar(false)
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnackbar])

  return (
    <>
      <Page title="Manage users" className="mt-3">
        <Card>
          <>
            <ResellerUserToolbar
              filterName={filterName}
              onFilterName={handleFilterName}
              id={reseller?.id as string}
              refetchData={getUsers}
              snackBarProps={{
                setOpenToast: setShowSnackbar,
                setToastMessage: setSnackbarMessage,
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={tableData?.length}
                    onSelectAllRows={(checked) =>
                      tableData &&
                      onSelectAllRows(
                        checked,
                        tableData.map((row: any) => row.id)
                      )
                    }
                    actions={
                      <Tooltip title="Delete">
                        <IconButton
                          color="primary"
                          onClick={() => handleDeleteRows(selected)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                )}
                <Table size={dense ? 'small' : 'medium'}>
                  <TableBody>
                    {!users.length && !userLoading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TableNoData
                          isNotFound={!userLoading}
                          message={'No user found'}
                        />
                      </Box>
                    ) : (
                      <>
                        {users && users.length ? (
                          !tableData.filter((user: any) => {
                            const fullName = `${user.firstName.toLowerCase()}${user.lastName.toLowerCase()}`
                            return fullName.includes(filterName.toLowerCase())
                          }).length ? (
                            <TableNoData
                              isNotFound={true}
                              message={'No user found'}
                            />
                          ) : (
                            tableData
                              .filter((user: any) => {
                                const fullName = `${user.firstName.toLowerCase()}${user.lastName.toLowerCase()}`
                                return fullName.includes(filterName.toLowerCase())
                              })
                              .map((row: any) => (
                                <ResellerUserRow
                                  key={row?.id}
                                  row={row}
                                  orgId={reseller?.id as string}
                                  refetchUserData={getUsers}
                                  selected={selected.includes(row?.id)}
                                  onSelectRow={() => onSelectRow(row?.id)}
                                  onDeleteRow={() => handleDeleteRow(row?.id)}
                                  onEditRow={() => handleEditRow(row?.firstName)}
                                  orgStatus={reseller?.isActive}
                                  orgName={reseller?.name}
                                />
                              ))
                          )
                        ) : (
                          <Box sx={{ px: 2 }}>
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                          </Box>
                        )}
                        {/* <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(tableData?.length)}
                      /> */}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </>

          {/* {users.length ? (
        <Box sx={{ position: 'relative', py: 4, px: 2 }}>
          <FormControl size="small">
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <Typography variant="body2">{`Show`}</Typography>
              <Select
                id="select-small"
                defaultValue={'5'}
                onChange={handleRowChange}
              >
                <MenuItem value={'5'}>5</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'15'}>15</MenuItem>
              </Select>
              <Typography variant="body2">{`of ${tableData?.length} results`}</Typography>
            </Stack>
          </FormControl>

          <Pagination
            color="primary"
            sx={{ float: 'right', py: 2 }}
            variant="outlined"
            count={Math.ceil(tableData?.length / rows)}
            page={customPage}
            onChange={(e: unknown, newPage: number) => {
              setCustomPage(newPage)
            }}
          />
        </Box>
      ) : (
        <></>
      )} */}
        </Card>
      </Page>

      <Snackbar
        sx={{ width: '30vw', zIndex: 999999 }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackBarClose}
          icon={<CheckCircleIcon fontSize="inherit" />}
          severity="success"
          variant="outlined"
          sx={{
            width: '100%',
            color: 'text.primary',
            fontWeight: 'bold',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ResellerUserList
