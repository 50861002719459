import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { OpenModalProps } from 'src/@types/modalProps'
import { post } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

interface Props {
  openProps: OpenModalProps
  service: any
  setNewEpAdded?: any
}

const types = ['21Packets Cloud Deployed']
function IntermidiateEndlet({ openProps, service, setNewEpAdded }: Props) {
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const { open, setOpen } = openProps
  const [option, setOption] = useState('')
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleCreateEl = () => {
    setLoading(true)
    post(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/intermediate`,
      {
        endlet: {
          endletType: 'NOD',
          services: [
            {
              id: service.id,
              name: service.name,
            },
          ],
          serviceType: [service.type],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken') ?? ''}`,
        },
      }
    )
      .then((response: any) => {
        setSnackbarProps({
          open: true,
          message: 'Intermediate endlet created successfully!',
          severity: 'success',
        })
        setNewEpAdded(true)
        setLoading(false)
        handleClose()
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          message: e.message,
          severity: 'error',
        })
        handleClose()
      })
  }
  console.log(service)
  return (
    <Dialog open={open} onClose={handleClose} scroll="body" fullWidth maxWidth="sm">
      <DialogTitle>Add Intermediate Endlet</DialogTitle>

      <Box
        sx={{
          pl: 3,
          pr: 3,
          mt: 3,
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Select Intermediate Endlet Type</InputLabel>
          <Select
            fullWidth
            label="Select Intermediate Endlet Type"
            onChange={(e: any) => setOption(e.target.value)}
          >
            {types.map((type) => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ justifyContent: 'flex-end', display: 'flex', m: 2 }}>
        <LoadingButton
          variant="contained"
          sx={{ mt: 1 }}
          onClick={handleCreateEl}
          loading={loading}
        >
          Create
        </LoadingButton>
      </Box>
    </Dialog>
  )
}

export default IntermidiateEndlet
