// import { palette } from 'src/theme/palette.ts'
import cytoscape from 'cytoscape'
import globePNG from 'src/assets/Globe.png'
const imageURL = globePNG

export const graphStyle: cytoscape.Stylesheet[] = [
  {
    selector: 'node',
    style: {
      'background-image': `url(${imageURL})`,
      'background-fit': 'contain',
      'background-color': 'white',
      width: 50,
      height: 50,
      label: 'data(label)',
      'font-size': '16px',
      'font-weight': 'bold',
      color: '#000000',
      'text-background-shape': 'roundrectangle',
      'text-background-color': '#fff',
      'text-background-opacity': 1,
      //   events: 'no',
    },
  },
  {
    selector: 'edge',
    style: {
      width: 5,
      'line-color': 'blue',
      label: 'data(label)',
      'font-size': '14px',
      color: '#000000',
      'text-margin-x': 10,
      'text-margin-y': -10,
      'text-background-shape': 'roundrectangle',
      'text-background-color': '#fff',
      'text-background-opacity': 1,
    },
  },
]
