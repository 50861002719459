import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Skeleton, Chip, IconButton } from '@mui/material'
import { Stack } from '@mui/system'
import { get, del } from 'src/utils/httpMethods'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import MeshConfiguration from '../../sections/@dashboard/Services/MeshConfiguration'
import PointToPointServices from '../../sections/@dashboard/Services/PointToPointServices'
import HubNSpokevWan from '../../sections/@dashboard/Services/HubNSpokevWan'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))
interface props {
  endlet: any
  id: string
  getEndlets: () => Promise<void>
}

function ProvDtServices({ endlet, id, getEndlets }: props) {
  const [dataLoading, setDataLoading] = useState(false)
  const [openVWAN, setOpenvWAN] = useState(false)
  const [ipBlocksLeft, setIPBlocksLeft] = useState<any>([])
  const [newEPAdded, setNewEpAdded] = useState(false)

  const [openDeleteService, setOpenDeleteService] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const servicesArr = endlet?.services?.length ? [...endlet.services] : ['']
  const [isIPAdded, setIsIPAdded] = useState(false)
  const [delVWANType, setDelVWANType] = useState('')

  useEffect(() => {
    if (newEPAdded) getEndlets()
  }, [newEPAdded])

  return (
    <div>
      <Stack
        sx={{ mt: 2, mb: 2 }}
        direction="row"
        justifyContent="flex-end"
        spacing={1}
      >
        <Button
          variant="outlined"
          sx={{ width: 'fit-content' }}
          hidden={endlet.services.length}
          disabled
        >
          Select Existing Service
        </Button>
        <Button variant="outlined" sx={{ width: 'fit-content' }} disabled>
          + Add New Services
        </Button>
      </Stack>
      {endlet.serviceType.includes('vWAN') ? (
        <>
          {servicesArr?.map((each: any, index: number) => (
            <Accordion defaultExpanded={index === 0} key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1">
                  {endlet.services.length
                    ? endlet?.serviceType[index] +
                      ' - ' +
                      endlet?.services[index]?.subType
                    : endlet?.serviceType[0]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction={'row'} spacing={2} justifyContent="space-between">
                  <Stack direction={'row'} spacing={2} alignItems="center">
                    <Typography variant="body1" sx={{ pb: 1, pt: 1 }}>
                      {/* Current vWan */}
                      vWAN Status
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} spacing={1}>
                    <Button
                      disabled
                      size="small"
                      sx={{ width: 'fit-content', textTransform: 'none' }}
                      variant="outlined"
                      onClick={() => {
                        setOpenvWAN(true)
                        setCurrentIndex(index)
                      }}
                    >
                      Change vWAN
                    </Button>

                    <Button
                      disabled
                      hidden={
                        endlet.services.length &&
                        endlet.serviceType[index] === 'vWAN'
                          ? false
                          : true
                      }
                      size="small"
                      sx={{ width: 'fit-content', textTransform: 'none' }}
                      variant="outlined"
                      color="error"
                      onClick={() => (
                        setOpenDeleteService(true),
                        setCurrentIndex(index),
                        setDelVWANType('Remove')
                      )}
                    >
                      Remove vWAN From Endlet
                    </Button>
                    <Button
                      disabled
                      hidden={endlet.services.length ? false : true}
                      size="small"
                      sx={{ width: 'fit-content', textTransform: 'none' }}
                      variant="outlined"
                      color="error"
                      onClick={() => (
                        setOpenDeleteService(true),
                        setCurrentIndex(index),
                        setDelVWANType('Delete')
                      )}
                    >
                      Delete vWAN
                    </Button>
                  </Stack>
                </Stack>
                <LightTooltip
                  title={
                    each?.deploymentStatus === 'Service Configured' ||
                    each?.deploymentStatus === 'Service Deployed'
                      ? `${each.deploymentStatus} In Process`
                      : each?.deploymentStatus === 'Service Up' ||
                        each?.deploymentStatus === 'Service Down'
                      ? each.deploymentStatus
                      : ''
                  }
                  placement="right"
                >
                  <Chip
                    label={
                      endlet.services?.length ? endlet.services[index]?.name : 'None'
                    }
                    sx={{
                      color: 'white',
                      p: 2,
                      fontWeight: 'bold',
                      backgroundColor:
                        each?.deploymentStatus === 'Service Configured' ||
                        each?.deploymentStatus === 'Service Deployed'
                          ? 'orange'
                          : each?.deploymentStatus === 'Service Up'
                          ? '#00A54F'
                          : each?.deploymentStatus === 'Service Down'
                          ? 'red'
                          : '#0BB6DE',
                    }}
                  />
                </LightTooltip>

                {endlet.services.length ? (
                  each?.subType === 'Point To Point' ? (
                    <>
                      <PointToPointServices
                        isIpAddedProps={{ isIPAdded, setIsIPAdded }}
                        setStateMethodProps={{
                          setNewEpAdded,
                        }}
                        indexProps={{ each, index, endlet }}
                        isSuperUser={true}
                      />
                    </>
                  ) : each?.subType === 'Full Mesh' ? (
                    <>
                      <MeshConfiguration
                        isIpAddedProps={{ isIPAdded, setIsIPAdded }}
                        index={index}
                        isSuperUser={true}
                        endlet={endlet}
                      />
                    </>
                  ) : each?.subType === 'Hub And Spoke' ? (
                    <HubNSpokevWan
                      index={index}
                      endlet={endlet}
                      isIpAddedProps={{ isIPAdded, setIsIPAdded }}
                      isSuperUser={true}
                    />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ProvDtServices
