// @mui
import { styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, Typography, Button } from '@mui/material'
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop'
import useResponsive from '../../../hooks/useResponsive'
// utils
import cssStyles from '../../../utils/cssStyles'
// config
import { HEADER, NAVBAR } from '../../../config'
// components
import Logo from '../../../components/Logo'
import Iconify from '../../../components/Iconify'
import { IconButtonAnimate } from '../../../components/animate'
//
import BusinessIcon from '@mui/icons-material/Business'

// import Searchbar from "./Searchbar";
import AccountPopover from './AccountPopover'
// import LanguagePopover from "./LanguagePopover";
// import ContactsPopover from "./ContactsPopover";
import UserDropdown from 'src/components/TenantSelect'
import useAuth from 'src/hooks/useAuth'

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean
  isOffset: boolean
  verticalLayout: boolean
}

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: '#09284D',
    }),
  },
}))

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction
  isCollapse?: boolean
  verticalLayout?: boolean
}

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout

  const isDesktop = useResponsive('up', 'lg')
  const { user, organization } = useAuth()
  const Packets_Employee = ['Packets_Engineer', 'Packets_Finance', 'Packets_Support']
  const Reseller_Employee = [
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance',
  ]

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {/* {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />} */}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {/* <Searchbar /> */}
        {user?.role === 'Packets_Super_User' ||
        Packets_Employee.includes(user?.role) ||
        user?.role === 'Reseller_Admin' ||
        Reseller_Employee.includes(user?.role) ? (
          <UserDropdown />
        ) : (
          <Box
            component="span"
            sx={{
              color: 'text.secondary',
            }}
            className="d-flex align-items-center"
          >
            <Button
              disabled
              sx={{
                color: 'text.secondary',
                ':disabled': {
                  color: 'text.secondary',
                },
              }}
            >
              {' '}
              <BusinessIcon />
              <span className="ms-2">{organization?.name}</span>
            </Button>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}

          <Typography sx={{ color: 'text.secondary' }}>
            Hello, {user?.firstName} {user?.lastName}
          </Typography>
          {/* <NotificationsPopover /> */}

          <AccountPopover />
          {/* <ContactsPopover /> */}
        </Stack>
      </Toolbar>
    </RootStyle>
  )
}
