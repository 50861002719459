import React, { useContext, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { useForm } from 'react-hook-form'
import FileUploadIcon from '@mui/icons-material/FileUpload'

import { FormProvider, RHFTextField } from 'src/components/hook-form'
import { Dialog, DialogActions } from '@mui/material'
import { OpenModalProps } from 'src/@types/modalProps'
import { Box } from '@mui/material'
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { post } from 'src/utils/httpMethods'
import useAuth from 'src/hooks/useAuth'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { Stack } from '@mui/system'
import { Divider } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
  openProps: OpenModalProps
  setNewKeys?: () => Promise<void>
  newKeyFlag?: React.Dispatch<React.SetStateAction<boolean>>
}

type FormValuesProps = {
  newSSH_name: string
  userName: string
  newSSH_key: string
  sshPort: string
  afterSubmit?: string
}

const SSH_schema = Yup.object().shape({
  newSSH_name: Yup.string()
    .matches(/^[a-zA-Z0-9/_+=.@-]*$/, 'Invalid SSH key name')
    .required('SSH key name is required'),
  userName: Yup.string().required('SSH key name is required'),
  sshPort: Yup.string().required('SSH Port Number is required'),
  newSSH_key: Yup.string()
    .required('SSH key is required')
    .matches(
      /\s*(\bBEGIN\b).*(PRIVATE KEY\b)\s*.*([a-zA-Z0-9\n\/\.\:\+\ \=]+).*\s*(\bEND\b).*(PRIVATE KEY\b)\s*/gm,
      'Invalid SSH key'
    ),
})

function NewSSHModal({ openProps, setNewKeys, newKeyFlag }: Props) {
  const { open, setOpen } = openProps
  const { UseNewUserData } = useAuth()
  const { refetchUserData } = UseNewUserData()
  const [loading, setLoading] = useState(false)
  const [pemFile, setPemFile] = useState<File | undefined>(undefined)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const defaultValues = {
    newSSH_name: '',
    userName: '',
    sshPort: '22',
    newSSH_key: '',
  }
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SSH_schema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods

  const { newSSH_name } = watch()

  useEffect(() => {
    setValue('newSSH_name', newSSH_name.trim())
    console.log(newSSH_name)
  }, [newSSH_name])

  const onSubmit = async (data: FormValuesProps) => {
    console.log(data)

    setLoading(true)

    post(`${process.env.REACT_APP_HOST_API_URL}/auth/organization/ssh`, {
      sshKeyValue: data.newSSH_key,
      sshKeyName: data.newSSH_name,
      sshPortNumber: data.sshPort,
      username: data.userName,
    })
      .then((res) => {
        console.log(res)
        newKeyFlag && newKeyFlag(true)
        setLoading(false)
        handleClose()
        setSnackbarProps({
          open: true,
          message: 'SSH key added successfully!',
          severity: 'success',
        })
        // refetchUserData().then(() => {
        //   newKeyFlag && newKeyFlag(true)
        //   setLoading(false)
        //   handleClose()
        //   setSnackbarProps({
        //     open: true,
        //     message: 'SSH key added successfully!',
        //     severity: 'success',
        //   })
        // })
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          message: e,
          severity: 'error',
        })
        console.log(e)
      })
  }

  const handleClose = () => {
    setOpen(false)
    // setNewKeys && setNewKeys()
    setCommand1(false)
    setCommand2(false)
    setCommand3(false)
    setPemFile(undefined)
    reset()
  }

  const [command1, setCommand1] = useState(false)
  const [command2, setCommand2] = useState(false)
  const [command3, setCommand3] = useState(false)

  let fileReader: any

  const handleFileRead = (e: any) => {
    const content = fileReader.result
    console.log(content)
    setValue('newSSH_key', content.trim())
    // … do something with the 'content' …
  }

  const handleFileChosen = (file: File) => {
    fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(file)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth scroll="body">
      <Stack direction={'row'}>
        <Box sx={{ height: '100%' }}>
          <Typography variant="h4" sx={{ px: 2, pt: 3 }}>
            Add New SSH Key
          </Typography>
          <Box sx={{ width: 750, height: '100%', px: 1 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ p: 2 }}>
                <RHFTextField
                  autoComplete="off"
                  name="newSSH_name"
                  label="SSH Key name"
                  sx={{ mt: 2 }}
                ></RHFTextField>
                <RHFTextField
                  autoComplete="off"
                  sx={{ my: 2 }}
                  name="userName"
                  label="Username"
                ></RHFTextField>
                <RHFTextField
                  autoComplete="off"
                  sx={{ my: 2 }}
                  name="sshPort"
                  label="SSH Port Number"
                ></RHFTextField>
                <RHFTextField
                  autoComplete="off"
                  name="newSSH_key"
                  disabled={pemFile ? true : false}
                  multiline
                  minRows={5}
                  maxRows={8}
                  label="Enter New SSH key"
                  sx={{ mb: 2 }}
                ></RHFTextField>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  OR
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', mr: 1, mb: 1 }}
                  component="label"
                  startIcon={<FileUploadIcon />}
                >
                  Attach PEM file
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      console.log('hello')
                      const file = e.target.files ? e.target.files[0] : undefined
                      if (file?.name.split('.')[1] !== 'pem') {
                        setSnackbarProps({
                          open: true,
                          severity: 'error',
                          message: 'Only files with ".pem" extension allowed!',
                        })
                        return
                      } else {
                        handleFileChosen(file)
                        console.log(file)
                        setPemFile(file)
                      }
                    }}
                  />
                </Button>
                {pemFile && (
                  <Stack direction={'row'} alignItems="center" spacing={2}>
                    <span>{pemFile.name}</span>
                    <Button
                      startIcon={<DeleteIcon />}
                      color="error"
                      onClick={() => {
                        setPemFile(undefined)
                        setValue('newSSH_key', '')
                      }}
                    >
                      Remove file
                    </Button>
                  </Stack>
                )}
              </Box>
              <DialogActions sx={{ float: 'bottom', px: 0 }}>
                <Button
                  onClick={handleClose}
                  variant="text"
                  type="button"
                  sx={{ color: 'text.secondary' }}
                >
                  Cancel
                </Button>

                <LoadingButton loading={loading} variant="contained" type="submit">
                  Add SSH Key
                </LoadingButton>
              </DialogActions>
            </FormProvider>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: 'black', my: 2 }}
        />
        <Box sx={{ height: '100%', px: 1, pt: 3 }}>
          <Typography variant="subtitle1">SSH Keys</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Follow these instructions to create or add SSH keys on Linux, MacOS &
            Windows. Windows users without OpenSSH can install and use PuTTY instead.
            Create a new key pair, if needed Open a terminal and run the following
            command:
          </Typography>
          <Box
            sx={{
              borderRadius: '5px',
              border: '1px solid lightgray',
              my: 2,
              p: 1,
              bgcolor: '#ebebeb',
            }}
          >
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              ssh-keygen -t ed25519
              <Button
                variant="outlined"
                startIcon={command1 && <CheckIcon />}
                onClick={() => {
                  setCommand1(true)
                  setCommand2(false)
                  setCommand3(false)

                  navigator.clipboard.writeText('ssh-keygen -t ed25519')
                }}
              >
                {command1 ? 'Copied' : 'Copy'}
              </Button>
            </Stack>
          </Box>
          <Typography variant="body2" sx={{ mt: 2 }}>
            You will be prompted to save and name the key. Generating public/private
            ed25519 key pair. Enter file in which to save the key
            (/Users/USER/.ssh/id_ed25519):Next you will be asked to create and
            confirm a passphrase for the key (highly recommended): Enter passphrase
            (empty for no passphrase): Enter same passphrase again:This will generate
            two files, by default called id_ed25519 and id_ed25519.pub. Next, add
            contents of public key id_ed25519.pub into
            /Users/USER/.ssh/authorized_keys file.
          </Typography>

          <Box
            sx={{
              borderRadius: '5px',
              border: '1px solid lightgray',
              my: 2,
              p: 1,
              bgcolor: '#ebebeb',
            }}
          >
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              {'cat ~/.ssh/id_ed25519.pub >> ~/.ssh/authorized_keys'}

              <Button
                variant="outlined"
                startIcon={command2 && <CheckIcon />}
                onClick={() => {
                  setCommand2(true)
                  setCommand1(false)
                  setCommand3(false)
                  navigator.clipboard.writeText(
                    'cat ~/.ssh/id_ed25519.pub >> ~/.ssh/authorized_keys'
                  )
                }}
              >
                {command2 ? 'Copied' : 'Copy'}
              </Button>
            </Stack>
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Next, copy and paste the contents of the private key id_ed25519 file into
            the SSH key content field on the left.
          </Typography>

          <Box
            sx={{
              borderRadius: '5px',
              border: '1px solid lightgray',
              my: 2,
              p: 1,
              bgcolor: '#ebebeb',
            }}
          >
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              cat ~/.ssh/id_ed25519
              <Button
                variant="outlined"
                startIcon={command3 && <CheckIcon />}
                onClick={() => {
                  setCommand3(true)
                  setCommand2(false)
                  setCommand1(false)
                  navigator.clipboard.writeText('cat ~/.ssh/id_ed25519')
                }}
              >
                {command3 ? 'Copied' : 'Copy'}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default NewSSHModal
