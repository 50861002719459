import React, { useContext, useEffect, useState } from 'react'

// @mui
import { Button, Container, TextField, Typography, Divider } from '@mui/material'
import { Stack } from '@mui/system'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'

// @rhf
import { FormProvider, RHFTextField } from 'src/components/hook-form'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'

import Page from 'src/components/Page'
import useSettings from 'src/hooks/useSettings'

// _mock_
// import { _samlData } from "../../_mock";
import AddSAML from './AddSAML'
import { yupResolver } from '@hookform/resolvers/yup'
import useAuth from 'src/hooks/useAuth'
import { put } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'

type FormValuesProps = {
  SAMLIdentityProvider: {
    providerId: string
    providerCertificate: string
    SSO_URL: string
  }
  SAMLServiceProvider: {
    providerId: string
    ACS_URL: string
  }
}

// ----------------------------------------------------------------------

function SAMLsettings() {
  const { themeStretch } = useSettings()
  const [openAddNew, setOpenAddNew] = useState(false)
  const { organization, UseNewUserData } = useAuth()
  const { refetchUserData } = UseNewUserData()
  const defaultValues = {
    SAMLIdentityProvider: {
      providerId: '',
      providerCertificate: '',
      SSO_URL: '',
    },
    SAMLServiceProvider: {
      providerId: '',
      ACS_URL: '',
    },
  }

  const SAMLsettningsSchema = Yup.object().shape({
    SAMLIdentityProvider: Yup.object().shape({
      providerId: Yup.string().trim().required('Provider ID is required!'),
      SSO_URL: Yup.string()
        .url('Invalid URL')
        .trim()
        .required('Single Sign On URL is required!'),
      providerCertificate: Yup.string().trim().required('Certificate is required!'),
    }),
    SAMLServiceProvider: Yup.object().shape({
      providerId: Yup.string().trim().required(),
      ACS_URL: Yup.string().trim().required(),
    }),
  })
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SAMLsettningsSchema),
    defaultValues,
  })

  const [loading, setLoading] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const updateOrg = async (data: FormValuesProps) => {
    try {
      setLoading(true)
      await put(`${process.env.REACT_APP_HOST_API_URL}/auth/organizations/self`, {
        ...data,
      })
      setLoading(false)
      refetchUserData()
      setSnackbarProps({
        open: true,
        message: 'Single Sign On details updated succesfully!',
        severity: 'success',
      })
    } catch (e) {
      setLoading(false)
      setSnackbarProps({
        open: true,
        message: 'Error while updating Single Sign On details!',
        severity: 'error',
      })
      console.log(e)
    }
  }

  const onSubmit = async (data: FormValuesProps) => {
    console.log(data)
    console.log(errors)
    await updateOrg(data)
  }

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods

  useEffect(() => {
    setValue(
      'SAMLServiceProvider.providerId',
      organization?.SAMLServiceProvider.providerId
    )
    setValue(
      'SAMLServiceProvider.ACS_URL',
      organization?.SAMLServiceProvider.ACS_URL
    )
    setValue(
      'SAMLIdentityProvider.providerId',
      organization?.SAMLIdentityProvider?.providerId
    )

    setValue(
      'SAMLIdentityProvider.SSO_URL',
      organization?.SAMLIdentityProvider?.SSO_URL
    )
    setValue(
      'SAMLIdentityProvider.providerCertificate',
      organization?.SAMLIdentityProvider?.providerCertificate
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  return (
    <>
      <Page title="SSO settings">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Single Sign On"
            links={[{ name: 'Dashboard' }, { name: 'User' }, { name: 'List' }]}
          />
          <AddSAML openProps={{ openAddNew, setOpenAddNew }} />
          <Typography variant="h6">SAML Configuration For 21Packets</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ my: 3 }}>
              <RHFTextField
                fullWidth
                name="SAMLIdentityProvider.providerId"
                label="Issuer"
                helperText="The issuer URL of the IdP. This is usually the same as the IdP metadata url"
              />

              <RHFTextField
                fullWidth
                name="SAMLIdentityProvider.SSO_URL"
                label="Single Sign On (SSO) Url"
                helperText="The url of your Identity Provider endlet"
              />
              <RHFTextField
                multiline
                rows={4}
                fullWidth
                name="SAMLIdentityProvider.providerCertificate"
                label="X.509 Certificate"
                helperText="The PEM formatted x509 Certificate of the IdP."
              />

              <Divider />

              <Typography variant="h6">
                SAML Configuration For Your Identity Provider (IDP)
              </Typography>

              {/* <TextField
                disabled
                fullWidth
                name="metadatUrl"
                defaultValue={'https://21packets.com/sso/auth/samlp/metadata.xml'}
                label="Metadata URL"
                helperText="The location of the metadata document for the IdP."
              /> */}

              <TextField
                disabled
                fullWidth
                defaultValue={organization?.SAMLServiceProvider.providerId}
                // name="SAMLServiceProvider.providerId"
                label="Issuer"
              />

              <TextField
                fullWidth
                disabled
                // name="SAMLServiceProvider.ACS_URL"
                label="ACS URL"
                defaultValue={organization?.SAMLServiceProvider.ACS_URL}
                helperText="The URL of the 21Packets SSO login endlet"
              />

              {/* <TextField
                fullWidth
                disabled
                multiline
                rows={4}
                defaultValue={`attributes.mappings = {
              "email":   "Email",  // required
          }`}
                name="ssoUrl"
                label="SAML attributes mapping"
                helperText="The mapping needed on IDP side."
              /> */}
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: 'flex-end', mb: 3 }}
            >
              <Button sx={{ color: 'text.disabled' }} type="button">
                Cancel
              </Button>
              <LoadingButton loading={loading} variant="contained" type="submit">
                Save changes
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Container>
      </Page>
    </>
  )
}

export default SAMLsettings
