import { useState } from 'react'
import { Outlet } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
// hooks
import useSettings from '../../hooks/useSettings'
import useResponsive from '../../hooks/useResponsive'
import useCollapseDrawer from '../../hooks/useCollapseDrawer'
// config
import { HEADER, NAVBAR } from '../../config'
import { useTheme } from '@mui/material/styles'

//
import DashboardHeader from './header'
import NavbarVertical from './navbar/NavbarVertical'
import NavbarHorizontal from './navbar/NavbarHorizontal'
import SnackbarTopLevel from 'src/sections/@dashboard/SnackbarTopLevel'
import ModalTopLevel from 'src/sections/@dashboard/ModalTopLevel'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import AlertMFASettings from 'src/pages/auth/AlertMFASettings'

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean
}

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer()

  const { themeLayout } = useSettings()
  const theme = useTheme()

  const isDesktop = useResponsive('up', 'lg')

  const [open, setOpen] = useState(false)

  const isVerticalLayout = themeLayout === 'vertical'
  console.log(themeLayout)

  if (isVerticalLayout) {
    return (
      <>
        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <>
            <NavbarVertical
              isOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
          </>
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
          <SnackbarTopLevel />
          <ModalTopLevel />
          <AlertMFASettings />
        </Box>
      </>
    )
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      <IconButton
        size="small"
        onClick={onToggleCollapse}
        sx={
          collapseClick
            ? {
                '&:hover': {
                  border: `2px solid ${theme.palette.primary.main}`,
                  bgcolor: theme.palette.primary.lighter,
                },
                border: `2px solid ${theme.palette.primary.main}`,
                bgcolor: 'white',
                position: 'fixed',
                left: 70,
                top: 25,
                zIndex: 1299,
                transition: '0.3s',
              }
            : {
                '&:hover': {
                  border: `2px solid ${theme.palette.primary.main}`,
                  bgcolor: theme.palette.primary.lighter,
                },
                border: `2px solid ${theme.palette.primary.main}`,
                bgcolor: 'white',
                position: 'fixed',
                transition: '0.3s',
                left: 260,
                top: 25,
                zIndex: 1299,
              }
        }
      >
        {collapseClick ? (
          <KeyboardDoubleArrowRightIcon />
        ) : (
          <KeyboardDoubleArrowLeftIcon />
        )}
      </IconButton>

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
        <SnackbarTopLevel />
        <ModalTopLevel />
        <AlertMFASettings />
      </MainStyle>
    </Box>
  )
}
