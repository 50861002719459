import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { COUNTRIES } from '../Services(Endlets)/CreateEndletModal'

import {
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Box,
  FormHelperText,
  Divider,
} from '@mui/material'
import { Stack } from '@mui/system'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { get, put } from 'src/utils/httpMethods'

import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form'

import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'

import ChangeSSHkey from './ChangeSSHkey'
import useAuth from 'src/hooks/useAuth'
import { InterfacesTabView } from './Configure Endlet Tabs'
type FormValuesProps = {
  endletName: string
  tags: Array<string>
  cloudProvider: string
  region: any
  systemGeneratedGeotag: boolean
  location_name: string
  country: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  endletIp: string
  sshKey: any
  location: any
  isExisting: boolean
  currentService: string
  afterSubmit?: string
}

const endletSchema = Yup.object().shape({
  endletName: Yup.string(),
  tags: Yup.array(),
  cloudProvider: Yup.string(),
  systemGeneratedGeotag: Yup.boolean(),
  currentService: Yup.string(),
  location: Yup.object().shape({
    name: Yup.string(),
    country: Yup.string(),
    address1: Yup.string(),
    address2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
  }),
  endletIp: Yup.string(),
  sshKey: Yup.object().shape({
    sshKeyName: Yup.string(),
    sshKeyValue: Yup.string().matches(
      /^ssh-rsa AAAA[0-9A-Za-z+/]+[=]{0,3} ([^@]+@[^@]+)$/,
      'Invalid SSH key'
    ),
    username: Yup.string(),
  }),
})

interface Props {
  endlet: any
  endletName: string
  getEndlets: () => Promise<void>
}
function EpDtConfiguration({ endlet, endletName, getEndlets }: Props) {
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [openChangeSSH, setOpenChangeSSH] = useState(false)
  const [tagsList, setTagsList] = useState<any>()
  const [regions, setRegions] = useState<any>()
  const [initialSSH, setInitialSSH] = useState<any>({})
  const [countryLookup, setCountryLookup] = useState('')
  const { organization } = useAuth()
  const orgName = organization?.name
  const defaultValues = {
    endletName: '',
    cloudProvider: '',
    region: '',
    tags: [],
    systemGeneratedGeotag: false,
    currentService: 'vWan 1',
    location: {
      name: '',
      country: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
    endletIp: '',
    isExisting: false,
    sshKey: {
      sshKeyName: '',
      sshKeyValue: '',
      username: '',
    },
  }
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(endletSchema),
    defaultValues,
  })
  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods

  const {
    systemGeneratedGeotag: endletLoc,
    sshKey,
    tags,
    cloudProvider,
    location,
    region,
  } = watch()

  useEffect(() => {
    reset(endlet)
    setInitialSSH({ ...endlet.sshKey })
    // if(typeof endlet.region)
  }, [endlet])

  useEffect(() => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/regions?cloudProvider=${cloudProvider}`
    )
      .then((res: any) => {
        setRegions(res.regions)
      })
      .catch((e) => console.log(e))
  }, [cloudProvider])

  const fetchTags = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/tags?tagType=Endlet`)
      .then((res: any) => {
        setTagsList(res)
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    fetchTags()
  }, [])

  const onSubmit = async (data: FormValuesProps) => {
    const finalData = {
      endlet: {
        endletName: endletName,
        tags: data.tags,
        systemGeneratedGeotag: data.systemGeneratedGeotag,
        sshKey: data.sshKey,
        region: endlet.region,
      },
    }
    setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, finalData)
      .then((response: any) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Endlet updated successfully`,
        })
        getEndlets()
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: `Error while updating endlet`,
        })
      })
  }

  const hideAddress =
    typeof endletLoc === 'boolean' ? endletLoc : endletLoc === 'true' ? true : false
  const onDelete = (title: any) => () => {
    setValue(
      'tags',
      tags.filter((v) => v !== title)
    )
  }
  useEffect(() => {
    setValue('sshKey', initialSSH)
  }, [initialSSH])

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="subtitle1" sx={{ pb: 2 }} mt={2}>
          GENERAL
        </Typography>
        <Typography variant="subtitle1" sx={{}}>
          Tags
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormHelperText sx={{ pb: 2 }}>
              Create a New Tag Below or Select From an Existing Tag
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <FormHelperText sx={{ pb: 2 }}>Tag Suggestions</FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              options={
                tagsList
                  ? tagsList.length
                    ? tagsList.map((option: any) => option.name)
                    : ['']
                  : ['']
              }
              value={tags ? [...tags] : ['']}
              onChange={(e, newValue) =>
                setValue('tags', [
                  ...newValue.filter((tag) => tag.trim().length !== 0),
                ])
              }
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Type Tags and Hit 'Enter'"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} spacing={2}>
              <Chip label="Cloud" />
              <Chip label="DC" />
              <Chip label="PCI" />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} pt={1} mb={1}>
          {tags?.map((v, i) => (
            <Grid item key={i}>
              <Chip key={v} label={v} onDelete={onDelete(v)} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ justifyContent: 'flex-end', display: 'flex', mb: 1 }}>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            save
          </LoadingButton>
        </Box>
        <Divider sx={{ width: '100%', borderColor: 'darkgray' }} />
        <Typography variant="subtitle1" sx={{ pb: 2, pt: 2 }}>
          LOCATION
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Provider
            </Typography>
            <RHFSelect
              disabled
              name="cloudProvider"
              // label="Cloud Provider"
              placeholder="Cloud Provider"
            >
              <option value=""> </option>
              <optgroup label="Cloud Providers">
                <option value="aws"> Amazon Web Services </option>
                <option value="maz"> Microsoft Azure </option>
                <option value="gcp"> Google Cloud Platform </option>
              </optgroup>
              <optgroup label="Other">
                <option value="custom"> Custom Endlet</option>
              </optgroup>
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1 }}
              hidden={
                endlet.cloudProvider === 'custom' &&
                endlet.systemGeneratedGeotag === false
              }
            >
              Deployment Location
            </Typography>
            <RHFSelect
              disabled
              InputLabelProps={{ shrink: true }}
              name="region"
              value={region}
              // label="Deployment Location"
              placeholder="Select Region"
              hidden={
                endlet.cloudProvider === '' || endlet.cloudProvider === 'custom'
              }
            >
              <option value=""> </option>
              {regions && regions.length ? (
                <>
                  {regions.map((reg: any, i: any) => (
                    <option value={reg} key={i}>
                      {reg}
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>
                  Loading...
                </option>
              )}
            </RHFSelect>
            <RHFTextField
              disabled
              value={endlet.region}
              name="region"
              hidden={
                endlet.cloudProvider !== 'custom' ||
                endlet.systemGeneratedGeotag === false
              }
            ></RHFTextField>
          </Grid>
        </Grid>
        {endlet.cloudProvider === 'custom' &&
        endlet.systemGeneratedGeotag === false ? (
          <>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Location
                </Typography>
                <RHFTextField
                  disabled
                  name="location.name"
                  hidden={hideAddress}
                ></RHFTextField>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Country
                </Typography>
                <Autocomplete
                  hidden={hideAddress}
                  fullWidth
                  disabled
                  id="combo-box-demo"
                  options={Object.keys(COUNTRIES).map((obj: string) => obj)}
                  value={location && location.country ? location.country : ''}
                  onChange={(e, value) => {
                    setValue('country', value ? value : '')
                    setCountryLookup(value ? COUNTRIES[value].toString() : '')
                  }}
                  renderInput={(params) => (
                    <RHFTextField {...params} name="location.country" />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ display: hideAddress ? 'none' : '' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Address 1
                </Typography>
                <RHFTextField disabled name="location.address1"></RHFTextField>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Address 2
                </Typography>
                <RHFTextField disabled name="location.address2"></RHFTextField>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  City
                </Typography>
                <RHFTextField disabled name="location.city"></RHFTextField>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  State / Province
                </Typography>
                <RHFTextField disabled name="location.state"></RHFTextField>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  ZIP
                </Typography>
                <RHFTextField disabled name="location.zip"></RHFTextField>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ pb: 1 }} mt={2}>
              Endlet IP Address
            </Typography>
            <RHFTextField disabled name="endletIp"></RHFTextField>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 3, width: '100%', borderColor: 'darkgray' }} />
        <Box hidden={endlet.status === 'Up'}>
          <Typography variant="subtitle1" sx={{ py: 2 }}>
            AUTHENTICATION
          </Typography>

          <Stack direction={'row'} alignItems="center" sx={{ mb: 2 }} spacing={2}>
            <Typography variant="subtitle2">Current SSH Key</Typography>

            <Chip
              label={
                initialSSH &&
                initialSSH.sshKeyName &&
                initialSSH.sshKeyName
                  .replace(/-/g, ' ')
                  .replace(orgName?.toLowerCase(), '')
              }
              sx={{
                p: 2,
                fontWeight: 'bold',
                backgroundColor: '#00A54F',
                color: 'white',
                // textTransform: 'capitalize',
              }}
            />

            <Button
              style={{ marginLeft: 'auto' }}
              variant="outlined"
              onClick={() => setOpenChangeSSH(true)}
            >
              Change SSH Key
            </Button>
          </Stack>
          <Divider sx={{ mt: 3, width: '100%', borderColor: 'darkgray' }} />
        </Box>
      </FormProvider>

      <InterfacesTabView />

      <ChangeSSHkey
        openProps={{
          open: openChangeSSH,
          setOpen: setOpenChangeSSH,
        }}
        endlet={endlet}
        sshKey={sshKey}
        getEndlets={getEndlets}
      />
    </div>
  )
}

export default EpDtConfiguration
