import React, { useContext } from "react"
import { TopLevelmodalContext } from "src/contexts/ModalContext"
import { Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    p: 4,
    textAlign: 'center'
};

function ModalTopLevel() {
    const { modalProps, setModalProps } = useContext(TopLevelmodalContext)!
    const { open, severity, message } = modalProps!
    const handleClose = () => {
        setModalProps((prev) => ({ ...prev, open: false }))
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {severity == 'success' ? <CheckCircleOutlineIcon sx={{ width: 85, height: 85, p: 1 ,color:'#0A853D'}} /> :
                         <WarningAmberIcon sx={{ width: 72, height: 72, p: 1 }} color="error" />}
                    <Typography id="modal-modal-title" sx={{ fontSize: 16, color: '#000000', p: 2, fontWeight: 'bold' }}>
                        {message}
                    </Typography>
                    <hr/>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ mt: 2 }}
                    >
                        OK
                    </Button>
                </Box>
            </Modal>
        </>
    );
}


export default ModalTopLevel