import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { red } from '@mui/material/colors'

import { post } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'

function ResetMFAModal({
  user: name,
  openProps,
  id,
}: {
  openProps: {
    openResetMFA: boolean
    setOpenResetMFA: React.Dispatch<React.SetStateAction<boolean>>
  }
  user: string
  id: string
}) {
  const { openResetMFA: open, setOpenResetMFA: setOpen } = openProps
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleResetMFA = async () => {
    try {
      setLoading(true)
      await post(`${process.env.REACT_APP_HOST_API_URL}/auth/mfa/reset`, {
        userId: id,
      })
      handleClose()
      setLoading(false)
      setSnackbarProps({
        open: true,
        severity: 'success',
        message: `MFA settings successfully reset for ${name}`,
      })
    } catch (e) {
      console.log(e)
      setLoading(false)
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Error resetting MFA settings for ${name}`,
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ width: 600 }}>
        <DialogTitle>Reset MFA Settings?</DialogTitle>
        <DialogContentText sx={{ p: 3 }}>
          {`After reset, in order to access the portal ${name} has to configure a new MFA`}
        </DialogContentText>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleResetMFA}
            variant="contained"
            sx={{
              bgcolor: red[400],
              color: 'white',
              '&:hover': { bgcolor: red[900] },
            }}
          >
            Reset MFA
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ResetMFAModal
