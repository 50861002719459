import React, { useState } from 'react'

import { Stack, InputAdornment, TextField, Button } from '@mui/material'
import { Box } from '@mui/system'

import Iconify from '../../../../../components/Iconify'
import { SnackbarProps } from 'src/@types/modalProps'
import InviteResellerUserModal from './InviteResellerUserModal'

type Props = {
  filterName: string
  onFilterName: (value: string) => void
  id: string
  refetchData: () => Promise<void>
  snackBarProps: SnackbarProps
}

function ResellerUserToolbar({
  filterName,
  onFilterName,
  id,
  refetchData,
  snackBarProps,
}: Props) {
  const [open, setOpen] = useState(false)
  const { setOpenToast, setToastMessage } = snackBarProps
  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        alignItems={'center'}
        sx={{ p: 1, mt: 3, mb: 1, px: 2 }}
        justifyContent="space-between"
      >
        <Box>
          <TextField
            fullWidth
            size="small"
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder="Search User..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ width: '30%' }}>
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={() => setOpen(true)}
          >
            Invite User
          </Button>
        </Box>
      </Stack>
      <InviteResellerUserModal openProps={{ open, setOpen }} id={id} />
    </>
  )
}

export default ResellerUserToolbar
