import React, { useEffect, useState } from 'react'
import AddEndletFilter from './AddEndletFilter'
import Scrollbar from 'src/components/Scrollbar'

// @mui

import Chip from '@mui/material/Chip'
import { Box, Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

interface Props {
filterProps: {
  setFilterItems: React.Dispatch<React.SetStateAction<string[]>>
  filterItems: any
}
rows: any
page: any
}

function EndletFilters({ filterProps, rows, page }: Props) {
const { setFilterItems, filterItems } = filterProps
const [openAddModal, setOpenAddModal] = useState(false)
const handleDelete = (index: number) => {
  setFilterItems(filterItems.filter((items: any, i: number) => i !== index))
}

useEffect(() => {
  localStorage.setItem('filters', JSON.stringify(filterItems))
}, [filterItems])
const clearFilters = () => {
  localStorage.removeItem('filters')
  setFilterItems([])
}
return (
  <Stack direction={'row'} justifyContent="space-between" sx={{ pt: 2, px: 1 }}>
    <Stack
      direction={'row'}
      spacing={1}
      alignItems="center"
      sx={{ width: '100%' }}
    >
      <Box sx={{ ml: 'auto', py: 2 }}>
        <Button
          onClick={() => setOpenAddModal(true)}
          variant="outlined"
          sx={{ py: 1 }}
          startIcon={<FilterAltIcon />}
        >
          Apply Filter
        </Button>
        <AddEndletFilter
          openProps={{ open: openAddModal, setOpen: setOpenAddModal }}
          filterProps={{
            setFilterItems: setFilterItems,
            filterItems: filterItems,
          }}
        />
      </Box>
      {/* items.label.split('_').join(' ')[0].toUpperCase() +
                  items.label.split('_').join(' ').slice(1).toLowerCase() + */}
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Stack direction="row" spacing={1} sx={{}}>
          {filterItems.map((items: any, index: number) => {
            return (
              <Chip
                label={
                  items.label
                    .split('_')
                    .map(
                      (each: any) => each.charAt(0).toUpperCase() + each.slice(1)
                    )
                    .join(' ') +
                  ' ' +
                  items.operator.split('_').join(' ') +
                  ' ' +
                  items.value
                }
                style={{ padding: '20px 0px 20px 0px', fontSize: '15px' }}
                onDelete={() => handleDelete(index)}
              />
            )
          })}
        </Stack>
      </Scrollbar>
      {filterItems.length ? (
        <Box sx={{ ml: 'auto', py: 2 }}>
          <Typography
            onClick={() => clearFilters()}
            variant="body2"
            component={'span'}
            sx={{ px: 2, pb: 2, cursor: 'pointer', color: 'blue' }}
          >
            <u>Clear filters</u>
          </Typography>
        </Box>
      ) : (
        ''
      )}
    </Stack>
  </Stack>
)
}

export default EndletFilters
