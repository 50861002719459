import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { OpenModalProps } from 'src/@types/modalProps'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { put } from 'src/utils/httpMethods'

const roles = [
  {
    label: 'Administrator',
    value: 'Reseller_Admin',
  },

  {
    label: 'Engineer',
    value: 'Reseller_Engineer',
  },
  {
    label: 'Finance',
    value: 'Reseller_Finance',
  },
]

interface Props {
  openProps: OpenModalProps
  user: string
  role: string
  id: string
  orgId: string
}

function EditResellerUser({ openProps, user, role, id, orgId }: Props) {
  const { open, setOpen } = openProps
  const [loading, setLoading] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const handleClose = () => {
    setOpen(false)
  }
  const [userRole, setUserRole] = useState('')

  const onSubmit = async () => {
    try {
      setLoading(true)
      await put(`${process.env.REACT_APP_HOST_API_URL}/resellers/users/${id}`, {
        role: userRole,
      })
      handleClose()
      setLoading(false)

      setSnackbarProps({
        open: true,
        severity: 'success',
        message: `User role for ${user} changed`,
      })
    } catch (e) {
      setLoading(false)
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Error while changing role for ${user}`,
      })
    }
  }
  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ width: 600 }}>
        <DialogTitle>Change user role?</DialogTitle>
        <DialogContentText sx={{ p: 3 }}>
          {`Change User role and permissions for ${user}?`}
        </DialogContentText>
        <Box sx={{ p: 3 }}>
          <Select
            fullWidth
            onChange={(e) => setUserRole(e.target.value)}
            defaultValue={role}
          >
            {roles.map((r) => (
              <MenuItem value={r.value} key={r.value}>
                {r.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => {
              onSubmit()
            }}
            variant="contained"
          >
            Save changes
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default EditResellerUser
