import React, { useEffect, useState } from 'react'

import { Link as RouterLink, useNavigate } from 'react-router-dom'

import Page from 'src/components/Page'
import { styled } from '@mui/material/styles'
import LogoBlackText from 'src/components/LogoBlackText'
import useResponsive from 'src/hooks/useResponsive'
import { Card, Container, Grid, Link, Typography } from '@mui/material'
import { PATH_AUTH } from 'src/routes/paths'
import { Button } from '@mui/material'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  // zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

function BackupCodes() {
  const smUp = useResponsive('up', 'sm')
  const [backupCodes, setBackupCodes] = useState<string[]>(
    JSON.parse(localStorage.getItem('backupCodes') ?? `['']`)
  )

  useEffect(() => {}, [])
  const navigate = useNavigate()
  return (
    <Page title="Scan QR" sx={{ bgcolor: '#F9FAFB ' }}>
      <RootStyle>
        <HeaderStyle>
          <LogoBlackText />
        </HeaderStyle>
        <Container maxWidth="md">
          <ContentStyle>
            <Card elevation={6} sx={{ p: 3 }}>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                Backup Codes give you a way of authenticating yourself if you've lost
                access to your authenticator.
              </Typography>

              <Typography variant="subtitle2">
                These are your one time use 21Packets Backup Codes. Cross them off as
                you use them.
              </Typography>
              <Typography variant="subtitle2">
                Keep this list in a safe place and don't share it
              </Typography>
              <Grid container sx={{ my: 4 }}>
                {backupCodes.length > 1 &&
                  backupCodes.map((code, i) => (
                    <Grid item xs={6}>
                      {' '}
                      <Typography>{`${i + 1}) ${code}`}</Typography>
                    </Grid>
                  ))}
              </Grid>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/dashboard`, { replace: true })
                  localStorage.removeItem('backupCodes')
                }}
              >
                {' '}
                Continue to Portal{' '}
              </Button>
            </Card>

            {!smUp && (
              <Typography
                variant="body2"
                fontSize="18px"
                align="center"
                sx={{ mt: 3 }}
              >
                Don’t have an account?
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                  fontSize="18px"
                >
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}

export default BackupCodes
