import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Skeleton } from '@mui/material'

import { IPAddress } from '../ConfigureEndletModal'
import InterfaceIPv6Row from './InterfaceIPv6Row'
import { TableNoData } from 'src/components/table'

interface Props {
  addresses: IPAddress[]
}

const columnsv6 = ['IPv6 Address', 'Prefix', 'Description', '']

function InterfaceIPv6Table({ addresses }: Props) {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    addresses.length && setLoading(false)
  }, [addresses.length])

  console.log('*********** new ADDRESSES =====>>>>>', addresses)

  return (
    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columnsv6.map((column) => (
              <>
                <TableCell
                  sx={{
                    backgroundColor: 'tableHead',
                    color: 'white',
                    '&:first-of-type': {
                      boxShadow: 'unset',
                    },

                    '&:last-of-type': {
                      boxShadow: 'unset',
                    },
                  }}
                  align="left"
                >
                  <Stack direction="row" spacing={1}>
                    <span style={{ cursor: 'pointer' }}>{column}</span>
                  </Stack>
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Box>
                    <Skeleton animation="wave" height={60} />
                    <Skeleton animation="wave" height={60} />
                    <Skeleton animation="wave" height={60} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {addresses && addresses.length ? (
                  addresses.map((addr) => <InterfaceIPv6Row address={addr} />)
                ) : (
                  <TableNoData
                    isNotFound={!loading}
                    message={'No IPv6 Addresses Configured'}
                  />
                )}
              </>
            )}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InterfaceIPv6Table
