import React, { useContext, useState } from 'react'
import { Button, Stack } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { OpenModalProps } from 'src/@types/modalProps'
import { del, get, put } from 'src/utils/httpMethods'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'
import { EndletContext } from './EndletDetails'

interface Props {
  openProps: OpenModalProps
  currentService: any
  selectedEp: Array<string>
  totalEp: number
  setTableData: any
  setSelected: any
}

function DeleteMultiple({
  openProps: { open, setOpen },
  currentService,
  selectedEp,
  totalEp,
  setTableData,
  setSelected,
}: Props) {
  console.log(selectedEp)
  const { getEndlets, endletId } = useContext(EndletContext)!
  const [loading, setLoading] = useState(false)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = () => {
    setLoading(true)

    if (totalEp === selectedEp.length) {
      del(`${process.env.REACT_APP_HOST_API_URL}/services/${currentService.id}`)
        .then((response: any) => {
          setLoading(false)

          setSnackbarProps({
            open: true,
            message: 'vWAN Deleted Successfully!',
            severity: 'success',
          })
          getEndlets()
        })
        .catch((e) => {
          console.log(e)
          setLoading(false)

          setSnackbarProps({
            open: true,
            message: 'Error Deleting vWAN!',
            severity: 'error',
          })
        })
    } else {
      const data = {
        endletIds: selectedEp,
      }
      put(
        `${process.env.REACT_APP_HOST_API_URL}/services/filter/${currentService.id}`,
        data as any
      )
        .then((response: any) => {
          setLoading(false)

          setSnackbarProps({
            open: true,
            message: 'vWAN Deleted Successfully!',
            severity: 'success',
          })
          if (
            selectedEp.includes(endletId) ||
            currentService.subType === 'Point To Point'
          )
            getEndlets()
          else getDetails()
          handleClose()
          setSelected([])
        })
        .catch((e) => {
          console.log(e)
          setLoading(false)

          setSnackbarProps({
            open: true,
            message: 'Error Deleting vWAN!',
            severity: 'error',
          })
          handleClose()
          setSelected([])
        })
    }
  }

  const getDetails = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/services/${currentService.id}`)
      .then((response: any) => {
        console.log(response)
        setTableData(response.associatedEndlets)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }
  return (
    <div>
      {' '}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack justifyContent="center" alignItems={'center'} sx={{ pt: 2 }}>
          <ErrorOutlineIcon
            sx={{ color: 'orange', width: '5rem', height: '5rem' }}
          />
        </Stack>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete the vWAN?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={() => handleDelete()}
            variant="contained"
            color="error"
            loading={loading}
          >
            Yes
          </LoadingButton>
          <Button onClick={handleClose} color="primary" variant="contained">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteMultiple
