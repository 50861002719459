import { forwardRef } from 'react'
// @mui
import {
  Box,
  Tooltip,
  ListItemButtonProps,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
// config
import { ICON } from '../../../config'
//
import { NavItemProps } from '../type'
import Iconify from '../../Iconify'
import { ListItemStyle } from './style'
import Label from 'src/components/Label'
import useAuth from 'src/hooks/useAuth'

// ----------------------------------------------------------------------

type Props = NavItemProps & ListItemButtonProps

const NavItem = forwardRef<HTMLDivElement & HTMLAnchorElement, Props>(
  ({ item, depth, active, open, ...other }, ref) => {
    const { title, icon, info, children, disabled, caption } = item
    const { unreadMessages } = useAuth()

    const renderContent = (
      <ListItemStyle
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        {...other}
        sx={{ ml: 1 }}
      >
        {/* {icon && (
          <ListItemIcon
            sx={{
              mr: 1,
              flexShrink: 0,
              width: ICON.NAVBAR_ITEM_HORIZONTAL,
              height: ICON.NAVBAR_ITEM_HORIZONTAL,
            }}
          >
            {icon}
          </ListItemIcon>
        )} */}

        {icon && title === 'Message center' && unreadMessages ? (
          <>
            <Label
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                left: -10,
              }}
              color="warning"
            >
              {unreadMessages}
            </Label>
            <ListItemIcon
              sx={{
                mr: 1,
                alignItems: 'center',
                flexShrink: 0,
                width: ICON.NAVBAR_ITEM_HORIZONTAL,
                height: ICON.NAVBAR_ITEM_HORIZONTAL,
              }}
            >
              {icon}
            </ListItemIcon>
          </>
        ) : (
          <>
            <ListItemIcon
              sx={{
                mr: 1,
                flexShrink: 0,
                alignItems: 'center',
                width: ICON.NAVBAR_ITEM_HORIZONTAL,
                height: ICON.NAVBAR_ITEM_HORIZONTAL,
              }}
            >
              {icon}
            </ListItemIcon>
          </>
        )}
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
            variant: active ? 'subtitle2' : 'body2',
          }}
        />

        {caption && (
          <Tooltip title={caption} arrow>
            <Box component="span" sx={{ ml: 0.5, lineHeight: 0 }}>
              <Iconify
                icon="eva:info-outline"
                sx={{
                  width: ICON.NAVBAR_ITEM_HORIZONTAL / -4,
                  height: ICON.NAVBAR_ITEM_HORIZONTAL / -4,
                }}
              />
            </Box>
          </Tooltip>
        )}

        {info && (
          <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {!!children && (
          <Iconify
            icon={depth > 1 ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill'}
            sx={{
              ml: 0.5,
              flexShrink: 0,
              width: ICON.NAVBAR_ITEM_HORIZONTAL,
              height: ICON.NAVBAR_ITEM_HORIZONTAL,
            }}
          />
        )}
      </ListItemStyle>
    )

    return renderContent
  }
)

export default NavItem
