import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import useTabs from '../../hooks/useTabs'
import PasswordIcon from '@mui/icons-material/Password'
import KeyIcon from '@mui/icons-material/Key'
import { useNavigate, useLocation } from 'react-router'
import Endlets from './Endlets'
import Services from './Services'
import { ReactComponent as EpIcon } from '../../assets/End-points.svg'
import { ReactComponent as ServiceIcon } from '../../assets/services-icon.svg'
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import { SvgIcon } from '@mui/material'
import CytoscapeTabView from 'src/sections/@dashboard/Services/CytoscapeMapview/CytoscapeTabView'
import { PATH_SERVICES } from 'src/routes/paths'

function EndletMainPage() {
  const { pathname } = useLocation()
  const activeTab = pathname.split('/').slice(-1)
  const { currentTab, onChangeTab, setCurrentTab } = useTabs(pathname)
  const navigate = useNavigate()

  const EPTabs = [
    {
      value: 'endlets',
      icon: <DevicesOutlinedIcon />,
      component: <Endlets />,
      label: 'Endlets',
    },

    {
      value: 'services',
      icon: (
        <SvgIcon>
          <ServiceIcon />
        </SvgIcon>
      ),
      component: <Services />,
      label: 'Services',
    },
    // {
    //   value: 'endletMap',
    //   icon: <LocationOnIcon />,
    //   component: <CytoscapeTabView />,
    //   label: 'Function Map',
    // },
  ]
  useEffect(() => {
    setCurrentTab(activeTab[0])
  }, [activeTab, setCurrentTab])

  return (
    <div>
      {/* <SvgIconStyle
        src={`../../../assets/services-icon.svg`}
        sx={{ width: 1, height: 1 }}
      /> */}
      <Tabs
        sx={{ ml: 3 }}
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {EPTabs.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
            // onClick={() => navigate(`/${tab.value}`)}
            onClick={() =>
              navigate(
                tab.value === 'endlets'
                  ? PATH_SERVICES.root
                  : PATH_SERVICES.endlets.tabs(tab.value)
              )
            }
          />
        ))}
      </Tabs>
      <Box sx={{ mb: 5, overflow: 'auto' }} />

      {EPTabs.map((tab) => {
        const isMatched = tab.value === currentTab
        return isMatched && <Box key={tab.value}>{tab.component}</Box>
      })}
    </div>
  )
}

export default EndletMainPage
