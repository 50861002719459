import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Grid, Snackbar, Stack, Typography } from '@mui/material'
import Page from 'src/components/Page'
import ResellerList from 'src/sections/@dashboard/super-user/Reseller-settings/ResellerList'
import ResellerDetails from 'src/sections/@dashboard/super-user/Reseller-settings/ResellerDetails'
import InviteResellerModal from 'src/sections/@dashboard/super-user/Reseller-settings/InviteResellerModal'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useParams } from 'react-router'
import { Box } from '@mui/material'
import Scrollbar from 'src/components/Scrollbar'
import { Chip } from '@mui/material'
import { ResellersContext } from 'src/contexts/ResellersContext'
import AddResellerFilter from 'src/sections/@dashboard/super-user/Reseller-settings/AddResellerFilter'

export interface ContactPerson {
  name: string
  phone: string
  email: string
}
export interface Reseller {
  name: string
  id?: string
  phone: string
  address: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
  }
  subdomain: string
  logo: string
  email: string
  website: string
  taxId: string
  status: any
  notes?: any[]
  isActive: boolean
}

function ResellerManagement() {
  const [open, setOpen] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { filterItems, setFilterItems } = useContext(ResellersContext)!
  const roles = [
    'Reseller_Admin',
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance'
  ]
  const handleSnackBarClose = () => {
    setShowSnackbar(false)
  }

  const clearFilters = () => {
    localStorage.removeItem('reseller_filters')
    setFilterItems([])
  }

  const handleDelete = (index: number) => {
    setFilterItems(filterItems.filter((items: any, i: number) => i !== index))
  }

  return (
    <>
      <Page title="Manage Reseller">
        {/* <Typography variant="h4" sx={{ my: 2, mx: 4 }}>
        Manage Resellers
      </Typography> */}
        <Stack
          direction="row"
          justifyContent={'space-between'}
          sx={{ my: 0, mx: 4 }}
        >
          <Typography variant="h4">Manage Resellers</Typography>

          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            sx={{ mt: 1 }}
            // startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            Invite Reseller
          </Button>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          sx={{ mb: 1, mx: 4 }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Box sx={{ ml: 'auto', py: 2 }}>
              <Button
                onClick={() => setOpenFilters(true)}
                variant="outlined"
                sx={{ py: 1 }}
                startIcon={<FilterAltIcon />}
              >
                Apply Filter
              </Button>
              <AddResellerFilter
                openProps={{ open: openFilters, setOpen: setOpenFilters }}
              />
            </Box>
            <Scrollbar sx={{ flexGrow: 1 }}>
              <Stack direction="row" spacing={1} sx={{}}>
                {filterItems.map((items: any, index: number) => {
                  return (
                    <Chip
                      label={
                        items.label
                          .split('_')
                          .map(
                            (each: any) =>
                              each.charAt(0).toUpperCase() + each.slice(1)
                          )
                          .join(' ') +
                        ' ' +
                        items.operator.split('_').join(' ') +
                        ' ' +
                        `
                        ${
                          roles.includes(items.value)
                            ? items.value.split('_')[1]
                            : items.value
                        }`
                      }
                      style={{ padding: '20px 0px 20px 0px', fontSize: '15px' }}
                      onDelete={() => handleDelete(index)}
                    />
                  )
                })}
              </Stack>
            </Scrollbar>
            {filterItems.length ? (
              <Box sx={{ ml: 'auto', py: 2 }}>
                <Typography
                  onClick={() => clearFilters()}
                  variant="body2"
                  component={'span'}
                  sx={{ px: 2, pb: 2, cursor: 'pointer', color: 'blue' }}
                >
                  <u>Clear filters</u>
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
        <Grid container>
          <Grid item sm={1} lg={4} sx={{ position: 'relative' }}>
            <ResellerList filterResellers={filterItems} />
          </Grid>
          <Grid item sm={1} lg={8}>
            <ResellerDetails />
          </Grid>
        </Grid>
      </Page>
      <InviteResellerModal openProps={{ open, setOpen }} />
    </>
  )
}

export default ResellerManagement
