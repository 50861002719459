import React, { createContext, useCallback, useEffect, useState } from 'react'
//@mui
import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Step,
  Stepper,
  StepLabel,
  Skeleton,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

//custom
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { get } from 'src/utils/httpMethods'
import { useNavigate, useParams, useLocation } from 'react-router'
import useTabs from '../../hooks/useTabs'
import { Stack } from '@mui/system'
import { makeStyles } from '@mui/styles'
import StatusStepper from './StatusStepper'
import ProvDtServices from './ProvDtServices'
import ProvDtConfiguration from './ProvDtConfiguration'
import DetailEpTab from 'src/sections/@dashboard/Services/DetailEpTab'
import { SvgIcon } from '@mui/material'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import DescriptionIcon from '@mui/icons-material/Description'
import { ReactComponent as ServiceIcon } from '../../assets/services-icon.svg'

export const ProvEndletContext = createContext<{
  endlet: any
  endletId: string
  getEndlets: () => Promise<void>
} | null>(null)

function ProvisioningDetails() {
  const { id } = useParams()
  const [endlet, setEndlet] = useState<any>({})
  // const [currentTab, setCurrentTab] = useState('Configuration')
  const [isEdit, setIsEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const [fetchEndlet, setFetchEndlet] = useState<boolean>(false)
  const [currentState, setCurrentState] = useState<any>({})
  const [currentIndex, setCurrentIndex] = useState(0)
  //stepper
  const [contentLoading, setContentLoading] = useState(true)
  const { pathname } = useLocation()
  const activeTab = pathname.split('/').slice(-1)
  const { currentTab, onChangeTab, setCurrentTab } = useTabs(activeTab[0])

  const STATUS_OPTIONS = [
    'Endlet Configured',
    'SSH Key Validated',
    'O/S Validated',
    'Endlet Deployed',
  ]
  useEffect(() => {
    setCurrentTab(activeTab[0])
  }, [activeTab, setCurrentTab])

  const navigate = useNavigate()

  const getEndlets = useCallback(async () => {
    setContentLoading(true)
    get(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, {})
      .then((response: any) => {
        setEndlet(response)
        setCurrentState(
          response.currentProgress.find(
            (status: any) => status.deploymentStatus === response.deploymentStatus
          )
        )
        setCurrentIndex(STATUS_OPTIONS.indexOf(response.deploymentStatus))
        setContentLoading(false)
      })
      .catch((e) => {
        setContentLoading(false)
      })
  }, [])
  const TAB_OPTIONS = [
    {
      value: 'configuration',
      icon: <SettingsSuggestIcon width={20} height={20} />,
      component: <ProvDtConfiguration />,
      label: 'Configuration',
    },

    {
      value: 'detail',
      icon: <DescriptionIcon width={20} height={20} />,
      component: <DetailEpTab endlet={endlet} />,
      label: 'Detail',
    },

    {
      value: 'services',
      icon: (
        <SvgIcon>
          <ServiceIcon />
        </SvgIcon>
      ),
      component: <ProvDtServices endlet={endlet} id={id!} getEndlets={getEndlets} />,
      label: 'Services',
    },
  ]

  useEffect(() => {
    getEndlets()
  }, [fetchEndlet])

  const useStyles: any = makeStyles(() => ({
    root: {
      '& .MuiStepIcon-root.Mui-active': { color: 'orange' },
      '& .MuiStepIcon-root.Mui-completed': { color: 'green' },
      '& .MuiStepIcon-root.Mui-error': { color: 'red' },
    },
  }))
  const c = useStyles()

  return (
    <ProvEndletContext.Provider
      value={{
        endlet: endlet,
        endletId: id!,
        getEndlets: getEndlets,
      }}
    >
      <Stack direction="row" sx={{ m: 2, justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          component={'span'}
          sx={{
            m: 2,
            color: 'text.secondary',
          }}
          onClick={() => {
            setIsEdit(!isEdit)
          }}
        >
          {endlet.endletName}
        </Typography>

        <Typography
          component={'span'}
          sx={{
            m: 2,
            cursor: 'pointer',
            color: 'text.secondary',
          }}
          onClick={() => {
            navigate('/super-user/provisioning')
          }}
        >
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          View All Endlets
        </Typography>
      </Stack>

      <Card sx={{ m: 3, p: 2 }}>
        <Typography variant="h6" mt={1}>
          Status
        </Typography>

        {contentLoading ? (
          <Box sx={{ ml: 2, mr: 2 }}>
            <Box>
              <Skeleton animation="wave" height={150} />
            </Box>
          </Box>
        ) : (
          <>
            <Stepper
              activeStep={
                currentState && currentState?.issue.isResolved
                  ? currentIndex + 1
                  : Object.keys(endlet).length && currentIndex
              }
              alternativeLabel
              sx={{ pb: 3 }}
              className={c.root}
            >
              {STATUS_OPTIONS.map((label, index) => {
                console.log(
                  Object.keys(endlet).length &&
                    (index < endlet?.currentProgress?.length - 1 ||
                      endlet?.currentProgress[index]?.issue.isResolved)
                )
                const labelProps: any = {}
                const x = label.split(' ').join('_')
                if (
                  Object.keys(endlet).length &&
                  endlet?.currentProgress[index]?.issue.isResolved === false
                ) {
                  labelProps.error = true
                }

                return (
                  <Step key={label}>
                    <Tooltip
                      title={
                        Object.keys(endlet).length &&
                        (index < currentIndex ||
                        (index === currentIndex &&
                          endlet?.currentProgress[index]?.issue.isResolved)
                          ? endlet?.currentProgress[index]?.successMessage ||
                            'Completed'
                          : (index > currentIndex &&
                              currentState?.issue.isResolved === false) ||
                            (index > currentIndex + 1 &&
                              currentState?.issue.isResolved)
                          ? 'Not completed'
                          : endlet?.currentProgress[index]?.issue.isResolved ===
                            false
                          ? currentState?.issue.message
                          : 'In Progress')
                      }
                    >
                      <StepLabel
                        {...labelProps}
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Tooltip>
                  </Step>
                )
              })}
            </Stepper>

            <Box sx={{ justifyContent: 'flex-end', display: 'flex', m: 2 }}>
              <Button
                variant="contained"
                sx={{ mt: 1 }}
                onClick={() => setOpen(true)}
              >
                Update Status
              </Button>
            </Box>
          </>
        )}
      </Card>

      <Stack
        direction={'row'}
        justifyContent="flex-end"
        sx={{ width: '100%', pr: 3 }}
      >
        <Button color="error" variant="outlined" sx={{ mb: 2 }}>
          Delete Endlet
        </Button>
      </Stack>

      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
        sx={{ ml: 3 }}
      >
        {TAB_OPTIONS.map((tab) => (
          <Tab
            disableRipple
            key={tab.label}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
            onClick={() => navigate(`/super-user/provisioning/${id}/${tab.value}`)}
          />
        ))}
      </Tabs>
      <Card sx={{ p: 2, m: 3 }}>
        <>
          {TAB_OPTIONS.map((tab) => {
            const isMatched = tab.value === currentTab
            return isMatched && !contentLoading ? (
              <Box key={tab.value}>{tab.component}</Box>
            ) : isMatched && contentLoading ? (
              <>
                <Box sx={{ m: 2 }}>
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                </Box>
              </>
            ) : (
              ''
            )
          })}
        </>
      </Card>

      <StatusStepper
        openProps={{ open, setOpen }}
        fetchEndletProps={{ fetchEndlet, setFetchEndlet }}
        id={id!}
        currentState={currentState}
        currentIndex={currentIndex}
      />
    </ProvEndletContext.Provider>
  )
}

export default ProvisioningDetails
