import { useContext, useEffect, useState } from 'react'
// @mui

import { PATH_USER } from '../../../routes/paths'

import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  ListItemIcon,
} from '@mui/material'
// components
import MenuPopover from '../../../components/MenuPopover'
import { IconButtonAnimate } from '../../../components/animate'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LockResetIcon from '@mui/icons-material/LockReset'
import BusinessIcon from '@mui/icons-material/Business'
import LogoutIcon from '@mui/icons-material/Logout'
import useAuth from 'src/hooks/useAuth'
import { MessageContext } from 'src/contexts/MessageContext'
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'My Profile',
    linkTo: PATH_USER.profile,
    icon: <AccountCircleIcon />,
  },
  {
    label: 'Login And Security',
    linkTo: PATH_USER.password,
    icon: <LockResetIcon />,
  },
  // {
  //   label: 'Company Settings',
  //   linkTo: PATH_USER.admin,
  //   icon: <BusinessIcon />,
  // },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null)
  const [showOptions, setShowOptions] = useState([...MENU_OPTIONS])
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const { logout, user, organization } = useAuth()
  useEffect(() => {
    setShowOptions(() =>
      user?.role === 'Customer_Admin'
        ? [...MENU_OPTIONS]
        : [...MENU_OPTIONS.filter((option) => option.label !== 'Company Settings')]
    )
  }, [user])

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('organization', JSON.stringify(organization))
  }, [user, organization])

  const handleClose = () => {
    setOpen(null)
  }

  const { setUnread } = useContext(MessageContext)!

  useEffect(() => {
    setUnread(localStorage.getItem('unread')!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          // ...(open && {
          //   "&:before": {
          //     zIndex: 1,
          //     content: "''",
          //     width: "100%",
          //     height: "100%",
          //     borderRadius: "50%",
          //     position: "absolute",
          //     bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
          //   },
          // }),
        }}
      >
        <Avatar src={user?.avatar} alt={user?.name} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          mx: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {showOptions.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              <ListItemIcon sx={{ color: 'text.secondary', m: 0 }}>
                {option.icon}
              </ListItemIcon>
              <Typography noWrap variant="body2">
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          sx={{ m: 1 }}
          onClick={async () => {
            await logout()
            setUnread('')
            localStorage.removeItem('selecetedTenant')
            localStorage.clear()
          }}
        >
          <ListItemIcon sx={{ color: 'text.secondary', m: 0 }}>
            <LogoutIcon />
          </ListItemIcon>
          <Typography noWrap variant="body2">
            Logout
          </Typography>
        </MenuItem>
      </MenuPopover>
    </>
  )
}
