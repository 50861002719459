/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Autocomplete,
  Box,
  Card,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Snackbar,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import useAuth from 'src/hooks/useAuth'
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form'
import { useForm } from 'react-hook-form'
import useSettings from 'src/hooks/useSettings'
import Page from 'src/components/Page'
import { get, put } from 'src/utils/httpMethods'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { LoadingButton } from '@mui/lab'
import { COUNTRIES } from 'src/sections/@dashboard/Services(Endlets)/CreateEndletModal'
import { Stack } from '@mui/material'

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { fireBaseApp } from 'src/utils/firebase'
import { useUploadFile } from 'react-firebase-hooks/storage'
import { getFirebaseFile } from 'src/utils/getFirebaseFile'
import { CircularProgress } from '@mui/material'
import { Button } from '@mui/material'
import { CustomFile } from 'src/components/upload'
import { fData } from 'src/utils/formatNumber'
import ImageCropOverlay from './ImageCropOverlay'

type FormValuesProps = {
  logo_url_light: CustomFile | string | null
  logo_url_dark: CustomFile | string | null
  name: string
  phone: string
  email: string
  address: {
    country: string
    line1: string
    line2: string
    city: string
    state: string
    zip: string
  }
  website: string
  taxId: string
  light_logo: string | null
  dark_logo: string | null
  subdomain: string
}

const storage = getStorage(fireBaseApp)

function CompanySettings() {
  const { organization, UseNewUserData, user, logout } = useAuth()
  const { refetchUserData } = UseNewUserData()
  const [alert, setAlert] = useState({ show: false } as any)
  const navigate = useNavigate()
  const [prevDomain, setPrevDomain] = useState('')
  const [accessTier, setAccessTier] = useState(organization?.accessTire)
  const [imageCropOverlay, setImageCropOverlay] = useState(false)
  const [crop, setCrop] = useState<any>()
  const [OriginalImage, setOriginalImage] = useState<any>()
  const [logoType, setLogoType] = useState<string>('')
  const [imagePreview, setImagePreview] = useState<any>()
  const [imagePreviewLight, setImagePreviewLight] = useState('')
  const [imagePreviewDark, setImagePreviewDark] = useState('')
  const fixSubdomain = '.21packets.com'
  const logoTypes = ['Light', 'Dark']
  console.log(organization && organization.address.country)
  const addressFields = [
    {
      label: 'Country',
      name: 'country',
      value: organization && organization.address.country,
    },
    {
      label: 'Address 1',
      name: 'line1',
      value: organization && organization.address.line1,
    },
    {
      label: 'Address 2',
      name: 'line2',
      value: organization && organization.address.line2,
    },
    {
      label: 'City',
      name: 'city',
      value: organization && organization.address.city,
    },

    {
      label: 'State',
      name: 'state',
      value: organization && organization.address.state,
    },
    {
      label: 'ZIP code',
      name: 'zip',
      value: organization && organization.address.zip,
    },
  ]
  const others = [
    {
      label: 'Website',
      name: 'website',

      value: organization && organization.website,
    },
    {
      name: 'taxId',

      label: 'Tax Id',
      value: organization && organization.taxId,
    },
    {
      name: 'subdomain',
      label: 'Subdomain',
      value: organization && organization.subdomain!,
    },
  ]
  const fields = [
    {
      label: 'Name',
      name: 'name',
      value: organization && organization.name,
    },
    {
      label: 'E-mail',
      name: 'email',
      value: organization && organization.email,
    },
    {
      label: 'Phone',
      name: 'phone',
      value: organization && organization.phone,
    },
  ]

  const defaultValues = {
    name: (organization && organization.name) || '',
    phone: (organization && organization.phone) || '',
    email: (organization && organization.email) || '',
    address: {
      country: (organization && organization.address.country) || '',
      line1: (organization && organization.address.line1) || '',
      line2: (organization && organization.address.line2) || '',
      city: (organization && organization.address.city) || '',
      state: (organization && organization.address.state) || '',
      zip: (organization && organization.address.zip) || '',
    },
    website: (organization && organization.website) || '',
    taxId: (organization && organization.taxId) || '',
    logo_url_light: (organization && organization.light_logo) || '',
    logo_url_dark: (organization && organization.dark_logo) || '',
    subdomain: (organization && organization.subdomain) || '',
  }

  const SendMessageSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z ]+$/, 'Special Charecters not allowed in Company Name')
      .required('Name is required'),
    phone: Yup.string().trim().required('Phone is required'),
    email: Yup.string().trim().email().required('Email is required'),
    address: Yup.object().shape({
      country: Yup.string().trim().required('Country is required'),
      line1: Yup.string().trim().required('Line1 is required'),
      line2: Yup.string().trim().required('Line2 is required'),
      city: Yup.string().trim().required('City is required'),
      state: Yup.string().trim().required('State is required'),
      zip: Yup.string().trim().required('ZIP is required'),
    }),
    website: Yup.string().trim().required('Website is required'),
    taxId: Yup.string().trim().required('TaxId is required'),
    subdomain: Yup.string(),
    logo: Yup.string(),
  })
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SendMessageSchema),
    defaultValues,
  })
  useEffect(() => {
    if (user?.role !== 'Customer_Admin' && user?.role !== 'Reseller_Admin')
      navigate(PATH_DASHBOARD.root)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateOrg = async (data: any) => {
    const finalData = {
      ...data,
      subdomain:
        organization?.accessTier === 'Reseller' && data.subdomain.length
          ? data.subdomain + fixSubdomain
          : '',
    }
    try {
      await put(`${process.env.REACT_APP_HOST_API_URL}/auth/organizations/self`, {
        ...finalData,
      })
      refetchUserData()

      setAlert({
        show: true,
        severity: 'success',
        message: 'Changes saved successfully',
      })
      if (organization?.accessTier === 'Reseller' && prevDomain !== subdomain) {
        logout()
      }
      setImageProgress(false)
    } catch (error) {
      console.error(error)
      setAlert({
        show: true,
        severity: 'error',
        message: error,
      })
    }
  }

  const onSubmit = async (data: FormValuesProps) => {
    console.log(organization?.accessTier)
    await updateOrg(data)
  }
  const handleClose = () => {
    setAlert({ show: false })
  }
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods

  const { subdomain, address, logo_url_light } = watch()
  const { themeStretch } = useSettings()
  console.log(subdomain)
  useEffect(() => {
    setValue(
      'subdomain',
      subdomain
        ? subdomain.replace(fixSubdomain, '')
        : organization?.name.toLowerCase().replace(' ', '-')
    )
    setPrevDomain(subdomain.replace(fixSubdomain, ''))
  }, [])

  const [query, setQuery] = useState(
    (organization && organization.address.line1) || ''
  )
  const [countryLookup, setCountryLookup] = useState<string>(
    (organization && COUNTRIES[organization.address.country]?.toString()) ?? ''
  )

  const [suggestions, setSuggestions] = useState<any>([])

  const [selectedLocation, setSelectedLocation] = useState<any>()
  const [isAddressVerified, setIsAddressVerified] = useState(false)

  //to reset address fileds on country change
  const defaultAddess = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  }

  // to determine verified address or not
  const verifyObj = {
    line1: address.line1,
    locality: address.city,
    state: address.state,
    zip: address.zip,
  }

  useEffect(() => {
    setIsAddressVerified(
      selectedLocation
        ? verifyObj.line1 === selectedLocation.addr1 &&
          verifyObj.locality === selectedLocation.locality &&
          verifyObj.state === selectedLocation.state &&
          verifyObj.zip === selectedLocation.zip
          ? true
          : false
        : false
    )
  }, [selectedLocation, verifyObj])

  // address lookup

  useEffect(() => {
    console.log(query.length)
    if (!countryLookup.length || !query.length) return
    if (countryLookup === 'USA') {
      get(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=147123342709467494&search=${query}`
      )
        .then((res: any) => {
          setSuggestions(res.suggestions)
        })
        .catch((e) => console.log(e))
    } else {
      get(
        `https://international-autocomplete.api.smartystreets.com/lookup?key=147123342709467494&country=${countryLookup}&search=${query}`
      )
        .then((res: any) => {
          setSuggestions(res.candidates)
        })
        .catch((e) => console.log(e))
    }
  }, [countryLookup, query])

  useEffect(() => {
    const [collection, file] = getFirebaseFile(organization?.logo)
    setFirebaseURLfile([collection, file])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const [imageProgress, setImageProgress] = useState(false)
  const storageRef = ref(storage, 'reseller_logos/')
  const [uploadFile] = useUploadFile()
  const [showLoader, setShowLoader] = useState(false)
  const [firebaseURLfile, setFirebaseURLfile] = useState(['', ''])

  const upload = async (file: any) => {
    setImageProgress(true)
    if (file) {
      const fileName = file.name
      const imgRef = ref(storageRef, fileName)
      const uploadTask = uploadBytesResumable(imgRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
            default:
              console.log('first')
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break

            default:
              console.log(error.code)
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const payload =
              logoType === 'Light'
                ? { light_logo: downloadURL }
                : { dark_logo: downloadURL }
            updateOrg(payload)
          })
        }
      )

      await uploadFile(imgRef, file, {
        contentType: 'image/jpeg',
      })
    }
    setImageProgress(false)
  }

  // firebase file delete
  const deletePFP = async (type: string) => {
    const deleteRef = ref(storageRef, firebaseURLfile[1])
    try {
      setImageProgress(true)
      const updatePayload = type === 'Light' ? { light_logo: '' } : { dark_logo: '' }
      await updateOrg(updatePayload)
      await deleteObject(deleteRef)
      refetchUserData()
    } catch (e) {
      console.log(e)
    }
  }

  const handleDrop = useCallback(
    (acceptedFiles: File[], type: string) => {
      const file = acceptedFiles[0]
      console.log(file)
      if (!file) return
      setCrop(undefined) // Makes crop preview update between images.
      setOriginalImage(file)
      setLogoType(type)
      setImageCropOverlay(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  )
  useEffect(() => {
    if (imagePreview) {
      const logoFieldName = logoType === 'Light' ? 'logo_url_light' : 'logo_url_dark'
      setValue(
        logoFieldName,
        Object.assign(imagePreview, {
          preview: URL.createObjectURL(imagePreview),
        })
      )
      upload(imagePreview)
    }
  }, [imagePreview])

  return (
    <Page title="Company Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h4" sx={{ my: 0 }}>
            Company Settings
          </Typography>
        </Box>
        <Card sx={{ p: 3 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                GENERAL
              </Grid>
              {fields.map((field) => (
                <Grid item xs={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {field.label}
                  </Typography>
                  <RHFTextField name={field.name}></RHFTextField>
                </Grid>
              ))}
              <Divider
                sx={{ width: '100%', borderColor: 'darkgray', mt: 3, mb: 1 }}
              />
              <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                ADDRESS
              </Grid>
              {addressFields.map((field) => (
                <>
                  {field.name === 'country' ? (
                    <Grid item xs={12}>
                      <>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          {field.label}
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={Object.keys(COUNTRIES).map((obj: string) => obj)}
                          // sx={{ width: 300 }}

                          value={address.country}
                          onChange={(e, value) => {
                            console.log(value)
                            setQuery('')
                            setValue(
                              'address',
                              value ? { ...address, country: value } : { ...address }
                            )
                            setCountryLookup(
                              value ? COUNTRIES[value].toString() : ''
                            )
                          }}
                          renderInput={(params) => (
                            <RHFTextField
                              {...params}
                              name="country"
                              sx={{ width: '49%' }}
                            />
                          )}
                        />
                      </>
                    </Grid>
                  ) : field.name === 'line1' ? (
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        {field.label}
                      </Typography>
                      <Autocomplete
                        disablePortal
                        value={address.line1}
                        id="combo-box-demo"
                        options={
                          countryLookup === 'USA'
                            ? suggestions
                              ? [
                                  ...suggestions.map((addr: any) => ({
                                    label:
                                      addr.street_line +
                                      ', ' +
                                      addr.secondary +
                                      ', ' +
                                      addr.city,
                                    value: addr.street_line,
                                    addr1: addr.street_line,
                                    locality: addr.city,
                                    zip: addr.zipcode,
                                    state: addr.state,
                                  })),
                                ]
                              : [
                                  {
                                    label: 'Start typing to get suggestions',
                                    value: '',
                                  },
                                ]
                            : suggestions
                            ? [
                                ...suggestions.map((addr: any) => ({
                                  label: addr.street + ', ' + addr.locality,
                                  value: addr.street,
                                  addr1: addr.street,
                                  locality: addr.locality,
                                  zip: addr.postal_code,
                                  state: addr.administrative_area,
                                })),
                              ]
                            : [
                                {
                                  label: 'Start typing to get suggestions',
                                  value: '',
                                },
                              ]
                        }
                        // sx={{ width: 300 }}
                        onChange={(e, value: any) => {
                          setQuery('')
                          console.log(suggestions)
                          console.log('=========>ADDRESS=========>', value)
                          setSelectedLocation({ ...value })
                          if (value) {
                            setValue('address.line1', value.addr1)
                            setValue('address.city', value.locality)
                            setValue('address.zip', value.zip)
                            setValue('address.state', value.state)
                          }
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            {...params}
                            fullWidth
                            placeholder="Start typing to get address suggestions"
                            name="address1"
                            onChange={(e) => {
                              console.log(e.target.value)
                              // setValue('address1', e.target.value)
                              setQuery(e.target.value)
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          {field.label}
                        </Typography>
                        <RHFTextField name={`address.${field.name}`}></RHFTextField>
                      </>
                    </Grid>
                  )}
                </>
              ))}
              <Grid item xs={12}>
                {isAddressVerified && (
                  <Stack
                    direction={'row'}
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Typography variant="subtitle2">Address is verified</Typography>
                    <CheckCircleIcon sx={{ color: 'green' }} />
                  </Stack>
                )}
              </Grid>

              <Divider
                sx={{ width: '100%', borderColor: 'darkgray', mt: 3, mb: 1 }}
              />

              <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                OTHER
              </Grid>
              {others
                .filter((field) =>
                  user?.role === 'Reseller_Admin'
                    ? field
                    : field.name !== 'subdomain'
                )
                .map((field) => (
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {field.label}
                    </Typography>
                    {field.name !== 'subdomain' ? (
                      <RHFTextField name={field.name}></RHFTextField>
                    ) : (
                      <RHFTextField
                        name={field.name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {fixSubdomain}
                            </InputAdornment>
                          ),
                        }}
                      ></RHFTextField>
                    )}
                  </Grid>
                ))}
            </Grid>
            <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>
              Company Logo
            </Typography>
            <Card
              sx={{
                py: 5,
                px: 3,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {logoTypes.map((type) => (
                <div>
                  <RHFUploadAvatar
                    sx={{
                      ':hover': {
                        cursor: 'pointer',
                      },
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                    name={type === 'Light' ? 'logo_url_light' : 'logo_url_dark'}
                    maxSize={3145728}
                    onDrop={(e) => {
                      handleDrop(e, type)
                    }}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Format * jpeg, *jpg, *png, *gif. Max size of {fData(3145728)}
                      </Typography>
                    }
                    logoInfo={{
                      isOrgLogo: true,
                      showDarkBack: type === 'Light' && !!logo_url_light,
                    }}
                  />
                  <br />
                  <div
                    style={{
                      visibility: `${
                        imageProgress && type === logoType ? 'visible' : 'hidden'
                      }`,
                    }}
                  >
                    <CircularProgress />
                  </div>
                  <Box
                    style={{ display: 'flex', flexDirection: 'column' }}
                    sx={{ typography: 'body2' }}
                  >
                    <div>{type} Logo</div>
                    <div>{`This logo will be displayed on ${
                      type === 'Light' ? 'dark' : 'light'
                    } backgrounds`}</div>
                  </Box>
                  <Button
                    disabled={!user?.avatar}
                    onClick={() => {
                      deletePFP(type)
                    }}
                  >
                    Remove Logo
                  </Button>
                </div>
              ))}
            </Card>

            <Box sx={{ margin: 'auto' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ float: 'right', m: 2 }}
                loading={isSubmitting}
              >
                <span>Update</span>
              </LoadingButton>
            </Box>
          </FormProvider>
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={alert.show}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Container>
      <ImageCropOverlay
        modalProps={{ open: imageCropOverlay, setOpen: setImageCropOverlay }}
        OriginalImage={OriginalImage}
        crop={crop}
        setCrop={setCrop}
        setPreviewImage={setImagePreview}
        isLightLogo={logoType === 'Light'}
      />
    </Page>
  )
}

export default CompanySettings
