import React, { useState, useContext, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { post, get } from 'src/utils/httpMethods'
import { OpenModalProps } from 'src/@types/modalProps'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

interface Props {
  openProps: OpenModalProps
  getExistingVWan: any
  id: string
  serviceTp: string
}

type FormValuesProps = {
  name: string
  subType: string
  tags: string[]
  afterSubmit?: string
}
const vWANSchema = Yup.object().shape({
  name: Yup.string().required('vWan name  is required'),
  subType: Yup.string().required('vWan type is required'),
  tags: Yup.array().of(Yup.string()),
})

function AddNewVwan({
  openProps: { open, setOpen },
  getExistingVWan,
  id,
  serviceTp,
}: Props) {
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const VWAN_TYPES = ['Point To Point', 'Full Mesh', 'Hub And Spoke']
  const [loading, setLoading] = useState(false)
  const [tagsList, setTagsList] = useState<any>()

  const defaultValues = {
    name: '',
    subType: '',
    tags: [],
  }
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(vWANSchema),
    defaultValues,
  })
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods
  const { tags } = watch()
  const fetchTags = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/tags?tagType=Service`)
      .then((res: any) => {
        setTagsList(res)
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    fetchTags()
  }, [])
  const onSubmit = async (data: any) => {
    setLoading(true)
    const finalData = {
      service: {
        ...data,
        endletId: id,
        type: serviceTp,
      },
    }
    post(`${process.env.REACT_APP_HOST_API_URL}/services`, finalData)
      .then((response: any) => {
        getExistingVWan()
        setLoading(false)
        handleClose()
        setSnackbarProps({
          open: true,
          message: 'New vWAN added successfully!',
          severity: 'success',
        })
      })
      .catch((e) => {
        setSnackbarProps({
          open: true,
          message: e.message,
          severity: 'error',
        })
        setLoading(false)
        handleClose()
      })
  }
  const handleClose = () => {
    setOpen(false)
    reset()
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll="body">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ p: 2, width: 500 }}>
            <Typography variant="h6" sx={{ my: 1 }}>
              Add New vWAN
            </Typography>

            <Stack spacing={2} sx={{ mt: 1 }}>
              <RHFTextField
                autoComplete="off"
                name="name"
                label="vWAN Name"
                sx={{ mt: 2 }}
              ></RHFTextField>
            </Stack>
            <RHFSelect
              sx={{ mt: 2 }}
              name="subType"
              label="vWAN Type"
              placeholder="vWAN Type"
            >
              <option value=""></option>
              {VWAN_TYPES.map((tp, i) => {
                return (
                  <option value={tp} key={i}>
                    {tp}
                  </option>
                )
              })}
            </RHFSelect>
            <Stack mt={2}>
              <Typography variant="caption" sx={{ mt: 0 }} color={'gray'}>
                Create a New Tag Below or Select From an Existing Tag
              </Typography>
            </Stack>
            <Stack>
              <Autocomplete
                sx={{ flexGrow: 1 }}
                multiple
                filterSelectedOptions
                id="tags-filled"
                noOptionsText={
                  'No tags found for your organization. Type and press enter to add a new tag'
                }
                options={
                  tagsList && tagsList.length
                    ? tagsList.map((option: any) => option.name)
                    : []
                }
                freeSolo
                value={tags ? [...tags] : ['']}
                renderTags={(value: readonly string[], getTagProps) => {
                  return value
                    .filter((option: string) => option !== '')
                    .map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                }}
                onChange={(e, value) => {
                  setValue('tags', [
                    ...value.filter((tag) => tag.trim().length !== 0),
                  ])
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`Type Tags and Hit “Enter"`}
                    name="tags"
                    sx={{ maxWidth: 850 }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
                float: 'right',
                px: 0,
                my: 2,
              }}
              spacing={1}
            >
              <Button
                onClick={handleClose}
                variant="text"
                type="button"
                sx={{ color: 'text.secondary' }}
              >
                Cancel
              </Button>

              <LoadingButton variant="contained" type="submit" loading={loading}>
                Save
              </LoadingButton>
            </Stack>
          </Box>
        </FormProvider>
      </Dialog>
    </div>
  )
}

export default AddNewVwan
