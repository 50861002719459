import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form'
import { OpenModalProps } from 'src/@types/modalProps'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

interface Props {
  openProps: OpenModalProps
}
type FormValuesProps = {
  token: string
  access_level: string
}

const tokenSchema = Yup.object().shape({
  token: Yup.string()
    .required('Token is required')
    .matches(
      /^Bearer [A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+$/,
      'Invalid Token'
    ),
  access_level: Yup.string().required('Access level is required'),
})
const roles = [
  { code: 'engineer', label: 'Engineer' },
  { code: 'support', label: 'Support' },
  { code: 'finance', label: 'Finance' },
]
function AddNewToken({ openProps }: Props) {
  const { open, setOpen } = openProps
  const [loading, setLoading] = useState(false)
  const defaultValues = {
    token: '',
    access_level: '',
  }
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(tokenSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    console.log(data)
  }
  const handleClose = () => {
    setOpen(false)
    reset()
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Typography variant="h4" sx={{ px: 2, pt: 3 }}>
        Add New Token
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box
            sx={{
              width: 500,
            }}
          >
            <RHFTextField
              autoComplete="off"
              name="token"
              multiline
              minRows={5}
              maxRows={8}
              label="Enter New Token"
              sx={{ mb: 2 }}
            ></RHFTextField>
            <RHFSelect
              sx={{ mt: '10px' }}
              name="access_level"
              label="Access Level"
              placeholder="Access Level"
            >
              <option value="" />
              {roles.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.label}
                </option>
              ))}
            </RHFSelect>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
            type="button"
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" loading={loading}>
            Add
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export default AddNewToken
