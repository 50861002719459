import React, { Fragment, useContext, useRef, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { OpenModalProps } from 'src/@types/modalProps'
import { Button, Dialog, DialogContent } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { TopLevelmodalContext } from 'src/contexts/ModalContext'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

function AlertMFASettings() {
  const {
    alertMFAModalProps: { show },
    setAlertMFAModalProps,
  } = useContext(TopLevelmodalContext)!
  const { logout } = useAuth()
  const navigate = useNavigate()
  const cancelButtonRef = useRef(null)

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == 'backdropClick' && 'escapeKeyDown') return
    setAlertMFAModalProps((prev) => ({
      ...prev,
      show: false,
    }))
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        scroll="body"
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogContent>
          <Box>
            <Box>
              <DialogTitle
                sx={{
                  padding: 0,
                  marginBottom: '12px',
                  fontSize: '18px',
                  fontWeight: 700,
                  color: '#111827',
                }}
              >
                Multi-Factor Authentication (MFA) Required
              </DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  //   marginTop: '10px',
                  flexDirection: 'column',
                  //   gap: '16px',
                  fontSize: '14px',
                  color: '#6B7280',
                }}
              >
                <Typography className="">
                  Multi-Factor Authentication (MFA) is required to be enabled for all
                  user accounts in order to utilize this portal. We currently support
                  Google Authenticator which can be enabled from the users My Profile{' '}
                  {`>>`} Login And Security and then enable Multi-Factor
                  Authentication.
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                marginTop: '36px',
                marginLeft: '8px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="button"
                onClick={() => logout()}
                sx={{
                  color: 'info',
                  backgroundColor: 'secondary',
                  mr: '10px',
                }}
              >
                Logout
              </Button>
              <LoadingButton
                onClick={() => {
                  handleClose('click', 'navigate')
                  navigate('/user/change-password')
                }}
                variant="contained"
                type="button"
                // loading={isConfirming}
              >
                Setup MFA
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AlertMFASettings
