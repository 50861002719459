import React, { useContext, useEffect, useState } from 'react'
import { Delete, Save } from '@mui/icons-material'
import {
  CircularProgress,
  Fab,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  useTheme,
} from '@mui/material'
import EditableTableCell from './EditableTableCell'
import { IPAddress } from '../ConfigureEndletModal'
import { ConfigureInterfaceContext } from './UpdateInterfaceForm'

interface Props {
  address: IPAddress
}

function InterfaceIPv4Row({ address }: Props) {
  const { palette } = useTheme()
  const [rowEdit, setRowEdit] = useState(false)
  const [rowIPobject, setRowIPobject] = useState({ ...address })
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  console.log('=====> row edit =======>', rowEdit)

  const { IP, subnet, description } = rowIPobject

  useEffect(() => {
    setRowIPobject({ ...address })
  }, [address])

  // import API methods from toplevel context
  const {
    addInterfaceIP,
    updateInterfaceIP,
    deleteInterfaceIP,
    userMethod,
    softDeleteV4,
    deleteDisabled,
    setDeleteDisabled,
    tableDisabled,
  } = useContext(ConfigureInterfaceContext)!

  console.log(userMethod)

  const [errors, setErrors] = useState<{
    IP: string | null
    subnet: string | null
  }>({ IP: null, subnet: null })

  const handleSubmit = async () => {
    setLoading(true)
    setDeleteDisabled(true)
    setRowEdit(false)

    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

    const subnetRegex =
      /^(255)\.(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255)/g

    const errorsLocal = {
      IP: ipRegex.test(rowIPobject.IP) ? null : 'Inavlid IP address',
      subnet: subnetRegex.test(rowIPobject.subnet) ? null : 'Inavlid Subnet',
    }
    setErrors({ ...errorsLocal })

    if (!errorsLocal.IP && !errorsLocal.subnet) {
      userMethod === 'added'
        ? await addInterfaceIP(rowIPobject)
        : await updateInterfaceIP(rowIPobject, address)
    }
    setLoading(false)
    setDeleteDisabled(false)
  }
  // console.log(address)
  return (
    <TableRow>
      <EditableTableCell
        rowProps={{ rowEdit, setRowEdit, rowIPobject, setRowIPobject }}
        text={IP}
        cellId="IP"
        error={errors.IP}
      />

      <EditableTableCell
        rowProps={{ rowEdit, setRowEdit, rowIPobject, setRowIPobject }}
        text={subnet}
        cellId="subnet"
        error={errors.subnet}
      />

      <EditableTableCell
        rowProps={{ rowEdit, setRowEdit, rowIPobject, setRowIPobject }}
        text={description}
        cellId="description"
        error={null}
      />

      <TableCell>
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          {loading ? (
            <CircularProgress size={25} sx={{ mx: 0.5 }} />
          ) : (
            <IconButton
              onClick={handleSubmit}
              size="small"
              disabled={!rowEdit}
              hidden={tableDisabled}
              sx={{
                '&:hover': {
                  '& .MuiSvgIcon-root': {
                    color: palette.primary.main,
                  },
                },
              }}
            >
              <Save />
            </IconButton>
          )}
          {deleteLoading ? (
            <CircularProgress size={25} sx={{ mx: 0.5 }} />
          ) : (
            <IconButton
              size="small"
              color="default"
              disabled={deleteDisabled}
              hidden={tableDisabled}
              sx={{
                '&:hover': {
                  '& .MuiSvgIcon-root': {
                    color: palette.error.main,
                  },
                },
              }}
              onClick={async () => {
                if (!address.IP.length || !address.subnet.length) {
                  softDeleteV4(address)
                } else {
                  setDeleteLoading(true)
                  setDeleteDisabled(true)

                  await deleteInterfaceIP(address)
                  setDeleteLoading(false)
                  setDeleteDisabled(false)
                }
              }}
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default InterfaceIPv4Row
