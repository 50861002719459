import * as Yup from 'yup'

import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
// form
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Stack, OutlinedInput, FormHelperText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// routes

// components
import { FormProvider } from '../../../components/hook-form'
import { post } from 'src/utils/httpMethods'
import useAuth from 'src/hooks/useAuth'
import AlternateMfaModal from './AlternateMfaModal'

import SnackbarTopLevel from 'src/sections/@dashboard/SnackbarTopLevel'
import useReacaptcha from 'src/hooks/useReacaptcha'

// ----------------------------------------------------------------------

type FormValuesProps = {
  code1: string
  code2: string
  code3: string
  code4: string
  code5: string
  code6: string
}

type ValueNames = 'code1' | 'code2' | 'code3' | 'code4' | 'code5' | 'code6'

export default function VerifyCodeForm() {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const {
    verifyOnly,
    user,
    logout,
    failedAttempts,
    changeMfa: { authenticatorApp, secret },
  } = useAuth()
  const [open, setOpen] = useState(false)

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
  })
  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  }

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  })

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods

  const values = watch()

  useEffect(() => {
    const target = document.querySelector('input.field-code')

    target?.addEventListener('paste', handlePaste)

    return () => {
      target?.removeEventListener('paste', handlePaste)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaste = (event: any) => {
    let data = event.clipboardData.getData('text')

    data = data.split('')
    ;[].forEach.call(
      document.querySelectorAll('.field-code'),
      (node: any, index) => {
        node.value = data[index]

        const fieldIndex = `code${index + 1}`

        setValue(fieldIndex as ValueNames, data[index])
      }
    )

    event.preventDefault()
  }

  const handleChangeWithNextField = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    const { maxLength, value, name } = event.target

    const fieldIndex = name.replace('code', '')

    const fieldIntIndex = Number(fieldIndex)

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=code${fieldIntIndex + 1}]`
        )

        if (nextfield !== null) {
          ;(nextfield as HTMLElement).focus()
        }
      }
    }

    handleChange(event)
  }
  const { recaptchaVerify } = useReacaptcha('login')
  const onSubmit = async (data: FormValuesProps) => {
    const token = await recaptchaVerify()
    const { code1, code2, code3, code4, code5, code6 } = data
    const otp = code1 + code2 + code3 + code4 + code5 + code6
    console.log(otp)
    try {
      if (user) {
        await post(`/auth/mfa-otp`, {
          userToken: otp,
          authenticatorApp: authenticatorApp,
          secret: secret,
        })
          .then((res) => {
            console.log(res)
            logout()
          })
          .catch((e) => console.log(e))
      } else {
        try {
          await verifyOnly(otp, token)
          navigate('/auth/logging-in')
        } catch (err) {
          console.log(err)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    failedAttempts.failedCount >= 2 &&
      !pathname.includes('recover') &&
      navigate(`/auth/recover/0`)
  }, [failedAttempts, navigate, pathname])

  useEffect(() => {
    console.log(pathname)
    setOpen(pathname.includes('recover'))
  }, [pathname])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement
    const { name } = inputElement

    const fieldIndex = name.replace('code', '')

    const fieldIntIndex = Number(fieldIndex)

    if (event.key === 'Backspace') {
      const currentFeild = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement

      console.log(typeof currentFeild?.value)

      const prevFeild = document.querySelector(
        `input[name=code${fieldIntIndex - 1}]`
      )

      if (prevFeild !== null && !currentFeild?.value.length) {
        ;(prevFeild as HTMLElement).focus()
      }
    }
  }

  return (
    <>
      <SnackbarTopLevel />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ px: 3, py: 6 }}>
          <Stack direction="row" spacing={2} justifyContent="center">
            {Object.keys(values).map((name, index) => (
              <Controller
                key={name}
                name={`code${index + 1}` as ValueNames}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <OutlinedInput
                    {...field}
                    error={!!error}
                    autoFocus={index === 0}
                    placeholder="-"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeWithNextField(event, field.onChange)
                    }
                    onKeyDown={handleKeyDown}
                    inputProps={{
                      className: 'field-code',
                      maxLength: 1,
                      sx: {
                        p: 0,
                        textAlign: 'center',
                        width: { xs: 36, sm: 56 },
                        height: { xs: 36, sm: 56 },
                      },
                    }}
                  />
                )}
              />
            ))}
          </Stack>

          {(!!errors.code1 ||
            !!errors.code2 ||
            !!errors.code3 ||
            !!errors.code4 ||
            !!errors.code5 ||
            !!errors.code6) && (
            <FormHelperText error sx={{ px: 2 }}>
              Code is required
            </FormHelperText>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Verify
          </LoadingButton>
        </Stack>
      </FormProvider>
      <AlternateMfaModal openProps={{ open: open, setOpen: setOpen }} />
    </>
  )
}
