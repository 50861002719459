import React, { useEffect, useState, useContext } from 'react'
import { OpenModalProps } from 'src/@types/modalProps'
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
} from '@mui/material'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { put } from 'src/utils/httpMethods'
import { useNavigate, useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'
interface Props {
  openProps: {
    openIssue: boolean
    setOpenIssue: React.Dispatch<React.SetStateAction<boolean>>
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
  fetchEndletProps: {
    fetchEndlet: boolean
    setFetchEndlet: React.Dispatch<any>
  }
  issueStep: any
}
function StatusIssue({ openProps, fetchEndletProps, issueStep }: Props) {
  const { id } = useParams()
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const { openIssue, setOpenIssue, setOpen } = openProps
  const { fetchEndlet, setFetchEndlet } = fetchEndletProps
  const [loading, setLoading] = useState(false)
  const [issue, setIssue] = useState('')
  const sshKey_issues = ['IP Address Does Not Allow SSH', 'SSH Key Invalid']
  const endlet_conf_issues = ['Configuration Failed']
  const os_validated_issues = [
    'Failure: Endlet Operating System Not Supported {Endlet OS}',
  ]
  const handleClose = () => {
    setOpenIssue(false)
  }


  const addIssue = () => {
    const data = {
      endlet: {
        status: 'Pending',
      },
      deploymentStatusProgress: {
        deploymentStatus: issueStep,
        issue: {
          message: issue,
          isResolved: false,
        },
      },
    }
    setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, data)
      .then((response: any) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Issue added!`,
        })
        setFetchEndlet(!fetchEndlet)
        handleClose()
        setOpen(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
        handleClose()
      })
  }
  return (
    <div>
      <Dialog
        open={openIssue}
        onClose={handleClose}
        scroll="body"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Select Issue</DialogTitle>
        <Typography variant="caption" sx={{ ml: 3 }} color={'gray'}>
          Enter or select from Existing issues below
        </Typography>
        <Box
          sx={{
            pl: 3,
            pr: 3,
            mt: 1,
          }}
        >
          <Autocomplete
            id="tags-filled"
            freeSolo
            options={
              issueStep === 'SSH Key Validated'
                ? sshKey_issues
                : issueStep === 'Endlet Deployed'
                ? endlet_conf_issues
                : issueStep === 'O/S Validated'
                ? os_validated_issues
                : []
            }
            onInputChange={(event, value) => setIssue(value)}
            renderInput={(params) => (
              <>
                <TextField {...params} label="Issues" name="issue" />
              </>
            )}
          />
        </Box>
        <Box sx={{ justifyContent: 'flex-end', display: 'flex', m: 2 }}>
          <LoadingButton
            variant="contained"
            sx={{ mt: 1 }}
            onClick={addIssue}
            loading={loading}
          >
            Add Issue
          </LoadingButton>
        </Box>
      </Dialog>
    </div>
  )
}

export default StatusIssue
