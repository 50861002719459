// components
import SvgIconStyle from '../../../components/SvgIconStyle'
import DashboardIcon from '@mui/icons-material/Dashboard'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import HelpIcon from '@mui/icons-material/Help'

import AutoStoriesIcon from '@mui/icons-material/AutoStories'

import BusinessIcon from '@mui/icons-material/Business'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

import SecurityIcon from '@mui/icons-material/Security'

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
)

const ICONS = {
  services: getIcon('ic_services'),
  knowledge_base: getIcon('ic_knowledge_base'),
  notifications: getIcon('ic_notifications'),
  customers: getIcon('ic_customers'),
  employees: getIcon('ic_employees'),
  provisioning: getIcon('ic_provisioning'),
  reseller: getIcon('ic_reseller'),
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
      {
        title: 'Functions',
        path: '/endlets',
        icon: ICONS.services,
      },
      { title: 'Billing', path: '/billing', icon: <AttachMoneyIcon /> },
      {
        title: 'Message center',
        path: '/message-center',
        icon: ICONS.notifications,
      },
    ],
  },

  // SUPPORT
  // // ----------------------------------------------------------------------
  {
    subheader: 'Support',
    items: [
      {
        title: 'Support center',
        path: '/support/support-center',
        icon: <HelpIcon />,
      },

      {
        title: 'Knowledge base',
        path: '/support/knowledge-base',

        icon: <AutoStoriesIcon />,
      },
    ],
  },

  // super-user
  {
    subheader: 'Super user',
    items: [
      {
        title: 'Messages',
        path: '/super-user/messages',
        caption:
          'Publish new platform updates and user notifications or manage existing ones',
        icon: ICONS.notifications,
      },
      {
        title: 'Company Settings',
        path: '/super-user/company-settings',
        icon: <BusinessIcon />,
      },

      {
        title: 'Customer Management',
        path: '/super-user/customers',
        icon: ICONS.customers,
      },
      {
        title: 'Reseller Management',
        path: '/super-user/reseller',
        icon: ICONS.reseller,
      },
      {
        title: 'Employee Management',
        path: '/super-user/manage-employees',
        icon: ICONS.employees,
      },
      {
        title: 'Provisioning',
        path: '/super-user/provisioning',
        icon: ICONS.provisioning,
      },
    ],
  },
  // COMPANY ADMINISTRATION
  // // ----------------------------------------------------------------------
  {
    subheader: 'Company Administration',
    items: [
      {
        title: 'Company Settings',
        path: '/user/admin/company-settings',
        icon: <BusinessIcon />,
      },
      {
        title: 'User Management',
        path: '/user/admin/users',
        icon: <PeopleAltIcon />,
      },
      {
        title: 'Security',
        path: '/user/admin/security',
        icon: <SecurityIcon />,
      },
    ],
  },
]

const navConfigVertical = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
      {
        title: 'Functions',
        path: '/endlets',
        icon: ICONS.services,
      },
      { title: 'Billing', path: '/billing', icon: <AttachMoneyIcon /> },
      {
        title: 'Message center',
        path: '/message-center',
        icon: ICONS.notifications,
      },
    ],
  },

  // SUPPORT
  // // ----------------------------------------------------------------------
  {
    subheader: 'Support',
    items: [
      {
        title: 'Support',
        path: '/support/support-center',
        icon: <HelpIcon />,
        children: [
          {
            title: 'Support Center',
            path: '/support/support-center',
            icon: <HelpIcon />,
          },

          {
            title: 'Knowledge base',
            path: '/support/knowledge-base',

            icon: <AutoStoriesIcon />,
          },
        ],
      },
    ],
  },

  // super-user
  {
    subheader: 'Super user',
    items: [
      {
        title: 'Messages',
        path: '/super-user/messages',
        caption:
          'Publish new platform updates and user notifications or manage existing ones',
        icon: ICONS.notifications,
      },
      {
        title: 'Management',
        path: '/super-user/customers',
        icon: <BusinessIcon />,
        children: [
          {
            title: 'Customer Management',
            path: '/super-user/customers',
            icon: ICONS.customers,
          },
          {
            title: 'Reseller Management',
            path: '/super-user/reseller',
            icon: ICONS.reseller,
          },
          {
            title: 'Employee Management',
            path: '/super-user/manage-employees',
            icon: ICONS.employees,
          },
          {
            title: 'Provisioning',
            path: '/super-user/provisioning',
            icon: ICONS.provisioning,
          },
        ],
      },
    ],
  },
  // COMPANY ADMINISTRATION
  // // ----------------------------------------------------------------------
  {
    subheader: 'Company Administration',
    items: [
      {
        title: 'Management',
        path: '/user/admin/company-settings',
        icon: <BusinessIcon />,
        children: [
          {
            title: 'Company Settings',
            path: '/user/admin/company-settings',
            icon: <BusinessIcon />,
          },

          {
            title: 'User Management',
            path: '/user/admin/users',
            icon: <PeopleAltIcon />,
          },

          {
            title: 'Security',
            path: '/user/admin/security',
            icon: <SecurityIcon />,
          },
        ],
      },
    ],
  },
]

export { navConfig, navConfigVertical }
