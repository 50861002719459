import React, { useContext, useEffect, useState } from 'react'

import Cytoscape from 'react-cytoscapejs'
import { CircularProgress, Stack, Popover, useTheme } from '@mui/material'

import { ServiceContext } from '../ServiceDetails'
import { graphStyle } from './graphStyles'
import { Typography } from '@mui/material'

// import { graphData } from './mockGraph'

function ServiceCytoscape() {
  const { service, contentLoading } = useContext(ServiceContext)
  const { palette } = useTheme()

  const { associatedEndlets, name, id, serviceUniqueId } = service

  const [graphData, setGraphData] = useState<any>()

  useEffect(() => {
    setGraphData(
      associatedEndlets && [
        ...associatedEndlets.map((endlet: any) => ({
          data: { id: endlet.endletId, label: endlet.endletName },
        })),
        ...associatedEndlets.map(
          (endlet: any, i: number) =>
            i < associatedEndlets.length - 1 && {
              data: {
                id: i,
                source: endlet.endletId,
                target: associatedEndlets[i + 1].endletId,
                label: name,
              },
            }
        ),
      ]
    )
  }, [associatedEndlets, name, service])

  const layoutOptions = {
    name: associatedEndlets && associatedEndlets.length > 2 ? 'circle' : 'grid',

    // idealEdgeLength: 5,
    // nodeRepulsion: 6000,
    // // gravity: 50,
    // animate: true,
    // animationDuration: 5000,
    // // Add more options as needed
  }

  const [cy, setCy] = useState<cytoscape.Core>()
  const [open, setOpen] = useState(false)

  const [pointerLocation, setPointerLocation] = useState({ x: 0, y: 0 })

  const [hoveredNode, setHoveredNode] = useState<any>(null)

  useEffect(() => {
    if (cy) {
      // Add event listeners for node mouse enter and leave
      cy.on('mousemove', (e) => {
        setPointerLocation({
          x: e.originalEvent.clientX,
          y: e.originalEvent.clientY,
        })
      })
      cy.on('click', 'node', handleNodeMouseEnter)
      // cy.on('mouseout', 'node', handleNodeMouseLeave)

      // Clean up the event listeners when the component unmounts
      return () => {
        cy.off('click', 'node', handleNodeMouseEnter)
        // cy.off('mouseout', 'node', handleNodeMouseLeave)
      }
    }
  }, [cy])

  const handleNodeMouseEnter = (event: any) => {
    const node = event.target
    node.addClass('highlight') // Add a class to the node for styling (optional)
    setHoveredNode(node)
    setOpen((prev) => {
      if (prev === true) {
        handlePopoverClose()
      }
      return !prev
    })
  }

  // const handleNodeMouseLeave = (event: any) => {
  //   console.log(event)
  //   setHoveredNode(null)
  //   handlePopoverClose()
  // }

  const handlePopoverClose = () => {
    setHoveredNode(null)
    setOpen(false)
  }

  console.log(hoveredNode?._private.data.id)

  const [popoverData, setPopoverData] = useState<any>()

  useEffect(() => {
    setPopoverData(
      () =>
        associatedEndlets &&
        associatedEndlets.find(
          (elem: any) => elem.endletId === hoveredNode?._private.data.id
        )
    )
  }, [associatedEndlets, hoveredNode])

  console.log(popoverData)

  return (
    <>
      {contentLoading ? (
        <Stack
          sx={{
            width: '90%',
            height: '300px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={50} />
        </Stack>
      ) : graphData && associatedEndlets.length >= 2 ? (
        <>
          <Cytoscape
            zoom={2}
            pan={{ x: 0, y: 0 }}
            cy={(cy) => {
              setCy(cy)
            }}
            elements={
              graphData && [...graphData.filter((data: any) => data !== false)]
            }
            stylesheet={[
              ...graphStyle,
              { selector: 'edge', style: { 'line-color': palette.primary.main } },
            ]}
            layout={layoutOptions}
            style={{
              margin: 'auto',
              width: '90%',
              height: '600px',
              backgroundColor: 'white',
            }}
          />
        </>
      ) : (
        <Stack
          sx={{
            width: '90%',
            height: '600px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">
            {' '}
            Endlet-Service Graph will be displayed once service is at 'Endlets Added'
            stage{' '}
          </Typography>
        </Stack>
      )}
      {hoveredNode && (
        <Popover
          open={open}
          onClose={handlePopoverClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: pointerLocation.y, left: pointerLocation.x }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ p: 3 }}
        >
          {popoverData && (
            <Stack sx={{ m: 2 }}>
              <Typography>{popoverData.endletId}</Typography>
              <Typography>{popoverData.endletIp}</Typography>
            </Stack>
          )}
        </Popover>
      )}
    </>
  )
}

export default ServiceCytoscape
