import React, { useContext, useState } from 'react'

// @mui
import { Dialog, DialogTitle, Alert, Snackbar } from '@mui/material'

// @custom
import { OpenModalProps } from 'src/@types/modalProps'
import { reset } from 'numeral'
import { CustomersContext } from 'src/contexts/CustomersContext'
import { ResellersContext } from 'src/contexts/ResellersContext'
import AddCustomerFilterForm from '../AddCustomerFilterForm'

interface Props {
  openProps: OpenModalProps
  // filterProps: any
}

function AddResellerFilter({ openProps }: Props) {
  const { open, setOpen } = openProps
  const { setFilterItems, filterItems } = useContext(ResellersContext)!
  const [isError, setIsError] = useState(false)

  const handleCloseSnackbar = () => {
    setIsError(false)
  }
  const onSubmit = async (data: any) => {
    const unionArr = [...data.test, ...filterItems]
    const duplicate = unionArr.filter(
      (tag: any, index: any, array: any) =>
        array.findIndex(
          (t: any) =>
            t.label === tag.label &&
            t.operator === tag.operator &&
            t.value === tag.value
        ) !== index
    )
    if (duplicate.length > 0) {
      setIsError(true)
    } else {
      setFilterItems((prev: any) => [...prev].concat([...data.test]))
      setOpen(false)
      reset()
    }
  }

  const handleClose = () => {
    setOpen(false)
    reset()
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} scroll="body" fullWidth>
        <DialogTitle>Apply Filters</DialogTitle>
        <AddCustomerFilterForm
          onSubmit={onSubmit}
          handleClose={handleClose}
          reset={reset}
        />
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={() => {
              setIsError(false)
            }}
            severity="error"
          >
            Duplicate Filter
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  )
}

export default AddResellerFilter
