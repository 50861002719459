import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Grid,
} from '@mui/material'
import { Box } from '@mui/system'
import { put } from 'src/utils/httpMethods'
import useAuth from 'src/hooks/useAuth'
import { post } from 'src/utils/httpMethods'
import CreateEndletModal from '../Services(Endlets)/CreateEndletModal'
import { LoadingButton } from '@mui/lab'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { TenantsContext } from 'src/contexts/TenantsContext'

interface Props {
  openProps: {
    openEp: boolean
    setOpenEp: React.Dispatch<React.SetStateAction<boolean>>
  }
  endlet: any
  setNewEpAdded: React.Dispatch<React.SetStateAction<boolean>>
  currentIndex: any
}
function AddPoint2EP({
  openProps: { openEp, setOpenEp },
  endlet,
  setNewEpAdded,
  currentIndex,
}: Props) {
  const { snackbarProps, setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [open, setOpen] = useState(false)
  const [epError, setEpError] = useState('')
  const [selectedEP, setSelectedEP] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const { selectedTenant } = useContext(TenantsContext)!
  const [openDrop, setOpenDrop] = useState(false)
  const [endlets, setEndlets] = useState<any>([])
  const [endletAdded, setEndletAdded] = useState(false)
  const { user } = useAuth()
  const Packets_Employee = ['Packets_Engineer', 'Packets_Finance', 'Packets_Support']
  const Reseller_Employee = [
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance',
  ]

  //get all endlets
  useEffect(() => {
    if (
      user?.role === 'Packets_Super_User' ||
      Packets_Employee.includes(user?.role) ||
      user?.role === 'Reseller_Admin' ||
      Reseller_Employee.includes(user?.role)
    ) {
      post(
        selectedTenant
          ? `${process.env.REACT_APP_HOST_API_URL}/endlets/organization/${selectedTenant}`
          : `${process.env.REACT_APP_HOST_API_URL}/endlets/filters`,

        {}
      )
        .then((response: any) => {
          setEndlets(
            response.data.filter(
              (res: any) => !res.services.length && res.status !== 'Saved'
            )
          ) //set endlets having no service added
        })
        .catch((e) => {})
    } else {
      post(`${process.env.REACT_APP_HOST_API_URL}/endlets/filters`, {})
        .then((response: any) => {
          setEndlets(
            response.data.filter(
              (res: any) => !res.services.length && res.status !== 'Saved'
            )
          ) //set endlets having no service added
        })
        .catch((e) => {})
    }
  }, [snackbarProps, endletAdded])

  const handleClose = () => {
    setSelectedEP('') //reset dropdown
    setOpenEp(false)
  }
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedEP(event.target.value)
  }

  const handleSubmit = () => {
    updateService()
  }

  //update service with new selected endlet
  const updateService = () => {
    if (Object.keys(selectedEP).length) {
      setLoading(true)
      const currentActiveService = endlet.services[currentIndex]
      const data = {
        service: {
          associatedEndlets: [
            { endletId: endlet.endletId, endletName: endlet.endletName },
            { endletId: selectedEP.endletId, endletName: selectedEP.endletName },
          ],
          // status: 'Consumed',
        },
      }
      put(
        `${process.env.REACT_APP_HOST_API_URL}/services/${currentActiveService.id}`,
        data
      )
        .then((response: any) => {
          updateEpWithNewService()
          setSelectedEP('')
          setLoading(false)
          setNewEpAdded(true)
          handleClose()
        })
        .catch((e) => {
          console.log(e)
          setSnackbarProps({
            open: true,
            message: e.message,
            severity: 'error',
          })
          setLoading(false)
          handleClose()
        })
    } else {
      setEpError('Endlet is required')
    }
  }

  //update selected endlet with current selected service
  const updateEpWithNewService = () => {
    const newService = {
      endlet: {
        services: [
          {
            id: endlet.services[currentIndex].id,
            name: endlet.services[currentIndex].name,
          },
        ],
        serviceType: selectedEP.serviceType.includes(
          endlet.services[currentIndex].type
        )
          ? [...selectedEP.serviceType]
          : [...selectedEP.serviceType, endlet.services[currentIndex].type],
      },
    }
    put(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/${selectedEP.endletId}`,
      newService
    )
      .then((response: any) => {
        setSnackbarProps({
          open: true,
          message: 'Endlet added successfully',
          severity: 'success',
        })
      })
      .catch((e) => {
        console.log(e)
        setSnackbarProps({
          open: true,
          message: e.message,
          severity: 'error',
        })
      })
  }
  useEffect(() => {
    if (Object.keys(selectedEP).length) setEpError('')
  }, [selectedEP])
  console.log(endletAdded)
  return (
    <div>
      <Dialog
        open={openEp}
        onClose={handleClose}
        scroll="body"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Select Endlet</DialogTitle>
        <Typography variant="caption" sx={{ ml: 3 }} color={'gray'}>
          Select from Existing Endlets below or create new one
        </Typography>
        <Box
          sx={{
            pl: 3,
            pr: 3,
            mt: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Endlet</InputLabel>
            <Select
              onClose={() => {
                setOpenDrop(false)
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedEP}
              label="Select Endlet"
              onChange={handleChange}
              sx={{ width: 'auto' }}
            >
              <MenuItem
                onClick={() => {
                  setOpen(true)
                  setOpenDrop(false)
                }}
              >
                <Button variant="text" sx={{ p: 0, m: 0 }}>
                  + Add New Endlet
                </Button>
              </MenuItem>
              <Divider />
              <MenuItem value={''}></MenuItem>
              {endlets &&
                endlets?.map((ep: any, i: any) => (
                  <MenuItem value={ep} key={i}>
                    {ep.endletName}
                  </MenuItem>
                ))}
            </Select>

            <Grid item lg={12}>
              <Typography
                variant="subtitle2"
                style={{ color: '#FF4842', fontWeight: 'normal' }}
              >
                <small>{epError}</small>
              </Typography>
            </Grid>
          </FormControl>
        </Box>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            loading={loading}
          >
            save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <CreateEndletModal
        openProps={{ open, setOpen }}
        setEndletAdded={setEndletAdded}
      />
    </div>
  )
}

export default AddPoint2EP
