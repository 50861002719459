import React, {
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// @mui
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { del, put } from 'src/utils/httpMethods'
import { Card } from '@mui/material'
import { Box } from '@mui/system'
import { Skeleton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { TableNoData } from 'src/components/table'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import Backdrop from '@mui/material/Backdrop'
// table columns
const columns = [
  { label: 'Firewall Type', id: 'type' },
  { label: 'Protocol', id: 'block' },
  { label: 'Port', id: 'mask' },
  { label: 'Source', id: 'hop' },
  { label: 'Destination', id: 'interface' },
  { label: '', id: 'actions' },
]

interface Props {
  loadingProps: any
  tableDataProps: any
  refetch: (load: boolean) => void
  // loading: boolean
  endletId: string
}
function FireWallTable({ tableDataProps, loadingProps, refetch, endletId }: Props) {
  const { tableData, setTableData } = tableDataProps
  const { loading, setLoading } = loadingProps
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [currentIndex, setCurrentIndex] = useState<any>()
  const [delLoading, setDelLoading] = useState(false)
  const headCellRefs = useRef<any>([])
  const [dragLoading, setDragLoading] = useState(false)
  const [cellWidths, setCellWidths] = useState<number[]>([])

  const deleteFirewall = (id: string, index: number) => {
    setDelLoading(true)
    setCurrentIndex(index)
    del(`${process.env.REACT_APP_HOST_API_URL}/firewalls/${id}`)
      .then((res) => {
        setDelLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Firewall Deleted Successfully!`,
        })
        refetch(false)
      })
      .catch((e) => {
        setDelLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: e.message,
        })
      })
  }

  useLayoutEffect(() => {
    const widths = headCellRefs.current.map((ref: any) => ref.offsetWidth)
    setCellWidths(widths)
  }, [])

  const handleDragEnd = (result: any) => {
    setDragLoading(true)
    if (!result.destination) return
    const { source, destination } = result
    const updatedTableData = Array.from(tableData)
    const [removed] = updatedTableData.splice(source.index, 1)

    updatedTableData.splice(destination.index, 0, removed)
    setTableData(updatedTableData)
    handleIndexChange(source.index, destination.index)
  }

  const handleIndexChange = (source: any, destination: any) => {
    // setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/firewalls/modify`, {
      endletId: endletId,
      dragIndex: (source + 1).toString(),
      dropIndex: (destination + 1).toString(),
    })
      .then((res) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: 'Rules updated successfully!',
        })
        // refetch()
        setDragLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: e.message,
        })
      })
  }
  const handleClose = () => {
    setDragLoading(false)
  }
  console.log(loading)
  return (
    <Card sx={{ mt: 0 }}>
      {/* {dragLoading && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.01)', // Set the alpha value to 0.01 for very transparent
            },
          }}
          open={true}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />

        </Backdrop>
      )} */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <TableContainer sx={{ minWidth: 800, mt: 1 }} component={Paper}>
          {dragLoading && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          )}
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell
                    sx={{
                      backgroundColor: 'tableHead',
                      color: 'white',
                      '&:first-of-type': {
                        boxShadow: 'unset',
                      },

                      '&:last-of-type': {
                        boxShadow: 'unset',
                      },
                    }}
                    key={col.id}
                    ref={(ref) => (headCellRefs.current[index] = ref)}
                  >
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <Droppable droppableId="table-rows">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {loading ? (
                    <>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      {tableData && tableData.length ? (
                        tableData.map((rule: any, index: number) => (
                          <Draggable
                            key={rule.id}
                            draggableId={rule.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TableRow
                                key={rule.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableCell style={{ width: cellWidths[0] }}>
                                  {rule.type}
                                </TableCell>
                                <TableCell style={{ width: cellWidths[1] }}>
                                  {rule.protocol.toUpperCase()}
                                </TableCell>
                                <TableCell style={{ width: cellWidths[2] }}>
                                  {rule.port}
                                </TableCell>
                                <TableCell style={{ width: cellWidths[3] }}>
                                  {rule.source}
                                </TableCell>
                                <TableCell style={{ width: cellWidths[4] }}>
                                  {rule.destination}
                                </TableCell>

                                <TableCell style={{ width: cellWidths[5] }}>
                                  {delLoading && currentIndex === index ? (
                                    <CircularProgress size={30} />
                                  ) : (
                                    <IconButton
                                      onClick={() => deleteFirewall(rule.id, index)}
                                      sx={{ ':hover': { color: 'red' } }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <>
                          {!tableData?.length && !loading ? (
                            <TableRow>
                              <TableCell colSpan={7}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <TableNoData
                                    isNotFound={!loading}
                                    message={'No firewall found'}
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                      {provided.placeholder}
                    </>
                  )}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
    </Card>
  )
}

export default FireWallTable
