import React, { useContext, useState } from 'react'

// -----------@mui-------------
import { Divider, IconButton, ListItem, ListItemText, useTheme } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'

import moment from 'moment'

// --------------

import { del } from 'src/utils/httpMethods'
import { ResellersContext } from 'src/contexts/ResellersContext'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import RemoveModal from '../../notes/RemoveModal'

// ---------------

interface Props {
  note: any
  index: number
}

function ResellerNoteRow({ note, index: i }: Props) {
  const theme = useTheme()

  const [openRemove, setOpenRemove] = useState(false)

  const [showAction, setShowAction] = useState(false)

  const value = useContext(ResellersContext)!
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const { changeCompany: setSelected, currentCompany } = value

  const deleteNote = () => {
    del(`/resellers/${currentCompany.id}/notes/${note.id}`)
      .then((response) => {
        setSelected(currentCompany)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Note Removed!`,
        })
      })
      .catch((error) => {
        console.log(error)
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: `Error while removing note!`,
        })
      })
  }

  return (
    <>
      <RemoveModal
        openProps={{ open: openRemove, setOpen: setOpenRemove }}
        onDelete={deleteNote}
      />

      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: theme.palette.background.paper,
          px: 3,
          py: 2,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.palette.background.neutral,
          },
        }}
        onMouseOver={() => setShowAction(true)}
        onMouseLeave={() => setShowAction(false)}
      >
        <ListItemText
          primary={note?.text}
          primaryTypographyProps={{ noWrap: true }}
          secondary={
            <small> {moment.unix(note?.createdAt?._seconds).fromNow()} </small>
          }
        />
        {showAction && (
          <>
            <IconButton
              onClick={() => {
                setOpenRemove(true)
              }}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'error.main',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </ListItem>
      <Divider />
    </>
  )
}

export default ResellerNoteRow
