import React, { useState } from 'react'

import IpSubnetCalculator from 'ip-subnet-calculator'

// @mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { del } from 'src/utils/httpMethods'
import { Card } from '@mui/material'
import { Box } from '@mui/system'
import { Skeleton } from '@mui/material'
import { TableNoData } from 'src/components/table'
import IPBlocksTableRow from './IPBlocksTableRow'

// table columns
const columns = [
  { label: 'IP Block Type', id: 'type' },
  { label: 'IP Block', id: 'block' },
  { label: 'Prefix', id: 'mask' },
  { label: 'Next Hop IP', id: 'hop' },
  { label: 'Interface', id: 'interface' },
  { label: '', id: 'actions' },
]

interface Props {
  tableData: any
  refetch: () => void
  loading: boolean
}

function IPBlocksTable({ tableData, loading, refetch }: Props) {
  const splitStr = tableData && tableData[0]?.ipBlockValue.split('/')
  console.log(
    '======> subnet value======>',
    // abc
    splitStr && IpSubnetCalculator.calculateSubnetMask(splitStr[0], splitStr[1])
  )

  return (
    <Card>
      <TableContainer sx={{ minWidth: 800, mt: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  sx={{
                    backgroundColor: 'tableHead',
                    color: 'white',
                    '&:first-of-type': {
                      boxShadow: 'unset',
                    },

                    '&:last-of-type': {
                      boxShadow: 'unset',
                    },
                  }}
                  key={col.id}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {loading ? (
                <>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {tableData && tableData?.length ? (
                    tableData?.map((block: any, i: number) => (
                      <IPBlocksTableRow
                        block={block}
                        refetchData={refetch}
                        index={i}
                      />
                    ))
                  ) : (
                    <>
                      {!tableData?.length && !loading ? (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <TableNoData
                                isNotFound={!loading}
                                message={'No IP block found'}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default IPBlocksTable
