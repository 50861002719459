export const graphData = [
  { data: { id: 'node1', label: 'Endlet 1', name: 'Varad' } },
  { data: { id: 'node2', label: 'Intermediate' } },
  { data: { id: 'node3', label: 'Endlet 2' } },
  { data: { id: 'node4', label: 'Endlet 3' } },
  { data: { id: 'node5', label: 'Endlet 4' } },
  { data: { id: 'node6', label: 'Endlet 5' } },
  { data: { id: 'node7', label: 'Endlet 6' } },

  // Additional nodes

  { data: { source: 'node1', target: 'node2', label: 'vWan1' } },
  { data: { source: 'node2', target: 'node3', label: 'vWan1' } },
  { data: { source: 'node3', target: 'node4', label: 'vWan1' } },
  { data: { source: 'node3', target: 'node5', label: 'vWan1' } },
  { data: { source: 'node2', target: 'node7', label: 'vWan1' } },
  { data: { source: 'node1', target: 'node6', label: 'vWan1' } },

  // Additional edges
]
