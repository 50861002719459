import React, { createContext, ReactNode, useState } from 'react'

export interface SnackbarContextType {
  open: boolean
  message: string
  severity: 'success' | 'error'
}

export interface ExportedContextValues {
  snackbarProps: SnackbarContextType
  setSnackbarProps: React.Dispatch<React.SetStateAction<SnackbarContextType>>
}

export const ToplevelSnackbarContext = createContext<ExportedContextValues | null>(
  null
)

function SnackbarProvider({ children }: { children: ReactNode }) {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarContextType>({
    open: false,
    message: '',
    severity: 'success',
  })

  return (
    <ToplevelSnackbarContext.Provider
      value={{
        snackbarProps,
        setSnackbarProps,
      }}
    >
      {children}
    </ToplevelSnackbarContext.Provider>
  )
}

export default SnackbarProvider
