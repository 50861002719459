import { initializeApp } from 'firebase/app'

import { getFirestore } from 'firebase/firestore'

import 'firebase/auth'
import 'firebase/database'
import { FIREBASE_API } from 'src/config'

/*
 * Initialize firebase app
 */

export const fireBaseApp = initializeApp(FIREBASE_API)

export const initFirebaseApp = () => {
  initializeApp(FIREBASE_API)
}
export const db = getFirestore(fireBaseApp)
