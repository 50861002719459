import Page from 'src/components/Page'
import { useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { post } from 'src/utils/httpMethods'
import Iconify from 'src/components/Iconify'
import { TableNoData } from 'src/components/table'
import CreateEndletModal from 'src/sections/@dashboard/Services(Endlets)/CreateEndletModal'
import EndletFilters from 'src/sections/@dashboard/Services/EndletFilters'
import SavedEndlet from 'src/sections/@dashboard/Services/SavedEndlet'
import { columns } from 'src/_mock/_endlets'
import Label from '../../components/Label'
import { Link } from 'react-router-dom'

import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { TopLevelmodalContext } from 'src/contexts/ModalContext'

// @mui----------------------------------
import {
  Box,
  Card,
  Divider,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Pagination,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material'

import { Stack } from '@mui/system'
import { Button } from '@mui/material'
import { TenantsContext } from 'src/contexts/TenantsContext'
import useAuth from 'src/hooks/useAuth'
import Tags from './Tags'
import { PATH_SERVICES } from 'src/routes/paths'

function Endlets() {
  const theme = useTheme()

  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(10)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openSavedEndlet, setOpenSavedEndlet] = useState(false)
  const [currentRow, setCurrentRow] = useState({})
  const [endletData, setEndletData] = useState<any>([])

  const [filterItems, setFilterItems] = useState<string[]>(
    JSON.parse(localStorage.getItem('filters') || '[]')
  )

  const navigate = useNavigate()

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  useEffect(() => {
    setPage(1)
  }, [filterItems])

  const handleRowChange = (event: SelectChangeEvent) => {
    setPage(1)
    setRows(parseInt(event.target.value))
  }
  const { selectedTenant } = useContext(TenantsContext)!

  const { user } = useAuth()
  const { snackbarProps } = useContext(ToplevelSnackbarContext)!
  const { modalProps } = useContext(TopLevelmodalContext)!
  const Packets_Employee = ['Packets_Engineer', 'Packets_Finance', 'Packets_Support']
  const Reseller_Employee = [
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance',
  ]

  useEffect(() => {
    setLoading(true)
    const data = filterItems.length ? { filter: [...filterItems] } : {}
    if (
      user?.role === 'Packets_Super_User' ||
      Packets_Employee.includes(user?.role) ||
      user?.role === 'Reseller_Admin' ||
      Reseller_Employee.includes(user?.role)
    ) {
      post(
        selectedTenant
          ? `${process.env.REACT_APP_HOST_API_URL}/endlets/organization/${selectedTenant}?pageNumber=${page}&rowsPerPage=${rows}`
          : `${process.env.REACT_APP_HOST_API_URL}/endlets/filters?pageNumber=${page}&rowsPerPage=${rows}`,

        data
      )
        .then((response: any) => {
          setEndletData(response)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    } else {
      post(
        `${process.env.REACT_APP_HOST_API_URL}/endlets/filters?pageNumber=${page}&rowsPerPage=${rows}`,
        data
      )
        .then((response: any) => {
          setEndletData(response)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }

    window.scrollTo(0, 0)
  }, [page, rows, filterItems, snackbarProps, selectedTenant, user, modalProps])

  const handleRow = (e: any, data: any) => {
    if (data.status === 'Saved') {
      e.preventDefault()
      setCurrentRow(data)
      setOpenSavedEndlet(true)
    }
    navigate(`${data.endletId}/configuration`)
  }
  console.log(endletData.data?.map((end: any) => end.services))
  return (
    <Page title="Endlets" m={3}>
      <Stack direction="row" justifyContent={'space-between'} sx={{ mb: 2 }}>
        <Typography variant="h4">Endlets</Typography>

        <Button
          hidden={
            user?.role === 'Packets_Super_User' ||
            user?.role === 'Reseller_Admin' ||
            [...Reseller_Employee, ...Packets_Employee].includes(user?.role)
          }
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{ mt: 1 }}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
        >
          Create Endlet
        </Button>
      </Stack>
      <CreateEndletModal openProps={{ open, setOpen }} />
      <SavedEndlet
        openProps={{ open: openSavedEndlet, setOpen: setOpenSavedEndlet }}
        currentRow={currentRow}
      />
      <Card>
        <EndletFilters
          filterProps={{
            filterItems,
            setFilterItems,
          }}
          rows={rows}
          page={page}
        />
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <>
                    <TableCell
                      sx={{
                        backgroundColor: 'tableHead',
                        color: 'white',
                        '&:first-of-type': {
                          boxShadow: 'unset',
                        },

                        '&:last-of-type': {
                          boxShadow: 'unset',
                        },
                      }}
                      align="left"
                    >
                      <Stack direction="row" spacing={1}>
                        <span style={{ cursor: 'pointer' }}>
                          {column.label.toUpperCase()}
                        </span>
                      </Stack>
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box>
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {endletData.data && endletData.data?.length ? (
                      endletData.data.map((endlet: any, index: any) => {
                        return (
                          <TableRow key={endlet.endletId}>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={(e: any) => handleRow(e, endlet)}
                            >
                              <Link
                                color="#1CCAFF"
                                // to={`${endlet.endletId}/configuration`}
                                to={PATH_SERVICES.endlets.endletDetails(
                                  endlet.endletId,
                                  'configuration'
                                )}
                              >
                                {endlet.endletName}
                              </Link>
                            </TableCell>

                            <TableCell style={{ width: '25%' }}>
                              {endlet.services &&
                                endlet.services.map((service: any) => {
                                  return (
                                    <>
                                      {service ? (
                                        <Label
                                          variant={
                                            theme.palette.mode === 'light'
                                              ? 'ghost'
                                              : 'filled'
                                          }
                                          sx={{
                                            m: 1,
                                            textTransform: 'none',
                                            color: 'grey',
                                          }}
                                        >
                                          {service.name}
                                        </Label>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )
                                })}
                            </TableCell>

                            <TableCell style={{ width: '25%' }}>
                              <Tags
                                tags={endlet.tags}
                                index={index}
                                data={endletData.data}
                              />
                            </TableCell>
                            <TableCell>
                              {endlet.cloudProvider !== 'custom'
                                ? endlet.region
                                : endlet.cloudProvider === 'custom' &&
                                  endlet.systemGeneratedGeotag
                                ? endlet.region || ''
                                : endlet.cloudProvider === 'custom' &&
                                  !endlet.systemGeneratedGeotag
                                ? endlet.location.name
                                : ''}
                            </TableCell>
                            <TableCell>
                              {endlet ? (
                                <>
                                  {endlet.status === 'Saved' ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === 'light'
                                          ? 'ghost'
                                          : 'filled'
                                      }
                                      sx={{
                                        textTransform: 'none',
                                        backgroundColor: 'grey',
                                        color: 'grey',
                                      }}
                                    >
                                      {endlet.status}
                                    </Label>
                                  ) : (
                                    <Label
                                      variant={
                                        theme.palette.mode === 'light'
                                          ? 'ghost'
                                          : 'filled'
                                      }
                                      color={
                                        endlet.status === 'Up'
                                          ? 'success'
                                          : endlet.status === 'Down'
                                          ? 'error'
                                          : endlet.status === 'Pending'
                                          ? 'warning'
                                          : 'secondary'
                                      }
                                      sx={{
                                        textTransform: 'none',
                                      }}
                                    >
                                      {endlet.status}
                                    </Label>
                                  )}
                                </>
                              ) : (
                                <Label
                                  variant={
                                    theme.palette.mode === 'light'
                                      ? 'ghost'
                                      : 'filled'
                                  }
                                  color="warning"
                                  sx={{
                                    textTransform: 'none',
                                  }}
                                >
                                  Pending
                                </Label>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <>
                        {!endletData.data?.length && !loading ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Box
                                sx={{ display: 'flex', justifyContent: 'center' }}
                              >
                                <TableNoData
                                  isNotFound={!loading}
                                  message={'No endlet found'}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <Box
          hidden={!endletData?.data?.length}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 4,
            py: 2,
          }}
        >
          <FormControl size="small">
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <Typography variant="body2">{`Show`}</Typography>
              <Select
                id="select-small"
                defaultValue={'10'}
                onChange={handleRowChange}
              >
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'20'}>20</MenuItem>
                <MenuItem value={'50'}>50</MenuItem>
              </Select>
              <Typography variant="body2">{`of ${
                !loading ? endletData.totalResults : 0
              } results`}</Typography>
            </Stack>
          </FormControl>

          <Pagination
            shape="rounded"
            color="primary"
            variant="outlined"
            count={Math.ceil(endletData.totalResults / rows)}
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Card>
    </Page>
  )
}

export default Endlets
