import { memo, useEffect, useState } from 'react'
// @mui
import { Box, Stack } from '@mui/material'
//
import { NavSectionProps } from '../type'
import NavList from './NavList'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@mui/material/styles'
import Logo from 'src/components/Logo'
import UserDropdown from 'src/components/TenantSelect'
import { Button } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'
import useResponsive from 'src/hooks/useResponsive'
import { IconButtonAnimate } from 'src/components/animate'
import Iconify from 'src/components/Iconify'
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover'
import { Typography } from '@mui/material'

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const { user, organization } = useAuth()
  const theme = useTheme()
  const [navItemsConditional, setNavItemsConditional] = useState(navConfig)
  const Packets_Employee = ['Packets_Engineer', 'Packets_Finance', 'Packets_Support']
  const Reseller_Employee = [
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance',
  ]
  const Customer_Employee = [
    'Customer_Engineer',
    'Customer_Support',
    'Customer_Finance',
  ]

  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    setNavItemsConditional(() =>
      user?.role === 'Packets_Super_User' ||
      Packets_Employee.includes(user?.role) ||
      user?.role === 'Reseller_Admin' ||
      Reseller_Employee.includes(user?.role) ||
      Customer_Employee.includes(user?.role)
        ? Packets_Employee.includes(user?.role) ||
          Reseller_Employee.includes(user?.role) ||
          Customer_Employee.includes(user?.role)
          ? [
              ...navConfig
                .map((conf) =>
                  conf.subheader === 'General'
                    ? {
                        ...conf,
                        items: [
                          ...conf.items.filter((a) => a.title !== 'Message center'),
                        ],
                      }
                    : { ...conf }
                )
                .filter(
                  (config) =>
                    config.subheader !== 'Super user' &&
                    config.subheader !== 'Company Administration'
                ),
            ]
          : // For Reseller_Admin
          user?.role === 'Reseller_Admin'
          ? [
              ...navConfig
                .map((conf) =>
                  conf.subheader === 'General'
                    ? {
                        ...conf,
                        items: [
                          ...conf.items.filter((a) => a.title !== 'Message center'),
                        ],
                      }
                    : conf.subheader === 'Super user'
                    ? {
                        subheader: 'Administration',
                        items: [
                          ...conf.items.filter(
                            (a) => a.title !== 'Reseller Management'
                          ),
                        ],
                      }
                    : { ...conf }
                )
                .filter((config) => config.subheader !== 'Company Administration'),
            ]
          : //For Super_User
            [
              ...navConfig
                .map((conf) =>
                  conf.subheader === 'General'
                    ? {
                        ...conf,
                        items: [
                          ...conf.items.filter((a) => a.title !== 'Message center'),
                        ],
                      }
                    : conf.subheader === 'Super user'
                    ? {
                        subheader: 'Super User Administration',
                        items: [
                          ...conf.items.filter(
                            (a) => a.title !== 'Company Settings'
                          ),
                        ],
                      }
                    : { ...conf }
                )
                .filter((config) => config.subheader !== 'Company Administration'),
            ]
        : //For Customer_Admin
          [...navConfig.filter((conf) => conf.subheader !== 'Super user')]
    )

    // setNavItemsConditional(() =>
    //   user?.role === 'Packets_Super_User' || user?.role === 'Reseller_Admin'
    //     ? [
    //         ...navConfig.map((config) =>
    //           config.subheader === 'General'
    //             ? {
    //                 ...config,
    //                 items: [
    //                   ...config.items.filter(
    //                     (cfg) => cfg.title !== 'Message center'
    //                   ),
    //                 ],
    //               }
    //             : { ...config }
    //         ),
    //       ]
    //     : []
    // )
  }, [navConfig, user])

  return (
    <Stack
      direction="row"
      // justifyContent="flex-start"
      sx={{
        // bgcolor: 'background.neutral',
        borderRadius: 0,
        px: 0.5,
        py: 0.5,
        bgcolor: 'navBg',
        alignItems: 'center',
        // bgcolor: theme.palette.primary.darker,
        borderRightStyle: 'solid',
      }}
    >
      <Stack direction={'row'} spacing={1} sx={{ ml: 4 }}>
        <Logo />

        {!isDesktop && (
          <IconButtonAnimate
            // onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {/* <Searchbar /> */}
        {user?.role === 'Packets_Super_User' ||
        Packets_Employee.includes(user?.role) ||
        user?.role === 'Reseller_Admin' ||
        Reseller_Employee.includes(user?.role) ? (
          <UserDropdown />
        ) : (
          <Box
            component="span"
            sx={{
              color: 'text.disabled',
            }}
            className="d-flex align-items-center"
          >
            <Button
              disabled
              sx={{
                color: 'text.disabled',
                ':disabled': {
                  color: 'text.disabled',
                },
              }}
            >
              {' '}
              <BusinessIcon />
              <Typography component={'span'} className="ms-2" variant="subtitle1">
                {organization?.name}
              </Typography>
            </Button>
          </Box>
        )}
      </Stack>

      <Stack
        direction="row"
        flexGrow={3}
        sx={{
          ...hideScrollbar,
          py: 1,
          ml: 5,
        }}
      >
        {navItemsConditional.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
              />
            ))}
          </Stack>
        ))}
      </Stack>
      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ mr: 4 }}>
        <Typography sx={{ color: 'text.disabled' }}>
          Hello, {user?.firstName} {user?.lastName}
        </Typography>
        <AccountPopover />
      </Stack>
    </Stack>
  )
}

export default memo(NavSectionHorizontal)
