import React, { SetStateAction, useEffect } from 'react'

// @Mui
import { Checkbox, TableRow, TableCell, Table } from '@mui/material'

import { EndletInterfaces, IPAddress } from '../ConfigureEndletModal'
import { TableContainer } from '@mui/material'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/components/table'

import { TableBody } from '@mui/material'
import { Typography } from '@mui/material'

// custom table head
const TABLE_HEAD = [{ id: '', label: 'Select All' }, { id: '' }]

type RowProps = {
  row: IPAddress
  selected: boolean
  onSelectRow: VoidFunction
  setSelected: any
}

interface TableProps {
  addresses: IPAddress[]
  selectedInterface: EndletInterfaces
  useTableProps: {
    selected: string[]
    setSelected: React.Dispatch<SetStateAction<string[]>>
    onSelectRow: (id: string) => void
    onSelectAllRows: (checked: boolean, newSelecteds: string[]) => void
    onSort: (id: string) => void
  }
}

// child component for rows
function IPListRow({ row, selected, onSelectRow }: RowProps) {
  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align="left" sx={{ cursor: 'pointer' }}>
        {row.IP}
      </TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  )
}

function InterfaceIPsList({
  addresses,
  selectedInterface,
  useTableProps: { selected, setSelected, onSelectRow, onSelectAllRows, onSort },
}: TableProps) {
  useEffect(() => {
    setSelected([])
  }, [selectedInterface, setSelected])

  return (
    <>
      <Typography variant="subtitle2">Select IP addresses to add</Typography>
      <TableContainer>
        {selected.length > 0 && (
          <TableSelectedActions
            numSelected={selected.length}
            rowCount={addresses.length}
            onSelectAllRows={(checked) => onSelectAllRows(checked, [])}
            actions={
              <></>
              // <Tooltip title="Delete">
              //   <IconButton
              //     color="primary"
              //     onClick={() => handleDeleteRows(selected)}
              //   >
              //     <Iconify icon={'eva:trash-2-outline'} />
              //   </IconButton>
              // </Tooltip>
            }
            sx={{ position: 'relative', left: 0, right: 0, mx: 1 }}
          />
        )}

        <Table>
          {selected.length <= 0 && (
            <TableHeadCustom
              headLabel={TABLE_HEAD}
              rowCount={addresses.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  addresses.map((addr: any) => addr.IP)
                )
              }
              sx={{ height: 58 }}
            />
          )}
          <TableBody>
            {addresses.map((addr) => (
              <IPListRow
                row={addr}
                selected={selected.includes(addr.IP)}
                onSelectRow={() => onSelectRow(addr.IP)}
                setSelected={setSelected}
                key={addr.IP}
              />
            ))}
            <TableNoData isNotFound={!addresses.length} message={'No IP found'} />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default InterfaceIPsList
