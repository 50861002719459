import React, { useContext, useState } from 'react'
import {
  ClickAwayListener,
  Stack,
  TableCell,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { IPAddress } from '../ConfigureEndletModal'
import { ConfigureInterfaceContext } from './UpdateInterfaceForm'

interface Props {
  cellId: keyof IPAddress
  text: string
  rowProps: {
    rowEdit: boolean
    setRowEdit: React.Dispatch<React.SetStateAction<boolean>>
    rowIPobject: IPAddress
    setRowIPobject: React.Dispatch<React.SetStateAction<IPAddress>>
  }
  error: string | null
}

function EditableTableCell({
  text,
  rowProps: { setRowIPobject, rowIPobject, setRowEdit },
  cellId,
  error,
}: Props) {
  const [editing, setEditing] = useState(false)
  const { tableDisabled, deleteDisabled } = useContext(ConfigureInterfaceContext)!
  const handleFocus = (e: any) => {
    if (!tableDisabled) {
      setEditing(true)
    }
  }
  const { palette } = useTheme()

  const handleClickAway = (e: any) => {
    setEditing(!error ? false : true)
  }
  console.log(tableDisabled)
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TableCell
        onClick={handleFocus}
        width={'max-content'}
        sx={{
          '&:first-child': {
            paddingLeft: tableDisabled ? 3 : 0,
          },
        }}
      >
        {!editing ? (
          <Typography
            sx={
              !tableDisabled
                ? {
                    '&:hover': {
                      border: `2px solid ${palette.primary.main}`,
                      transition: 'border-color 0.25s',
                      boxShadow: `0px 0px 4px 0px ${palette.primary.main}`,
                    },

                    p: 0.5,
                    border: `2px solid lightgray`,
                    borderRadius: 1,
                    transition: 'border-radius 0.25s',
                    minHeight: '40px',
                  }
                : {
                    minHeight: '40px',
                  }
            }
          >
            {text}
          </Typography>
        ) : (
          <Stack>
            <TextField
              size="small"
              autoFocus
              value={text}
              color={error ? 'error' : 'primary'}
              onChange={(e) => {
                console.log(
                  '====> Delete disabled ====>',
                  e.target.value === text,
                  !deleteDisabled
                )
                setRowEdit(e.target.value === text && !deleteDisabled ? false : true)
                setRowIPobject((prev) => {
                  const temp = { ...prev }
                  temp[cellId] = e.target.value
                  return temp
                })
              }}
            />
            {error ? (
              <Typography variant="body2" color={'error'}>
                {error}
              </Typography>
            ) : (
              <Typography variant="body2">{'    '}</Typography>
            )}
          </Stack>
        )}
      </TableCell>
    </ClickAwayListener>
  )
}

export default EditableTableCell
