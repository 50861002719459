// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField, TextFieldProps } from '@mui/material'

// ----------------------------------------------------------------------

type IProps = {
  name: string
}

type Props = IProps & TextFieldProps

export default function RHFTextField({ name, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          autoComplete="off"
          {...field}
          fullWidth
          value={
            (typeof field.value === 'number' && field.value === 0) ||
            field.value === ' '
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  )
}
