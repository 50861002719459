import React, { useContext, useState } from 'react'

// @mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import { LoadingButton } from '@mui/lab'

// types
import { put } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import useAuth from 'src/hooks/useAuth'
import { CustomersContext } from 'src/contexts/CustomersContext'
import { ResellersContext } from 'src/contexts/ResellersContext'

interface Props {
  openProps: {
    openStatusUpdate: boolean
    setOpenStatusUpdate: React.Dispatch<React.SetStateAction<boolean>>
  }
  companyName: string
  id: string
  isActive: boolean
  status: string
  subdomain: string
}

function ResellerStatusUpdateModal({
  companyName,
  openProps,
  id,
  isActive,
  status,
  subdomain,
}: Props) {
  const { openStatusUpdate: open, setOpenStatusUpdate: setOpen } = openProps
  const { reloadResellers } = useContext(ResellersContext)!
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const { user, organization } = useAuth()
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdateStatus = async () => {
    try {
      setLoading(true)
      const result: any = await put(`/resellers/status/${id}`, {
        isActive: !isActive,
        status: !isActive ? 'Active' : 'Archived',
        accessTier: 'Reseller',
        subdomain,
        organizationName: companyName,
      })
      reloadResellers()
      setSnackbarProps({
        open: true,
        message: result.message || `Reseller has been activated`,
        severity: 'success',
      })
      setLoading(false)
      setOpen(false)
    } catch (e: any) {
      setLoading(false)
      setSnackbarProps({
        open: true,
        message: e.error || e.message,
        severity: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ width: 600 }}>
        <DialogTitle>
          {`${status === 'Active' && isActive ? 'Deactivate' : 'Activate'} Reseller`}
        </DialogTitle>

        <DialogContentText sx={{ p: 3 }}>
          {`

          ${
            status === 'Active' && isActive
              ? `Are you sure you want to deactivate reseller ${companyName}?`
              : `Are you sure you want to activate reseller ${companyName}?`
          }
          `}
        </DialogContentText>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleUpdateStatus}
            variant="contained"
            sx={
              status === 'Active' && isActive
                ? {
                    bgcolor: red[400],
                    color: 'white',
                    '&:hover': { bgcolor: red[900] },
                  }
                : {
                    bgcolor: green[400],
                    color: 'white',
                    '&:hover': { bgcolor: green[900] },
                  }
            }
          >
            {status === 'Active' && isActive ? 'Deactivate' : 'Activate'}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ResellerStatusUpdateModal
