import React, { useContext, useState, useEffect } from 'react'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { Dialog, Grid } from '@mui/material'
import { OpenModalProps } from 'src/@types/modalProps'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Button } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import { useParams } from 'react-router'
import NewSSHModal from '../Services(Endlets)/NewSSHModal'
import { get, put } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'

interface Props {
  openProps: OpenModalProps
  getEndlets: () => Promise<void>
  endlet: any
  sshKey: any
}

function ChangeSSHkey({
  openProps: { open, setOpen },
  endlet,
  sshKey,
  getEndlets,
}: Props) {
  const { id } = useParams()
  const { organization, UseNewUserData } = useAuth()
  const orgName = organization?.name
  let keys = organization?.sshKeys

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [newKeyAdded, setNewKeyAdded] = useState(false)
  const [openNewSSH, setOpenNewSSH] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sshKeys, setSshKeys] = useState<any>([])

  const getSShKeys = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/auth/organization/ssh`)
      .then((res: any) => {
        setSshKeys(
          res.reverse().map((key: any, k: number) =>
            k === res.length - 1 && newKeyAdded
              ? {
                  id: key.id,
                  name: key.sshKeyName,
                  username: key.username,
                  checked: true,
                }
              : {
                  id: key.id,
                  name: key.sshKeyName,
                  username: key.username,
                  checked: !newKeyAdded
                    ? key.sshKeyName === endlet?.sshKey?.sshKeyName
                    : false,
                }
          ) || []
        )
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    getSShKeys()
  }, [])

  const setNewKeys = async () => {
    console.log('in set shhsk')
    await getSShKeys()
  }
  useEffect(() => {
    if (newKeyAdded === false) return
    setNewKeys()
  }, [newKeyAdded])

  const handleSubmit = () => {
    const selectedSSH = sshKeys.find((ssh: any) => ssh.checked)
    const finalData = {
      endlet: {
        sshKey: {
          sshKeyId: selectedSSH.id,
          sshKeyName: selectedSSH.name,
          username: selectedSSH.username,
          sshPortNumber: selectedSSH.sshPortNumber
            ? selectedSSH.sshPortNumber
            : '22',
        },
      },
    }
    setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, finalData)
      .then((response: any) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `SSH Key Changed successfully`,
        })
        getEndlets()
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: `Error while Changing SSH Key`,
        })
      })

    handleClose()
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ p: 2, width: 500 }}>
        <Typography variant="h6" sx={{ my: 1 }}>
          Change SSH Key
        </Typography>
        <Stack
          direction={'row'}
          alignItems="center"
          sx={{ mb: 4 }}
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">Existing SSH Key</Typography>
          <Button variant="outlined" onClick={() => setOpenNewSSH(true)}>
            + New SSH Key
          </Button>
        </Stack>
        <Grid container spacing={1}>
          {sshKeys.length ? (
            sshKeys.map((key: any, i: number) => (
              <Grid item key={i}>
                <React.Fragment key={key.name}>
                  <Button
                    sx={{
                      textTransform: 'none',
                      wordWrap: 'break-word',
                      minWidth: 120,
                      color: key.checked ? 'primary' : 'darkgray',
                    }}
                    onClick={() => {
                      setSshKeys((prev: any) => [
                        ...prev.map((innerKey: any, j: number) =>
                          i === j
                            ? {
                                ...innerKey,
                                checked: true,
                              }
                            : { ...innerKey, checked: false }
                        ),
                      ])
                    }}
                    variant="outlined"
                    startIcon={
                      key.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                    }
                  >
                    <Typography
                      variant="inherit"
                      sx={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {key.name
                        .replace(/-/g, ' ')
                        .replace(orgName?.toLowerCase(), '')}
                    </Typography>
                  </Button>
                </React.Fragment>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ pb: 2, pl: 1 }}>
              No SSH keys added, add a new one
            </Typography>
          )}
        </Grid>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            float: 'right',
            px: 0,
            my: 2,
          }}
          spacing={1}
        >
          <Button
            onClick={handleClose}
            variant="text"
            type="button"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
      <NewSSHModal
        openProps={{
          open: openNewSSH,
          setOpen: setOpenNewSSH,
        }}
        setNewKeys={setNewKeys}
        newKeyFlag={setNewKeyAdded}
      />
    </Dialog>
  )
}

export default ChangeSSHkey
