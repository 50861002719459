import React, { useContext, useEffect, useState } from 'react'

// Packages
import IpSubnetCalculator from 'ip-subnet-calculator'

// contexts
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { EndletContext, InterfacesContext } from '../EndletDetails'

// interceptors
import { get, post, put } from 'src/utils/httpMethods'

// RHF
import * as Yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ConfigureContext } from '../EpDtServices'
import { FormProvider } from 'src/components/hook-form' 

// @mui

import { FormControl, Grid, InputLabel, Select, TextField } from '@mui/material'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { MenuItem } from '@mui/material'
import { SelectChangeEvent } from '@mui/material'

import IPBlocksTable from './IPBlocksTable'
import { EndletInterfaces, IPAddress } from '../ConfigureEndletModal'
import InterfaceIPsList from './InterfaceIPsList'
import useTable from 'src/hooks/useTable'

// form

interface FormValue {
  ipBlockType: string
  ipValue?: string
  prefixValue?: string
  subnetValue?: string
  interfaceName: string
  ipVersion?: string
  nextHopIpAddress?: string
}

const formSchema = Yup.object().shape({
  ipBlockType: Yup.string().required('IP Block Type is required!'),
  interfaceName: Yup.string().required('Interface is required!'),

  ipValue: Yup.string().when(['ipBlockType', 'ipVersion'], {
    is: (ipBlockType: string, ipVersion: string) =>
      ipBlockType === 'Routed' && ipVersion === 'IPv4',
    then: Yup.string()
      .required('Ip is required')
      .trim()
      .matches(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        'Invalid IP'
      ),
    otherwise: Yup.string().when(['ipBlockType', 'ipVersion'], {
      is: (ipBlockType: string, ipVersion: string) =>
        ipBlockType === 'Routed' && ipVersion === 'IPv6',
      then: Yup.string()
        .required('Ip is required')
        .trim()
        .matches(
          /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/,
          'Invalid IP'
        ),
    }),
  }),

  prefixValue: Yup.string().when(['ipBlockType', 'ipVersion'], {
    is: (ipBlockType: string, ipVersion: string) =>
      ipBlockType === 'Routed' && ipVersion === 'IPv6',
    then: Yup.string().trim().nullable().required('Prefix is required'),
  }),

  subnetValue: Yup.string().when('ipBlockType', {
    is: (ipBlockType: string, ipVersion: string) =>
      ipBlockType === 'Routed' && ipVersion === 'IPv4',
    then: Yup.string().trim().nullable().required('Prefix is required'),
  }),

  ipVersion: Yup.string().when('ipBlockType', {
    is: 'Routed',
    then: Yup.string().required('ipVersion is required!'),
  }),
  nextHopIpAddress: Yup.string().when(['ipBlockType', 'ipVersion'], {
    is: (ipBlockType: string, ipVersion: string) =>
      ipBlockType === 'Routed' && ipVersion === 'IPv4',
    then: Yup.string()
      .required('Next Hop IP is required!')
      .trim()
      .matches(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        'Invalid IP'
      ),
    otherwise: Yup.string().when(['ipBlockType', 'ipVersion'], {
      is: (ipBlockType: string, ipVersion: string) =>
        ipBlockType === 'Routed' && ipVersion === 'IPv6',
      then: Yup.string()
        .required('Next Hop IP is required!')
        .trim()
        .matches(
          /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/,
          'Invalid IP'
        ),
    }),
  }),
})

// const configeSchema = Yup.object().shape({
//   ipBlock: Yup.array().of(Yup.object().shape(formSchema)),
// })

interface Props {
  openProps: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
  isHnS: boolean
}

function IpBlocksTabView({ openProps: { open, setOpen }, isHnS }: Props) {
  const {
    isIpAddedProps: { isIPAdded, setIsIPAdded },
    ipBlockProps: ipBlocksLeft,
    setStateMethodProps: { setDataLoading, setNewEpAdded },
    isSuperUser,
  } = useContext(ConfigureContext)!

  const [loading, setLoading] = useState(false)
  const [syncLoader, setSyncLoader] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  // To get selected IPs from child list component
  const { selected, setSelected, onSelectRow, onSelectAllRows, onSort } = useTable()
  const [selectedIPs, setSelectedIPs] = useState<(IPAddress | undefined)[]>()
  useEffect(() => {
    setSelectedIPs(
      selected.map((sel) =>
        selectedInterface?.addresses.find((addr) => addr.IP === sel)
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  /*TABLE DATA handlers and state */
  const { endlet } = useContext(EndletContext)!

  const [associatedEndlet, setassociatedEndlet] = useState<any>(undefined)
  const interfaces = useContext(InterfacesContext)!

  console.log(interfaces)
  const [selectedInterface, setSelectedInterface] =
    useState<EndletInterfaces | null>(null)
  const id = endlet.endletId
  const serviceId = endlet.services[0].id
  const endletName = endlet.endletName
  const [tableData, setTableData] = useState<any>([])

  const getIPblocks = () => {
    // setTableLoading(true)
    get(`${process.env.REACT_APP_HOST_API_URL}/ip-blocks/endlet/${id}`)
      .then((res) => {
        setTableData(res.data)
        setTableLoading(false)
      })
      .catch((e) => {
        setTableLoading(false)
        console.log(e)
      })
  }

  useEffect(() => {
    getIPblocks()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const epDesignation =
    associatedEndlet && associatedEndlet.find((ep: any) => ep.endletId === id)

  const [designation, setDesignation] = useState(
    epDesignation ? epDesignation.designation : ''
  )

  const handleChange = (e: SelectChangeEvent) => {
    setDesignation(e.target.value as string)
  }

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ipBlockType: '',
      ipValue: '',
      prefixValue: '',
      interfaceName: '',
      ipVersion: '',
      interfaceIpAddress: '',
      nextHopIpAddress: '',
      subnetValue: '',
    },
  })

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods
  console.log(errors)
  const {
    ipBlockType,
    interfaceName,
    nextHopIpAddress,
    ipVersion,
    ipValue,
    subnetValue,
    prefixValue,
  } = watch()
  console.log({ ipValue, subnetValue, prefixValue })
  const handleClose = () => {
    console.log('^^^^^^^ closing modal ^^^^^^^')
    setOpen(false)
    setLoading(false)
    setIsIPAdded(true)
    reset()
  }
  console.log(IpSubnetCalculator.calculateCIDRPrefix('10.114.0.2', '255.255.240.0'))

  const onSubmit = async (data: FormValue) => {
    setLoading(true)
    console.log(data)

    /* handler for calculating CIDR values */
    const ipVal = (ipValue: string, subnetValue: string) => {
      const prefix = IpSubnetCalculator.calculateCIDRPrefix(ipValue, subnetValue)
      return `${prefix.ipLowStr}/${prefix.prefixSize}`
    }

    if (designation !== '') {
      put(`${process.env.REACT_APP_HOST_API_URL}/services/${serviceId}`, {
        associatedEndlets: [
          ...associatedEndlet.filter((ep: any) => ep.endletId !== id),
          { endletId: id, endletName: endletName, designation: designation },
        ],
      })
        .then((res) => console.log(res))
        .catch((e) => console.log(e))
    }
    const finalData =
      data.ipBlockType === 'Routed'
        ? {
            ipBlockType: data.ipBlockType,
            interfaceName: data.interfaceName,
            ipBlockValue:
              data.ipVersion === 'IPv4'
                ? ipVal(ipValue, subnetValue)
                : `${ipValue}/${prefixValue}`,
            IPVersion: data.ipVersion,
            endletId: id,
            serviceId: serviceId,
            nextHopIpAddress: data.nextHopIpAddress ? data.nextHopIpAddress : '',
          }
        : {
            ipBlockType: data.ipBlockType,
            interfaceName: data.interfaceName,
            endletId: id,
            serviceId: serviceId,
            selectedIPs: selectedIPs,
          }
    console.log(finalData)
    post(`${process.env.REACT_APP_HOST_API_URL}/ip-blocks`, finalData)
      .then((response: any) => {
        setLoading(false)
        setValue('ipBlockType', '')
        setValue('interfaceName', '')
        setSelectedInterface(null)
        reset()
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `IP Block created successfully`,
        })
        setLoading(false)
        getIPblocks()
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  // For synchronizing routes
  const handleSyncRoutes = (id: string) => {
    setSyncLoader(true)
    get(`/ip-blocks/synchronize/${id}`)
      .then((res: any) => {
        // getIPblocks();
        setSyncLoader(false)
        setSnackbarProps({ open: true, message: res.message, severity: 'success' })
      })
      .catch((e) => {
        console.log(e)
        setSyncLoader(false)
        setSnackbarProps({ open: true, message: e.message, severity: 'error' })
      })
  }

  return (
    <Stack direction={'row'} alignItems="flex-start" width="100%">
      <Grid container columnGap={2}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={4} md={4} lg={4}>
            <Box sx={{ p: 2, width: '25vw' }}>
              {isHnS && (
                <Stack spacing={1} sx={{ my: 1 }}>
                  <Typography variant="subtitle2">Select Designation</Typography>
                  <Select
                    value={designation}
                    placeholder={'Designation'}
                    onChange={handleChange}
                    sx={{ width: '100%' }}
                  >
                    <MenuItem value={''}>
                      <em>Select Designation</em>
                    </MenuItem>
                    <MenuItem value={'Hub'}>Hub</MenuItem>
                    <MenuItem value={'Spoke'}>Spoke</MenuItem>
                  </Select>
                </Stack>
              )}

              <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">IP Block Type</Typography>
                    <FormControl>
                      <InputLabel>Select the IP Block Type</InputLabel>
                      <Controller
                        name={`ipBlockType`}
                        defaultValue={ipBlockType}
                        render={({ field }) => (
                          <Select label={'Select the IP Block Type'} {...field}>
                            <MenuItem value={''}>
                              <em>Select the IP Block Type</em>
                            </MenuItem>
                            <MenuItem value={'Locally Connected'}>
                              Locally Connected
                            </MenuItem>
                            <MenuItem value={'Routed'}>Routed</MenuItem>
                          </Select>
                        )}
                      ></Controller>
                    </FormControl>
                    {errors?.ipBlockType ? (
                      <Grid item lg={12}>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#FF4842', fontWeight: 'normal' }}
                        >
                          <small> {errors?.ipBlockType.message}</small>
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Stack>
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Interface</Typography>
                    <FormControl>
                      <InputLabel>
                        Select the Interface Name of the Locally Connected IP Subnet
                      </InputLabel>
                      <Controller
                        name={`interfaceName`}
                        defaultValue={`${interfaceName}`}
                        render={({ field }) => (
                          <Select
                            label={
                              'Select the Interface Name of the Locally Connected IP Subnet'
                            }
                            {...field}
                          >
                            <MenuItem value={''} defaultChecked disabled>
                              <em>
                                Select the Interface Name of the Locally Connected IP
                                Subnet
                              </em>
                            </MenuItem>
                            {interfaces &&
                              interfaces
                                .filter(
                                  (intf: EndletInterfaces) => intf.wanIP !== true
                                )
                                .map((intf: EndletInterfaces) => (
                                  <MenuItem
                                    value={intf.name}
                                    onClick={() => {
                                      setSelectedInterface(intf)
                                    }}
                                  >
                                    {intf.name}
                                  </MenuItem>
                                ))}
                          </Select>
                        )}
                      ></Controller>
                    </FormControl>
                    {errors?.interfaceName ? (
                      <Grid item lg={12}>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#FF4842', fontWeight: 'normal' }}
                        >
                          <small> {errors?.interfaceName?.message}</small>
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Stack>

                  {ipBlockType === 'Routed' && (
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">
                        IP Version (IPv4, IPv6)
                      </Typography>
                      <FormControl>
                        <InputLabel>Select the IP Version</InputLabel>
                        <Controller
                          name={`ipVersion`}
                          defaultValue={`${ipVersion}`}
                          render={({ field }) => (
                            <Select label={'Select the IP Version'} {...field}>
                              <MenuItem value={''} defaultChecked disabled>
                                <em>Select the IP Version</em>
                              </MenuItem>

                              <MenuItem value={'IPv4'}>IPv4</MenuItem>
                              <MenuItem value={'IPv6'}>IPv6</MenuItem>
                            </Select>
                          )}
                        ></Controller>
                      </FormControl>
                      {errors?.ipVersion ? (
                        <Grid item lg={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ color: '#FF4842', fontWeight: 'normal' }}
                          >
                            <small> {errors?.ipVersion?.message}</small>
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Stack>
                  )}

                  {ipBlockType === 'Routed' && (
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">IP Block</Typography>

                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="Enter the IP Block"
                            {...field}
                            autoComplete="off"
                            fullWidth
                          />
                        )}
                        {...register(`ipValue`)}
                      ></Controller>
                      {errors?.ipValue ? (
                        <Grid item lg={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ color: '#FF4842', fontWeight: 'normal' }}
                          >
                            <small> {errors?.ipValue?.message}</small>
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Stack>
                  )}

                  {ipBlockType === 'Routed' && ipVersion === 'IPv4' && (
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">Subnet Mask</Typography>

                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="Enter the Subnet Mask"
                            {...field}
                            autoComplete="off"
                            fullWidth
                          />
                        )}
                        {...register(`subnetValue`)}
                      ></Controller>
                      {errors?.subnetValue ? (
                        <Grid item lg={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ color: '#FF4842', fontWeight: 'normal' }}
                          >
                            <small> {errors?.subnetValue?.message}</small>
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Stack>
                  )}

                  {ipBlockType === 'Routed' && ipVersion === 'IPv6' && (
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">Prefix</Typography>

                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="Enter the Prefix"
                            {...field}
                            autoComplete="off"
                            fullWidth
                          />
                        )}
                        {...register(`prefixValue`)}
                      ></Controller>
                      {errors?.prefixValue ? (
                        <Grid item lg={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ color: '#FF4842', fontWeight: 'normal' }}
                          >
                            <small> {errors?.prefixValue?.message}</small>
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Stack>
                  )}

                  {ipBlockType === 'Routed' && (
                    // <Stack spacing={2}>
                    //   <Typography variant="subtitle2">Via</Typography>
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">
                        Via Next Hop IP Address
                      </Typography>

                      <Controller
                        defaultValue={`${nextHopIpAddress}`}
                        render={({ field }) => (
                          <TextField
                            label="Enter the IP Address of the Next Hop Router"
                            {...field}
                            autoComplete="off"
                            fullWidth
                          />
                        )}
                        {...register(`nextHopIpAddress`)}
                      ></Controller>
                      {errors?.nextHopIpAddress ? (
                        <Grid item lg={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ color: '#FF4842', fontWeight: 'normal' }}
                          >
                            <small> {errors?.nextHopIpAddress?.message}</small>
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Stack>
                    // </Stack>
                  )}

                  {/* ip blocks selector list */}

                  {selectedInterface && ipBlockType !== 'Routed' ? (
                    <InterfaceIPsList
                      addresses={selectedInterface.addresses!}
                      selectedInterface={selectedInterface}
                      useTableProps={{
                        selected,
                        setSelected,
                        onSelectRow,
                        onSelectAllRows,
                        onSort,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  float: 'right',
                  px: 0,
                  my: 2,
                }}
                spacing={1}
              >
                <Button
                  onClick={handleClose}
                  variant="text"
                  type="button"
                  sx={{ color: 'text.secondary' }}
                >
                  Cancel
                </Button>

                <LoadingButton variant="contained" type="submit" loading={loading}>
                  + Add IP block
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
        </FormProvider>
        <Grid item xs={8} md={8} lg={8}>
          <Stack>
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems={'flex-end'}
              height={60}
            >
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                IPv4 Blocks
              </Typography>
              <LoadingButton
                disabled={!(tableData && tableData.length)}
                sx={{ mt: 4, mb: 1 }}
                loading={syncLoader}
                variant="outlined"
                onClick={() => handleSyncRoutes(id)}
              >
                Sync IP Blocks
              </LoadingButton>
            </Stack>
            <IPBlocksTable
              tableData={
                tableData &&
                tableData.filter((addr: any) => addr.IPVersion === 'IPv4')
              }
              refetch={getIPblocks}
              loading={tableLoading}
            />

            <Typography variant="subtitle2" sx={{ mt: 4, mb: 1 }}>
              IPv6 Blocks
            </Typography>

            <IPBlocksTable
              tableData={
                tableData &&
                tableData.filter((addr: any) => addr.IPVersion === 'IPv6')
              }
              refetch={getIPblocks}
              loading={tableLoading}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default IpBlocksTabView
