import React, { useState, useEffect, useContext } from 'react'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { EndletContext } from './EndletDetails'

import { Dialog, Grid } from '@mui/material'
import { OpenModalProps } from 'src/@types/modalProps'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Button } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import { get, put } from 'src/utils/httpMethods'
import AddNewVwan from './AddNewVwan'
import { LoadingButton } from '@mui/lab'
interface Props {
  openProps: OpenModalProps
  endlet: any
  id: string
  currentIndex: any
}

function SelectExistingService({
  openProps: { open, setOpen },
  endlet,
  id,
  currentIndex,
}: Props) {
  const { organization } = useAuth()
  const [existingVWan, setExistingVWan] = useState<any>([])
  const [openVWAN, setOpenvWAN] = useState(false)
  const [loading, setLoading] = useState(false)
  const { endlet: elt, getEndlets } = useContext(EndletContext)!

  //Get all existing VWANs(services) for the organization
  const getExistingVWan = () => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/services/organization/${organization?.id}`
    )
      .then((response: any) => {
        const IdleServices = response.map((res: any, index: number) => ({
          ...res,
          checked: false,
        })) //to add checked key to all the services

        setExistingVWan(IdleServices.reverse())
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getExistingVWan()
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    updateEndlet()
  }

  const updateEndlet = () => {
    const x = existingVWan.find((vWAN: any) => vWAN.checked)

    const newService = {
      endlet: {
        services: [{ id: x.id, name: x.name }],
        serviceType: [x.type],
      },
    }

    put(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/${endlet?.endletId}`,
      newService
    )
      .then((response: any) => {
        updateService(x)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        handleClose()
      })
  }
  const handleClose = () => {
    setOpen(false)
    // const resetvWAN = [...existingVWan].map((item) => ({ ...item, checked: false }))
    // setExistingVWan(resetvWAN)
  }

  const updateService = (service: any) => {
    const mesh_data = {
      service: {
        associatedEndlets: !service.associatedEndlets.some(
          (ep: any) => ep.endletId === endlet.endletId
        )
          ? [
              ...service.associatedEndlets,
              {
                endletId: endlet.endletId,
                endletName: endlet.endletName,
                designation: elt.designation ? elt.designation : '',
              },
            ]
          : [...service.associatedEndlets],
      },
    }

    const p2p_assoEp_arr =
      service.associatedEndlets.length === 1 &&
      !service.associatedEndlets.some((ep: any) => ep.endletId === endlet.endletId)
        ? [
            ...service.associatedEndlets,
            { endletId: endlet.endletId, endletName: endlet.endletName },
          ] //if selected service is not associated with the current endlet
        : service.associatedEndlets.length === 2 &&
          service.associatedEndlets.some(
            (ep: any) => ep.endletId === endlet.endletId
          )
        ? [...service.associatedEndlets] // if selected service is already associated with the current endlet
        : [{ endletId: endlet.endletId, endletName: endlet.endletName }] //if service is not associated with any endlet

    const P2P_data = {
      service: {
        associatedEndlets: p2p_assoEp_arr,
        // status: p2p_assoEp_arr.length === 2 ? 'Consumed' : 'Idle',
      },
    }
    const finalData =
      service.subType === 'Point To Point'
        ? P2P_data
        : service.subType === 'Full Mesh' || service.subType === 'Hub And Spoke'
        ? mesh_data
        : ''
    put(`${process.env.REACT_APP_HOST_API_URL}/services/${service.id}`, finalData)
      .then((response: any) => {
        getEndlets()
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        handleClose()
        // setDataLoading(false)
      })
  }
  console.log(organization)
  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ p: 2, width: 500 }}>
        <Typography variant="h6" sx={{ my: 1 }}>
          Select Existing service
        </Typography>
        <Stack
          direction={'row'}
          alignItems="center"
          sx={{ mb: 4 }}
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">Existing Services</Typography>
        </Stack>
        <Grid container spacing={1} sx={{ pb: 2 }}>
          {existingVWan.length ? (
            existingVWan.map((key: any, i: number) => (
              <Grid item key={i}>
                <React.Fragment key={key.name}>
                  <Button
                    sx={{
                      textTransform: 'none',
                      wordWrap: 'break-word',
                      minWidth: 120,
                      color: key?.checked ? 'primary' : 'darkgray',
                    }}
                    onClick={() => {
                      // setValue('currentService', key.name)
                      setExistingVWan((prev: any) => [
                        ...prev.map((innerKey: any, j: number) =>
                          i === j
                            ? {
                                ...innerKey,
                                checked: true,
                              }
                            : { ...innerKey, checked: false }
                        ),
                      ])
                    }}
                    variant="outlined"
                    startIcon={
                      key.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                    }
                  >
                    <Typography
                      variant="inherit"
                      sx={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {key.name}
                    </Typography>
                  </Button>
                </React.Fragment>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ p: 1 }}>
              No Service added
            </Typography>
          )}
        </Grid>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            float: 'right',
            px: 0,
            my: 2,
          }}
          spacing={1}
        >
          <Button
            onClick={handleClose}
            variant="text"
            type="button"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>

      <AddNewVwan
        openProps={{
          open: openVWAN,
          setOpen: setOpenvWAN,
        }}
        getExistingVWan={getExistingVWan!}
        id={id}
        serviceTp={elt.serviceType[currentIndex]}
      />
    </Dialog>
  )
}

export default SelectExistingService
