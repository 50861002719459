import React, { useContext, useState } from 'react'

import { Box, Button, Stack, Typography } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import SelectMeshEndletModal from './SelectMeshEndletModal'
import ConfigureEndletModal from './ConfigureEndletModal'
import IntermidiateEndlet from './IntermidiateEndlet'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))
interface props {
  index: number
  endlet: any
  isIpAddedProps: {
    isIPAdded: boolean
    setIsIPAdded: React.Dispatch<React.SetStateAction<boolean>>
  }
  isSuperUser: boolean
}

function HubNSpokevWan({ index, endlet, isIpAddedProps, isSuperUser }: props) {
  const { isIPAdded, setIsIPAdded } = isIpAddedProps
  const [openEP, setOpenEP] = useState(false)
  const [openInterEndlet, setOpenInterEndlet] = useState(false)
  const [openConfigureEndlet, setOpenConfigureEndlet] = useState(false)

  return (
    <div>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-around"
        alignItems={'flex-start'}
        sx={{ mt: 5 }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ m: 0 }}>
          <Stack spacing={1}>
            <LightTooltip title={endlet.endletName} placement="top">
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '80px',
                  height: '32px',
                }}
                // sx={{ width: 'max-content', margin: 'auto' }}
              >
                {endlet.endletName}
              </Typography>
            </LightTooltip>

            <Box>
              <img
                alt={'title'}
                src="/assets/Globe.png"
                style={{ margin: 'auto', height: 50, width: 50 }}
              />
            </Box>
            <Button
              disabled={isSuperUser}
              sx={{
                margin: 'auto',
                width: 'fit-content',
                alignSelf: 'center',
              }}
              variant="outlined"
              onClick={() => setOpenConfigureEndlet(true)}
            >
              Configure
            </Button>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '3rem', //changed
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // marginBottom: '2rem', //changed
                justifyContent: 'center',
                flexDirection: 'column',
                marginLeft: '-20px',
                marginRight: '-20px',
              }}
            >
              {/* {endlet.services?.length ? endlet.services[0].name : ''} */}
              <img alt={'title'} src="/assets/Flow.gif" />
            </Box>
            <Button
              hidden={false}
              disabled={true}
              sx={{
                marginTop: '2rem',
                marginBottom: '0px',
                width: 'fit-content',
                alignSelf: 'center',
              }}
              variant="outlined"
              onClick={() => setOpenInterEndlet(true)}
            >
              Add Intermediate Endlet
            </Button>
          </Box>
          <Stack spacing={1}>
            <img
              alt={'title'}
              src="/assets/cloud.svg"
              style={{ margin: '35px auto auto' }}
            />
            <div
              style={{
                whiteSpace: 'nowrap',
                margin: 'auto',
                width: 'fit-content',
                alignSelf: 'center',
              }}
            >
              <>
                <Button
                  disabled={isSuperUser}
                  sx={{
                    margin: 'auto',
                    width: 'fit-content',
                    alignSelf: 'center',
                  }}
                  variant="outlined"
                  onClick={() => setOpenEP(true)}
                >
                  Configure
                </Button>
              </>
            </div>
          </Stack>
        </Stack>
      </Stack>
      <ConfigureEndletModal
        openProps={{
          open: openConfigureEndlet,
          setOpen: setOpenConfigureEndlet,
        }}
        endletName={endlet.endletName}
        id={endlet.endletId}
        serviceId={endlet.services[index].id}
        isHnS={true}
        setIsIPAdded={setIsIPAdded}
      />
      <SelectMeshEndletModal
        openProps={{
          open: openEP,
          setOpen: setOpenEP,
        }}
        endlet={endlet}
        index={index}
      />
      <IntermidiateEndlet
        openProps={{
          open: openInterEndlet,
          setOpen: setOpenInterEndlet,
        }}
        service={endlet.services[index]}
      />
    </div>
  )
}

export default HubNSpokevWan
