// AddEndletFilterForm.jsx
import React from 'react'
import * as Yup from 'yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider } from 'src/components/hook-form'
import {
  Box,
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Stack,
  DialogContent,
  Divider,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

const formSchema = {
  label: Yup.string().nullable().required('filter is required'),
  operator: Yup.string().nullable().required('Operator is required'),
  value: Yup.string().required('Value is required'),
}

const filterSchema = Yup.object().shape({
  test: Yup.array().of(Yup.object().shape(formSchema)),
})
const filters = [
  { key: 'endlet_name', value: 'Endlet Name' },
  { key: 'status', value: 'Status' },
  { key: 'service_type', value: 'Service Type' },
  { key: 'tags', value: 'Tags' },
  { key: 'location_name', value: 'Location' },
]
const operator = [
  { key: 'starts_with', value: 'Starts with' },
  { key: 'contains', value: 'Contains' },
  { key: 'equals', value: 'Equals' },
]
const status_options = ['Pending', 'Saved', 'Up', 'Down']

interface Props {
  onSubmit: any
  handleClose: any
  reset: any
}
const AddEndletFilterForm = ({ onSubmit, handleClose, reset }: Props) => {
  const methods = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      test: [{ label: '', operator: '', value: '' }],
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
  } = methods

  const { test } = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'test',
  })

  const handleChange = (index: any) => {
    test[index].operator = ''
    test[index].value = ''
  }

  const handleOpChange = (index: any) => {
    test[index].value = ''
  }

  const handleDelete = (index: any) => {
    remove(index)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: '100%' }}>
        <DialogContent sx={{ my: 0 }}>
          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Stack spacing={2}>
                {' '}
                <Stack key={item.id} spacing={2}>
                  <Controller
                    defaultValue={`${item.label}`}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={filters}
                        getOptionLabel={(option) => option.value}
                        defaultValue={filters.find(
                          (fltr) => fltr.key === item.label
                        )}
                        onChange={(event, values) => (
                          onChange(values?.key), handleChange(index)
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Filter" />
                        )}
                      />
                    )}
                    control={control}
                    {...register(`test.${index}.label`)}
                  />
                  {errors?.test && (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small>
                          {errors?.test !== undefined
                            ? errors?.test[index]?.label?.message
                            : ''}
                        </small>
                      </Typography>
                    </Grid>
                  )}
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={{
                          key: test[index].operator,
                          value:
                            operator.find((op) => op.key === test[index].operator)
                              ?.value || '',
                        }}
                        options={
                          test[index].label === 'status' ||
                          test[index].label === 'service_type'
                            ? [{ key: 'equals', value: 'Equals' }]
                            : test[index].label === 'endlet_name'
                            ? operator
                            : operator.slice(0, 2)
                        }
                        getOptionLabel={(option) => option.value}
                        defaultValue={operator.find(
                          (op) => op.key === item.operator
                        )}
                        onChange={(event, values) => (
                          onChange(values?.key), handleOpChange(index)
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Operator"
                            defaultValue={`${item.operator}`}
                          />
                        )}
                      />
                    )}
                    control={control}
                    {...register(`test.${index}.operator`)}
                  />
                  {errors?.test ? (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small>
                          {' '}
                          {errors?.test !== undefined
                            ? errors?.test[index]?.operator?.message
                            : ''}
                        </small>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {test[index].label === 'status' &&
                  test[index].operator === 'equals' ? (
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={status_options}
                          getOptionLabel={(option) => option}
                          defaultValue={status_options.find(
                            (status) => status === item.value
                          )}
                          onChange={(event, values) => onChange(values)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Value"
                              defaultValue={`${item.value}`}
                            />
                          )}
                        />
                      )}
                      control={control}
                      {...register(`test.${index}.value`)}
                    />
                  ) : (
                    <Controller
                      defaultValue={`${item.value}`}
                      render={({ field }) => {
                        return (
                          <TextField
                            label="Value"
                            name={field.name}
                            value={test[index].value || ''}
                            onChange={field.onChange}
                            fullWidth
                            autoComplete="off"
                          />
                        )
                      }}
                      {...register(`test.${index}.value`)}
                    ></Controller>
                  )}
                  {errors?.test ? (
                    <Grid item lg={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#FF4842', fontWeight: 'normal' }}
                      >
                        <small>
                          {' '}
                          {errors?.test !== undefined
                            ? errors?.test[index]?.value?.message
                            : ''}
                        </small>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {fields.length !== 1 && (
                    <div>
                      <Button
                        color="error"
                        variant="text"
                        onClick={() => {
                          handleDelete(index)
                        }}
                        sx={{
                          color: 'red',
                          float: 'right',
                          width: 'fit-content',
                          px: 2,
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </Stack>
              </Stack>
              {index !== fields.length - 1 && (
                <Divider sx={{ borderColor: 'darkgray', margin: '10px' }} />
              )}
            </React.Fragment>
          ))}

          <Stack
            direction="row"
            alignItems="center"
            sx={{ mt: 2, float: 'right' }}
            spacing={2}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ float: 'right' }}
              onClick={() => append({ label: '', operator: '', value: '' })}
            >
              + Add more
            </Button>
          </Stack>
        </DialogContent>
      </Box>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
          sx={{ color: 'text.secondary' }}
        >
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained">
          Add
        </LoadingButton>
      </DialogActions>
      {/* Rest of the form code */}
    </FormProvider>
  )
}

export default AddEndletFilterForm
