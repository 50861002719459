import React, { useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L, { LatLngTuple, latLng } from 'leaflet'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import CytoscapeTry from 'src/sections/@dashboard/Services/ServiceCytoscape/CytoscapeTry'

function TestComponent() {
  const [points, setPoints] = useState({
    center: [51.505, -0.091],
    zoom: 13,
  })

  const customIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })

  const mapOptions = {
    center: points.center as LatLngTuple,
    zoom: points.zoom,
  }

  const position1 = latLng(51.505, -0.09)

  const position2 = latLng(51.52, -0.12)

  const coordinates = [
    latLng(51.505, -0.09), // Coordinate 1
    latLng(51.51, -0.1), // Coordinate 2
    latLng(51.52, -0.12), // Coordinate 3
    // Add more coordinates as needed
  ]

  const mapURL =
    'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'

  return (
    <>
      {/* <MapContainer {...mapOptions} style={{ height: '800px', width: '1200px' }}>
        <TileLayer url={mapURL} />
        <Marker position={position1} icon={customIcon}>
          <Popup>Endlet Location</Popup>
        </Marker>
        <Polyline positions={coordinates} color="red" />
        <Marker position={position2} icon={customIcon}>
          <Popup>Endlet Location</Popup>
        </Marker>
      </MapContainer> */}

      <CytoscapeTry />
    </>
  )
}

export default TestComponent
