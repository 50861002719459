import Page from 'src/components/Page'
import React, { useContext, useEffect, useState } from 'react'
import { post } from 'src/utils/httpMethods'
import Iconify from 'src/components/Iconify'
import { TableNoData } from 'src/components/table'
import { columns } from 'src/_mock/_services'
import Label from '../../components/Label'

// @mui----------------------------------
import {
  Box,
  Card,
  Divider,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Pagination,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material'

import { Stack } from '@mui/system'
import { Button } from '@mui/material'
import { TenantsContext } from 'src/contexts/TenantsContext'
import useAuth from 'src/hooks/useAuth'
import ServiceFilter from 'src/sections/@dashboard/Services/ServiceFilter'
import CreateService from './CreateService'
import { TopLevelmodalContext } from 'src/contexts/ModalContext'
import Tags from './Tags'
import { Link } from 'react-router-dom'
import { PATH_SERVICES } from 'src/routes/paths'

function Services() {
  const theme = useTheme()
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(10)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const { setModalProps, modalProps } = useContext(TopLevelmodalContext)!
  const [currentRow, setCurrentRow] = useState({})
  const [serviceData, setServiceData] = useState<any>([])
  const [filterItems, setFilterItems] = useState<string[]>(
    JSON.parse(localStorage.getItem('service_filters') || '[]')
  )

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  useEffect(() => {
    setPage(1)
  }, [filterItems])

  const handleRowChange = (event: SelectChangeEvent) => {
    setPage(1)
    setRows(parseInt(event.target.value))
  }
  const { selectedTenant } = useContext(TenantsContext)!

  const { user, organization } = useAuth()

  useEffect(() => {
    setLoading(true)

    console.log(filterItems)
    const data = filterItems.length ? { filter: [...filterItems] } : {}

    post(
      selectedTenant ||
        user?.role === 'Reseller_Admin' ||
        user?.role === 'Packets_Super_User'
        ? `${process.env.REACT_APP_HOST_API_URL}/services/organization/filters/${selectedTenant}?pageNumber=${page}&rowsPerPage=${rows}`
        : `${process.env.REACT_APP_HOST_API_URL}/services/organization/filters/${organization?.id}?pageNumber=${page}&rowsPerPage=${rows}`,

      data
    )
      .then((response: any) => {
        console.log(response)
        setServiceData(response)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })

    window.scrollTo(0, 0)
  }, [page, rows, filterItems, modalProps, selectedTenant, user])

  const handleRow = (e: any, data: any) => {
    if (data.status === 'Saved') {
      e.preventDefault()
      setCurrentRow(data)
    }
  }

  console.log(selectedTenant)
  return (
    <Page title="Services" m={3}>
      <Stack direction="row" justifyContent={'space-between'} sx={{ mb: 2 }}>
        <Typography variant="h4">Services</Typography>

        <Button
          hidden={
            user?.role === 'Packets_Super_User' || user?.role === 'Reseller_Admin'
          }
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{ mt: 1 }}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
        >
          Create Service
        </Button>
      </Stack>
      <CreateService
        openProps={{
          open: open,
          setOpen: setOpen,
        }}
      />
      <Card>
        <ServiceFilter
          filterProps={{
            filterItems,
            setFilterItems,
          }}
          rows={rows}
          page={page}
        />
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <>
                    <TableCell
                      sx={{
                        backgroundColor: 'tableHead',
                        color: 'white',
                        '&:first-of-type': {
                          boxShadow: 'unset',
                        },

                        '&:last-of-type': {
                          boxShadow: 'unset',
                        },
                      }}
                      align="left"
                    >
                      <Stack direction="row" spacing={1}>
                        <span style={{ cursor: 'pointer' }}>
                          {column.label.toUpperCase()}
                        </span>
                      </Stack>
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box>
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {serviceData.data && serviceData.data?.length ? (
                      serviceData.data.map((service: any, index: any) => {
                        return (
                          <TableRow
                            key={service.id}
                            component={Link}
                            to={PATH_SERVICES.services.serviceDetails(service.id)}
                          >
                            <TableCell>{service.name}</TableCell>

                            <TableCell style={{ width: '25%' }}>
                              <Label
                                variant={
                                  theme.palette.mode === 'light' ? 'ghost' : 'filled'
                                }
                                sx={{
                                  m: 1,
                                  textTransform: 'none',
                                  color: 'grey',
                                  backgroundColor: 'grey',
                                }}
                              >
                                {`${service.type}  ${service.subType}`}
                                {/* {service.subType} */}
                              </Label>
                            </TableCell>
                            <TableCell style={{ width: '25%' }}>
                              <Tags
                                tags={service.tags}
                                index={index}
                                data={serviceData.data}
                              />
                            </TableCell>

                            <TableCell>
                              <>
                                <Label
                                  variant={
                                    theme.palette.mode === 'light'
                                      ? 'ghost'
                                      : 'filled'
                                  }
                                  color={
                                    service.deploymentStatus === 'Up'
                                      ? 'success'
                                      : 'warning'
                                  }
                                  sx={{
                                    textTransform: 'none',
                                  }}
                                >
                                  {/* {service.deploymentStatus} */}
                                  {service.deploymentStatus}
                                </Label>
                              </>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <>
                        {!serviceData.data?.length && !loading ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Box
                                sx={{ display: 'flex', justifyContent: 'center' }}
                              >
                                <TableNoData
                                  isNotFound={!loading}
                                  message={'No service found'}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />
        <Box
          hidden={!serviceData?.data?.length}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 4,
            py: 2,
          }}
        >
          <FormControl size="small">
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <Typography variant="body2">{`Show`}</Typography>
              <Select
                id="select-small"
                defaultValue={'10'}
                onChange={handleRowChange}
              >
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'20'}>20</MenuItem>
                <MenuItem value={'50'}>50</MenuItem>
              </Select>
              <Typography variant="body2">{`of ${
                !loading ? serviceData.totalResults : 0
              } results`}</Typography>
            </Stack>
          </FormControl>

          <Pagination
            shape="rounded"
            color="primary"
            variant="outlined"
            count={Math.ceil(serviceData.totalResults / rows)}
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Card>
    </Page>
  )
}

export default Services
