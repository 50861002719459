import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { red } from '@mui/material/colors'

import React from 'react'
import { OpenModalProps } from 'src/@types/modalProps'

function RemoveModal({
  openProps,
  onDelete,
}: {
  openProps: OpenModalProps
  onDelete: any
}) {
  const { open, setOpen } = openProps

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <Box sx={{ width: 600 }}>
        <DialogTitle>Remove?</DialogTitle>
        <DialogContentText sx={{ p: 3 }}>
          {`This note will be removed`}
        </DialogContentText>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose()
              onDelete()
            }}
            variant="contained"
            sx={{
              bgcolor: red[400],
              '&:hover': { bgcolor: red[100], color: red[900] },
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default RemoveModal
