import React, { useContext, useState } from 'react'

import {
  TableRow,
  CircularProgress,
  IconButton,
  TableCell,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import IpSubnetCalculator from 'ip-subnet-calculator'
import { del } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'

interface Props {
  block: any
  refetchData: () => void
  index: number
}

function IPBlocksTableRow({ block, refetchData, index }: Props) {
  const [deleteLoader, setDeleteLoader] = useState(false)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const calculateSubnet = (ip: string) => {
    const [ipBlk, prefix] = ip.split('/')
    const objectSubnet = IpSubnetCalculator.calculateSubnetMask(
      ipBlk,
      parseInt(prefix)
    )
    return objectSubnet.prefixMaskStr
  }

  /** API call to delete */
  const deleteIPBlock = (id: string) => {
    setDeleteLoader(true)
    del(`${process.env.REACT_APP_HOST_API_URL}/ip-blocks/${id}`)
      .then((res) => {
        console.log(res)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: 'IP Block Deleted!',
        })
        refetchData()
        setDeleteLoader(false)
      })
      .catch((e) => {
        console.log(e)
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: 'Error deleting IP Blocks',
        })
        setDeleteLoader(false)
      })
  }

  return (
    <TableRow>
      <TableCell>{block.ipBlockType}</TableCell>
      <TableCell>{block.ipBlockValue}</TableCell>
      {block.IPVersion === 'IPv4' ? (
        <Tooltip title={calculateSubnet(block.ipBlockValue)}>
          <TableCell>{block.ipBlockValue.split('/')[1]}</TableCell>
        </Tooltip>
      ) : (
        <TableCell>{block.ipBlockValue.split('/')[1]}</TableCell>
      )}
      <TableCell>{block.nextHopIpAddress}</TableCell>
      <TableCell>{block.interfaceName}</TableCell>
      <TableCell>
        {deleteLoader ? (
          <CircularProgress size={25} sx={{ margin: 1 }} />
        ) : (
          <IconButton
            onClick={() => deleteIPBlock(block.id)}
            sx={{ ':hover': { color: 'red' } }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export default IPBlocksTableRow
