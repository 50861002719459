import React, { useContext, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import {
  FormProvider,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form'
import { get, put } from 'src/utils/httpMethods'
import { Stack } from '@mui/system'
import { Autocomplete, CircularProgress, Grid, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { COUNTRIES } from './SavedEndlet'
import { Chip } from '@mui/material'
import { Button } from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { Divider } from '@mui/material'

const staticTags = ['Cloud', 'DC', 'PCI']

type FormValuesProps = {
  endlet_name: string
  tags: string[]
  system_generated_geotag: boolean
  ip: string
  cloudProvider: string
  region: string
  location_name: string
  country: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  afterSubmit?: string
}

const GeneralInfoSchema = Yup.object().shape({
  endlet_name: Yup.string(),
  tags: Yup.array().of(Yup.string()),
  system_generated_geotag: Yup.boolean(),
  cloudProvider: Yup.string(),
  ip: Yup.string().trim(),
  region: Yup.string().when('cloudProvider', {
    is: 'aws' || 'maz' || 'aws',
    then: Yup.string().required('Region is required!'),
  }),
  location_name: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('Location is required!'),
  }),

  country: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('Country is required!'),
  }),
  address1: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('Address is required!'),
  }),
  address2: Yup.string(),
  city: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('City is required!'),
  }),
  state: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('State is required!'),
  }),
  zip: Yup.string().when(['cloudProvider', 'system_generated_geotag'], {
    is: (cloudProvider: string, system_generated_geotag: boolean) =>
      cloudProvider === 'custom' && system_generated_geotag === false,
    then: Yup.string().required('ZIP is required!'),
  }),
})

interface Props {
  generalFormProps: {
    formStep: number
    setFormStep: React.Dispatch<React.SetStateAction<number>>
    handleClose: () => void
  }
  formDetails: {
    endletFields: any
    setEndletFields: React.Dispatch<React.SetStateAction<any>>
    currentRow: any
  }
}

function SavedEpGeneralForm({
  generalFormProps: { formStep, setFormStep, handleClose },
  formDetails: { endletFields, setEndletFields, currentRow },
}: Props) {
  const defaultValues = {
    endlet_name: currentRow.endletName ? currentRow.endletName : '',
    cloudProvider: currentRow.cloudProvider ? currentRow.cloudProvider : '',
    ip: currentRow.endletIp ? currentRow.endletIp : '',
    region: currentRow.region ? currentRow.region : '',
    tags: currentRow.tags.length ? currentRow.tags : [''],
    system_generated_geotag: currentRow.system_generated_geotag
      ? currentRow.system_generated_geotag
      : true,
    location_name: currentRow.location_name ? currentRow.location_name : '',
    country: currentRow.country ? currentRow.country : '',
    address1: currentRow.address1 ? currentRow.address1 : '',
    address2: currentRow.address2 ? currentRow.address2 : '',
    city: currentRow.city ? currentRow.city : '',
    state: currentRow.state ? currentRow.state : '',
    zip: currentRow.zip ? currentRow.zip : '',
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(GeneralInfoSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = methods

  const {
    system_generated_geotag: endletLoc,
    country,
    address1,
    city,
    state,
    zip,
    tags,
    cloudProvider,
    region,
    endlet_name,
  } = watch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verifyObj = {
    addr1: address1,
    locality: city,
    state: state,
    zip: zip,
  }

  const disableAddress =
    typeof endletLoc === 'boolean' ? endletLoc : endletLoc === 'true' ? true : false

  const [isAddressVerified, setIsAddressVerified] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<any>()
  const [countryLookup, setCountryLookup] = useState('')
  const [query, setQuery] = useState('')
  const [suggestions, setSuggestions] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const [regions, setRegions] = useState<any>()

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  useEffect(() => {
    setIsAddressVerified(
      selectedLocation
        ? verifyObj.addr1 === selectedLocation.addr1 &&
          verifyObj.locality === selectedLocation.locality &&
          verifyObj.state === selectedLocation.state &&
          verifyObj.zip === selectedLocation.zip
          ? true
          : false
        : false
    )
  }, [selectedLocation, verifyObj])

  const [tagsList, setTagsList] = useState<any>()

  useEffect(() => {
    setValue('tags', currentRow.tags)
  }, [currentRow, setValue])

  const [submitMethod, setSubmitMethod] = useState<'save' | 'create'>('create')

  useEffect(() => {
    if (!countryLookup.length && !query.length) return
    get(
      `https://international-autocomplete.api.smartystreets.com/lookup?key=147124006472552835&country=${countryLookup}&search=${query}`
    )
      .then((res) => {
        console.log(res)
        setSuggestions(res)
      })
      .catch((e) => console.log(e))
  }, [countryLookup, query])

  const saveEndlet = (data: FormValuesProps) => {
    // setLoading(true)
    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${currentRow.endletId}`, {
      endlet: {
        endletName: data.endlet_name,
        tags: data.tags,
        endletIp: data.ip,
        location: {
          name: data.location_name,
          country: data.country,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
        },
        cloudProvider: data.cloudProvider,
        region: data.region,
        systemGeneratedGeotag: data.system_generated_geotag,
        status: 'Saved',
      },
    })
      .then(() => {
        handleClose()
        setSnackbarProps({
          open: true,
          message: 'Endlet Saved Successfully!',
          severity: 'success',
        })
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          message: 'Error creating Endlet!',
          severity: 'error',
        })
      })
  }

  const onSubmit = async (data: FormValuesProps) => {
    if (submitMethod === 'save') {
      saveEndlet(data)
    } else {
      setEndletFields((prev: any) => ({ ...prev, ...data }))
      setFormStep(2)
    }
  }

  const fetchTags = () => {
    get(`${process.env.REACT_APP_HOST_API_URL}/tags`)
      .then((res: any) => {
        setTagsList(res)
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    fetchTags()
  }, [])

  console.log(errors)
  // console.log(endletFields)

  useEffect(() => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/regions?cloudProvider=${cloudProvider}`
    )
      .then((res: any) => {
        console.log(res)
        setRegions(res.regions)
      })
      .catch((e) => console.log(e))
  }, [cloudProvider])

  const [nameValidationLoader, setNameValidationLoader] = useState(false)
  const [nameValid, setNameValid] = useState(false)
  console.log('loader options^^^^^^^^^^^^^^', endlet_name, nameValidationLoader)
  useEffect(() => {
    if (endlet_name !== '') {
      setNameValidationLoader(true)
      const nameValidation = () => {
        get(
          `${process.env.REACT_APP_HOST_API_URL}/endlets/endlet-exists?endletName=${endlet_name}`
        )
          .then((res) => {
            setNameValidationLoader(false)
            console.log(res)
            clearErrors('endlet_name')
            setNameValid(true)
          })
          .catch((e) => {
            setNameValidationLoader(false)
            console.log(e)
            setError('endlet_name', e)
            setNameValid(false)
          })
      }
      const timer = setTimeout(() => {
        nameValidation()
      }, 500)

      return () => {
        clearTimeout(timer)
      }
    } else {
      setNameValidationLoader(false)
      setNameValid(false)
    }
  }, [clearErrors, endlet_name, setError])

  useEffect(() => {
    if (cloudProvider !== 'custom') {
      setValue('system_generated_geotag', false)
    }
  }, [cloudProvider, setValue])

  console.log(errors)

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <>
        <Typography variant="subtitle1" sx={{ py: 2 }}>
          General
        </Typography>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Endlet Name
            </Typography>
            <Typography variant="caption" sx={{ mt: 0 }} color={'gray'}>
              Enter a Custom Endlet Name Below or Leave Blank for a System Generated
              Endlet Name
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems="center">
            <RHFTextField
              name="endlet_name"
              placeholder="Type Endlet Name"
              autoComplete="off"
              sx={{ maxWidth: 850 }}
            ></RHFTextField>
            {nameValidationLoader && <CircularProgress />}
          </Stack>

          {nameValid && (
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <VerifiedIcon color="success" />
              <Typography variant="body2" color="green">
                {' '}
                Endlet name is available!{' '}
              </Typography>
            </Stack>
          )}
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={8} sx={{ pl: 0 }}>
              <Stack>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Tags
                </Typography>
                <Typography variant="caption" sx={{ mt: 0 }} color={'gray'}>
                  Create a New Tag Below or Select From an Existing Tag
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={4} sx={{ pl: 1 }}>
              <Typography variant="body2" color={'GrayText'} sx={{ mt: 4 }}>
                Tag suggestions
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Autocomplete
                sx={{ flexGrow: 1 }}
                multiple
                id="tags-filled"
                noOptionsText={
                  'No tags found for your organization. Type and press enter to add a new tag'
                }
                options={
                  tagsList && tagsList.length
                    ? tagsList.map((option: any) => option.name)
                    : []
                }
                freeSolo
                filterSelectedOptions
                value={tags ? [...tags] : ['']}
                renderTags={(value: readonly string[], getTagProps) => {
                  return value
                    .filter((option: string) => option !== '')
                    .map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                }}
                onChange={(e, value) => {
                  setValue('tags', [...value.filter((tag) => tag !== '')])
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`Type Tags and Hit “Enter"`}
                    name="tags"
                    sx={{ maxWidth: 850 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sx={{ pl: 2, pt: 1 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                {/* {tagsList &&
                tagsList.map((tag: any, i: number) =>
                  i < 3 ? (
                    <Chip
                      label={tag.name}
                      onClick={() => {
                        setValue(
                          'tags',
                          tags && tags.length ? [...tags, tag.name] : [tag.name]
                        )
                      }}
                    />
                  ) : (
                    <></>
                  )
                )} */}

                {staticTags &&
                  staticTags.map((tag: any, i: number) => (
                    <Chip
                      label={tag}
                      onClick={() => {
                        setValue(
                          'tags',
                          tags && tags.length ? [...tags, tag] : [tag]
                        )
                      }}
                    />
                  ))}

                {/* {!tagsList && <CircularProgress size={28} />} */}
              </Stack>
              {/* </Stack> */}
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: 'black', px: 2 }} />
          <Typography variant="subtitle1" sx={{ pb: 1, pt: 1 }}>
            Location
          </Typography>
          <Stack
            direction={'row'}
            sx={{ width: '100%' }}
            spacing={2}
            alignItems="center"
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                Provider
              </Typography>
              <RHFSelect
                sx={{ maxWidth: 850 }}
                name="cloudProvider"
                label="Select Provider"
                placeholder="Endlet Deployment"
              >
                <option value=""> </option>
                <optgroup label="Cloud Providers">
                  <option value="aws"> Amazon Web Services </option>
                  <option value="maz"> Microsoft Azure </option>
                  <option value="gcp"> Google Cloud Platform </option>
                </optgroup>
                <optgroup label="Other">
                  <option value="custom"> Custom Endlet</option>
                </optgroup>
              </RHFSelect>
            </Stack>

            <Stack sx={{ width: '100%' }} spacing={2}>
              <Typography
                variant="subtitle2"
                sx={{ mt: 1 }}
                hidden={cloudProvider === 'custom'}
              >
                Deployment Location
              </Typography>
              <RHFSelect
                sx={{ maxWidth: 850 }}
                name="region"
                placeholder="Select Deployment Location"
                disabled={cloudProvider === '' ? true : false}
                hidden={cloudProvider === 'custom'}
              >
                {regions && regions.length ? (
                  <>
                    <option value="">Select Deployment Location</option>
                    {regions.map((reg: any) => (
                      <option value={reg}>{reg}</option>
                    ))}
                  </>
                ) : (
                  <option value="" disabled>
                    Loading...
                    {/* <CircularProgress /> */}
                  </option>
                )}
              </RHFSelect>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={2} hidden={cloudProvider !== 'custom'}>
          <RHFRadioGroup
            hidden={cloudProvider !== 'custom'}
            name="system_generated_geotag"
            // defaultValue={false}
            options={[
              { label: 'System Generated Location', value: true },
              { label: 'User Generated Location', value: false },
            ]}
            sx={{ '& .MuiFormControlLabel-root': { mr: 4, mt: 1 } }}
          ></RHFRadioGroup>

          <RHFTextField
            sx={{ maxWidth: 850 }}
            autoComplete="off"
            name="location_name"
            label="Location Name"
            disabled={disableAddress}
            hidden={disableAddress}
          ></RHFTextField>

          <Autocomplete
            disablePortal
            disabled={disableAddress}
            filterSelectedOptions
            hidden={disableAddress}
            id="combo-box-demo"
            options={Object.keys(COUNTRIES).map((obj: string) => obj)}
            // sx={{ width: 300 }}
            value={country}
            onChange={(e, value) => {
              console.log(value)
              setValue('country', value ? value : '')
              setCountryLookup(value ? COUNTRIES[value].toString() : '')
            }}
            renderInput={(params) => (
              <RHFTextField
                {...params}
                label="Country"
                name="country"
                sx={{ maxWidth: 850 }}
              />
            )}
          />

          <Autocomplete
            disablePortal
            disabled={disableAddress}
            hidden={disableAddress}
            value={address1}
            id="combo-box-demo"
            options={
              suggestions.candidates
                ? suggestions.candidates.map((addr: any) => ({
                    label: addr.street + ', ' + addr.locality,
                    value: addr.street,
                    addr1: addr.street,
                    locality: addr.locality,
                    zip: addr.postal_code,
                    state: addr.administrative_area,
                  }))
                : [
                    {
                      label: '',
                      value: '',
                    },
                  ]
            }
            // sx={{ width: 300 }}
            onChange={(e, value: any) => {
              // console.log(value)
              setSelectedLocation({ ...value })
              if (value) {
                setValue('address1', value.addr1)
                setValue('city', value.locality)
                setValue('zip', value.zip)
                setValue('state', value.state)
              }
            }}
            renderInput={(params) => (
              <RHFTextField
                {...params}
                sx={{ maxWidth: 850 }}
                label="Address 1"
                name="address1"
                onChange={(e) => {
                  console.log(e.target.value)
                  // setValue('address1', e.target.value)
                  setQuery(e.target.value)
                }}
              />
            )}
          />
          <RHFTextField
            autoComplete="off"
            sx={{ maxWidth: 850 }}
            name="address2"
            disabled={disableAddress}
            hidden={disableAddress}
            label="Address 2"
          ></RHFTextField>
          <RHFTextField
            autoComplete="off"
            disabled={disableAddress}
            sx={{ maxWidth: 850 }}
            hidden={disableAddress}
            name="city"
            label="City"
          ></RHFTextField>
          <RHFTextField
            autoComplete="off"
            disabled={disableAddress}
            hidden={disableAddress}
            sx={{ maxWidth: 850 }}
            name="state"
            label="State / Province"
          ></RHFTextField>

          <RHFTextField
            autoComplete="off"
            disabled={disableAddress}
            sx={{ maxWidth: 850 }}
            hidden={disableAddress}
            name="zip"
            label="ZIP"
          ></RHFTextField>
        </Stack>
      </>

      {isAddressVerified && (
        <Stack direction={'row'} spacing={1} alignItems="center" sx={{ mt: 2 }}>
          <Typography variant="subtitle2">Address is verified</Typography>
          <CheckCircleIcon sx={{ color: 'green' }} />
        </Stack>
      )}

      <Stack spacing={2} sx={{ my: 4 }}>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Endlet IP Address
        </Typography>
        <RHFTextField
          name="ip"
          placeholder="Type Endlet IP Address"
          autoComplete="off"
          sx={{ maxWidth: 850 }}
        ></RHFTextField>
      </Stack>

      <Stack direction={'row'} spacing={1} sx={{ float: 'right', py: 3 }}>
        <Button
          onClick={handleClose}
          variant="text"
          type="button"
          sx={{ color: 'text.secondary' }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setSubmitMethod('save')
          }}
          variant="text"
          type="submit"
          sx={{ color: 'text.secondary' }}
        >
          Save Endlet
        </Button>
        <Button variant="contained" type="submit">
          Next
        </Button>
      </Stack>
    </FormProvider>
  )
}

export default SavedEpGeneralForm
