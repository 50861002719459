import React, { useContext, useEffect, useRef, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { OpenModalProps } from 'src/@types/modalProps'
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import Page from 'src/components/Page'
interface Props {
  modalProps: OpenModalProps
  OriginalImage: any
  crop: any
  setCrop: any
  setPreviewImage: any
  isLightLogo?: boolean
}
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

function ImageCropOverlay({
  modalProps: { open, setOpen },
  OriginalImage,
  crop,
  setCrop,
  setPreviewImage,
  isLightLogo,
}: Props) {
  const [imgUrl, setImgUrl] = useState('')
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const imgRef = useRef<HTMLImageElement>(null)
  const cancelButtonRef = useRef(null)
  const handleClose = () => {
    setOpen(false)
    clearFileInput()
  }

  const clearFileInput = () => {
    // const fileInput = document.getElementById(fileInputId) as HTMLInputElement
    // if (fileInput) {
    //   fileInput.value = ''
    // }
  }
  useEffect(() => {
    if (OriginalImage) {
      setImgUrl('')
      setImgUrl(URL.createObjectURL(OriginalImage) as any)
    }
  }, [OriginalImage])

  const onSaveCropClick = async () => {
    const image = imgRef.current

    if (!image || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    ) as any
    const ctx = offscreen.getContext('2d') as any

    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      offscreen.width,
      offscreen.height
    )
    const blob = (await offscreen.convertToBlob({
      type: OriginalImage?.type,
    })) as any

    const croppedFile = new File([blob], OriginalImage?.name, {
      type: OriginalImage?.type,
    })
    setPreviewImage(croppedFile)
    clearFileInput()
    handleClose()
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 16 / 9))
  }
  return (
    <Page title="Select Tenant">
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle> Crop Image</DialogTitle>

        <DialogContent dividers={true} sx={{ width: 550, height: 'auto' }}>
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!!imgUrl && (
              <ReactCrop
                crop={crop}
                onChange={(_: any, percentCrop: any) => setCrop(percentCrop)}
                onComplete={(c: any) => setCompletedCrop(c)}
                aspect={undefined}
                minHeight={1}
                minWidth={1}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgUrl}
                  style={{
                    minWidth: '500px',
                    minHeight: '300px',
                    objectFit: 'contain',
                    backgroundColor: isLightLogo ? '#030637' : '',
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button onClick={() => onSaveCropClick()} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default ImageCropOverlay
