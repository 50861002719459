import React, { useContext, useState } from 'react'

// modal props
import { OpenModalProps } from 'src/@types/modalProps'

// @mui
import { Box, Button, Dialog, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Alert, AlertTitle } from '@mui/material'
import { del } from 'src/utils/httpMethods'
import { useNavigate } from 'react-router'
import { PATH_SERVICES, ROOTS_SERVICES } from 'src/routes/paths'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'

interface Props {
  openProps: OpenModalProps
  services: boolean
  id: string
}
function DeleteEPModal({ openProps, services, id }: Props) {
  const { open, setOpen } = openProps
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const handleClose = () => {
    setOpen(false)
    setLoading(false)
  }

  const deleteEP = (id: string) => {
    setLoading(true)
    del(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`)
      .then((res) => {
        setLoading(false)
        handleClose()
        navigate(PATH_SERVICES.root)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: 'Endlet deleted Successfully!',
        })
      })
      .catch((e) => console.log('++++++ERROR DELETING Endlet++++++', e))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      //   maxWidth={'lg'}
      scroll="body"
    >
      {services ? (
        <Alert
          severity="error"
          sx={{ p: 2 }}
          onClose={() => {
            handleClose()
          }}
        >
          <AlertTitle>Cannot Delete Endlet!</AlertTitle>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Services are currently configured on the Endlet.
          </Typography>
          <Typography variant="body1">
            You must delete all services before you can delete the Endlet!
          </Typography>
        </Alert>
      ) : (
        <Box sx={{ height: '100%', p: 2 }}>
          <Typography variant="h6">Delete Endlet</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Are you sure you want to delete the Endlet?
          </Typography>
          <Stack direction={'row'} spacing={1} sx={{ float: 'right', m: 2 }}>
            <LoadingButton
              loading={loading}
              color="error"
              variant="contained"
              onClick={() => deleteEP(id)}
            >
              Yes
            </LoadingButton>
            <Button variant="contained" onClick={handleClose}>
              No
            </Button>
          </Stack>
        </Box>
      )}
    </Dialog>
  )
}

export default DeleteEPModal
