import React, { createContext, ReactNode, useEffect, useState } from 'react'

export interface SnackbarContextType {
  selectedTenant: string
  setSelectedTenant: React.Dispatch<React.SetStateAction<string>>
}

export const TenantsContext = createContext<SnackbarContextType | null>(null)

function TenantsProvider({ children }: { children: ReactNode }) {
  const [selectedTenant, setSelectedTenant] = useState(
    localStorage.getItem('selecetedTenant') || ''
  )

  useEffect(() => {
    localStorage.setItem('selecetedTenant', selectedTenant)
  }, [selectedTenant])

  return (
    <TenantsContext.Provider value={{ selectedTenant, setSelectedTenant }}>
      {children}
    </TenantsContext.Provider>
  )
}

export default TenantsProvider
