import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'

export interface ModalContextType {
  open: boolean
  message: string
  severity: 'success' | 'error'
}

interface AlertMFAModalPropsType {
  show: boolean
}

export interface ModalContextValues {
  modalProps: ModalContextType
  setModalProps: Dispatch<SetStateAction<ModalContextType>>
  alertMFAModalProps: AlertMFAModalPropsType
  setAlertMFAModalProps: React.Dispatch<React.SetStateAction<AlertMFAModalPropsType>>
}
export const TopLevelmodalContext = createContext<ModalContextValues | null>(null)

function ModalContext({ children }: { children: ReactNode }) {
  const [modalProps, setModalProps] = useState<ModalContextType>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [alertMFAModalProps, setAlertMFAModalProps] = useState({
    show: false,
  })
  return (
    <TopLevelmodalContext.Provider
      value={{
        modalProps,
        setModalProps,
        alertMFAModalProps,
        setAlertMFAModalProps,
      }}
    >
      {children}
    </TopLevelmodalContext.Provider>
  )
}
export default ModalContext
