import React, { createContext, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// @mui
import { styled } from '@mui/material/styles'
import { Card, Container, Link, Stack, Typography } from '@mui/material'

// custom
import { PATH_AUTH } from '../../routes/paths'
import Page from 'src/components/Page'
import LogoBlackText from 'src/components/LogoBlackText'
import useResponsive from 'src/hooks/useResponsive'

import { VerifyCodeForm } from 'src/sections/auth/verify-code'
import MFAHelp from 'src/sections/auth/verify-code/MFAHelp'
import useAuth from 'src/hooks/useAuth'
import DuoAuthSelector from './DuoAuth/DuoAuthSelector'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  // zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

function AuthenticatorCode() {
  const smUp = useResponsive('up', 'sm')
  const { mfaStatus } = useAuth()
  const [factor, setFactor] = useState('')
  return (
    <>
      {/* // <Stack direction={'row'} alignItems={'center'}> */}
      {mfaStatus.authenticatorApp === 'Corporate Duo' ? (
        <DuoAuthSelector />
      ) : (
        <VerifyCodeForm />
      )}
    </>
    // </Stack>
  )
}

export default AuthenticatorCode
